import React from "react";

import SessaoExpirada from "assets/v2/sessao-expirada.svg";

import "./styles.css";

export default function SessaoAviso() {
  return (
    <div className="sessao-container">
      <div className="content-sessao-expirada">
        <img src={SessaoExpirada} alt="sessão expirada" />
        <h1>Sua sessão expirou</h1>
        <h3>Por favor recarregue a página</h3>
      </div>
    </div>
  );
}
