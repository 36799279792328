import React, { useEffect, useState } from 'react';

import PageLoader from 'components/PageLoader';
import CameraFoto from 'components/CameraFoto';
import Liveness from 'components/Liveness';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Selfie from 'assets/icon_selfie_v2.png';
import { ReactComponent as Oculos } from 'assets/oculos.svg';
import { ReactComponent as Iluminacao } from 'assets/iluminacao.svg';
import { ReactComponent as Olho } from 'assets/olho.svg';
import { ReactComponent as Camera } from 'assets/camera.svg';
import {
  MENSAGEM_ERRO_ZOOM,
  CODIGO_ERRO_ZOOM,
} from 'components/Liveness/consts';
import inicializaFacetec from 'helpers/initializeFacetec';
import { useApp } from 'context/App';
import api from 'services/api';
import audioSelfie from 'assets/audios/selfie-2.mp3';
import { useAudio } from 'react-use';
import { VOLUME } from 'services/consts';
import './styles.css';
import { ContainerCenter } from 'components/ContainerCenter';

export default function Biometria() {
  const [isLoading, setIsLoading] = useState(true);
  const [faceteInicializada, setFaceteInicializada] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    facetecIndisponivel,
    statusFacetec,
    tokenSignatario,
    setIsLiveness,
    ajudaPorVoz,
    idEmpresa,
    livenessConfig,
    facematchConfig,
    configGlobal
  } = useApp();
  const [audio, state, controls, ref] = useAudio({
    src: audioSelfie,
    autoPlay: false,
  });

  const corPrimaria = configGlobal?.biometria_cor_fundo;

  useEffect(() => {
    setIsLoading(true);

    if (ajudaPorVoz) {
      controls.volume(VOLUME);
      controls.play();
    }

    const executaVerificacao = async () => {
      await verificaLiveness();
      onOpenModal();
      setIsLoading(false);
    };

    executaVerificacao();
  }, []);

  function onOpenModal() {
    setOpen(true);
  }

  function onCloseModal() {
    setOpen(false);
  }

  async function verificaLiveness() {
    let livenessObrigatorio = (livenessConfig && parseInt(window.localStorage.getItem('tentativasLiveness')) === 0) || (facematchConfig && parseInt(window.localStorage.getItem('tentativasFacematch')) === 0);

    if (!livenessObrigatorio) {
      const qtdTentativasValida = await quantidadeTentativasValida();

      if (!qtdTentativasValida) {
        setIsLiveness(false);
        setFaceteInicializada(false);
        return;
      }
    }

    if (facetecIndisponivel || [2, 5, 6, 7].indexOf(statusFacetec) !== -1) {
      await novaTentativaFacetec();
    } else {
      setIsLiveness(true);
      setFaceteInicializada(true);
    }
  }

  async function novaTentativaFacetec() {
    const retornoFacetec = await inicializaFacetec(idEmpresa);

    console.log(retornoFacetec);

    if (
      !retornoFacetec.possuiErro() &&
      [2, 5, 6, 7].indexOf(retornoFacetec.status) === -1
    ) {
      setIsLiveness(true);
      setFaceteInicializada(true);
    } else {
      let mensagem = '';

      switch (retornoFacetec.status) {
        case 2:
          mensagem = `(Instabilidade de conexão)`;
          break;
        case 5:
          mensagem = `(Aparelho/Plataforma/Navegador/Versão não compatível)`;
          break;
        case 6:
          mensagem = `(Orientação incorreta) - Ângulo da orientação: ${window.orientation !== undefined ? window.orientation : 'indefinido'
            }`;
          break;
        case 7:
          mensagem = `(Orientação incorreta) - Ângulo da orientação: ${window.orientation !== undefined ? window.orientation : 'indefinido'
            }`;
          break;
        default:
          mensagem = '';
          break;
      }

      const data = {
        token: tokenSignatario,
        mensagem: `Tentativa 2 - ${MENSAGEM_ERRO_ZOOM} - Status: ${retornoFacetec.status} ${mensagem}`,
        tipo: CODIGO_ERRO_ZOOM,
      };

      await api.post(`BemSign/GravarLog`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
          'Content-Type': 'application/json',
        },
      });

      setIsLiveness(false);
      setFaceteInicializada(false);
    }
  }

  async function quantidadeTentativasValida() {
    try {
      const response = await api.get(
        `Facetec/ObterQuantidadeTentativas/${tokenSignatario}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'autorizacao',
            )}`,
          },
        },
      );

      return response.data.retorno !== 0;
    } catch (error) { }
  }

  return (
    <ContainerCenter className="biometria-container" isLoading={isLoading || faceteInicializada}>
      {audio}
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        styles={{ modal: { borderRadius: '8px' } }}
        showCloseIcon={false}
      >
        <div className="modal-container">
          <div className="selfie-card">
            <img src={Selfie} alt="selfie" />
            <p style={{ color: corPrimaria }}>Uma foto sua</p>
          </div>

          <div className="selfie-dicas">
            <Iluminacao fill={corPrimaria} />
            <p>Procure um local bem iluminado.</p>
          </div>

          <div className="selfie-dicas">
            <Oculos fill={corPrimaria} />
            <p>Retire adereços da cabeça, como chapéus e óculos.</p>
          </div>

          <div className="selfie-dicas">
            <Olho fill={corPrimaria} />
            <p>Posicione a câmera na altura dos seus olhos.</p>
          </div>

          <div className="selfie-dicas">
            <Camera fill={corPrimaria} />
            <p>Poderão ser coletadas imagens durante todo o processo.</p>
          </div>
          <button className="button-bem" onClick={onCloseModal} style={{ backgroundColor: configGlobal.confirmacao_cor_botao_primario, color: configGlobal.confirmacao_cor_fonte_botao_primario }}>
            Entendi
          </button>
        </div>
      </Modal>
      <section
        className={`loader-animation ${isLoading ? 'enabled' : 'hidden'}`}
      >
        <PageLoader isOverlay="true" width={125} height={250} />
      </section>

      {!isLoading && faceteInicializada && !open && (
        <Liveness setFaceteInicializada={setFaceteInicializada} />
      )}
      {!isLoading && !faceteInicializada && !open && <CameraFoto />}
    </ContainerCenter>
  );
}
