import React, { useEffect, useState, useCallback, useRef } from "react";
import { Parser } from "html-to-react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useApp } from "context/App";

import { TIMEOUT_URL_REDIRECIONAMENTO } from "./consts";

import audioAssinaturaConcluida from "assets/audios/assinatura-concluida-1.mp3";

import { useAudio } from "react-use";
import { VOLUME } from "services/consts";
import "./styles.css";
import Feedback from "components/Feedback";
import DocumentoDownload from "components/DocumentoDownload";
import { ContainerAppRow } from "components/ContainerAppRow";
import { Content } from "components/ContainerAppRow/styles";

export default function Conclusao() {
  const [mensagemCustomizada, setMensagemCustomizada] = useState(null);
  const { ajudaPorVoz, configuracaoAssinatura, configGlobal } = useApp();
  const [audio, state, controls, ref] = useAudio({
    src: audioAssinaturaConcluida,
    autoPlay: false,
  });

  const inatividadeTimeout = useRef(null);

  const resetTimer = useCallback(() => {
    if (inatividadeTimeout.current) {
      clearTimeout(inatividadeTimeout.current);
    }

    inatividadeTimeout.current = setTimeout(() => {
      if (
        configuracaoAssinatura.urlRedirecionamento &&
        configuracaoAssinatura.urlRedirecionamento !== ""
      ) {
        window.location.href = configuracaoAssinatura.urlRedirecionamento;
      }
    }, TIMEOUT_URL_REDIRECIONAMENTO);
  }, []);

  useEffect(() => {
    resetTimer();

    return () => {
      if (inatividadeTimeout.current) {
        clearTimeout(inatividadeTimeout.current);
      }
    };
  }, []);

  useEffect(() => {
    const exibirTextoConclusao = async () => {
      try {
        if (
          configuracaoAssinatura &&
          configuracaoAssinatura.textoAdicionalConclusao
        ) {
          const htmlInput = configuracaoAssinatura.textoAdicionalConclusao;
          const htmlToReactParser = new Parser(React);
          const reactComponent = htmlToReactParser.parse(htmlInput);
          if (ajudaPorVoz) {
            controls.volume(VOLUME);
            controls.play();
          }

          setMensagemCustomizada(reactComponent);
        }
      } catch (err) {
        toast.error("Erro ao gerar texto de conclusão");
      }
    };

    exibirTextoConclusao();
  }, []);

  return (
    <ContainerAppRow
      className="conclusao-container"
      style={{
        background: configGlobal.conclusao_cor_fundo,
        color: configGlobal.conclusao_cor_fonte,
      }}
      headerContent={
        <Content>
          <h3>Processo concluído</h3>
          {configuracaoAssinatura && configuracaoAssinatura.nomeLote ? (
            <h5 style={{ marginTop: "10px" }}>
              {configuracaoAssinatura.nomeLote}
            </h5>
          ) : null}
          <DocumentoDownload resetTimer={resetTimer} />
        </Content>
      }
    >
      {audio}

      <Content className="mensagem-customizada">
        <div>{mensagemCustomizada}</div>
      </Content>

      <Content className="feedback-conclusao">
        <Feedback resetTimer={resetTimer} />
      </Content>

      <ToastContainer />
    </ContainerAppRow>
  );
}
