import React, { useEffect, useRef } from "react";
import "./styles.css";

export default function BotaoFixado({
  cor,
  children,
  onClick,
  disabled,
  CheckboxDoc,
  borderTop,
  BotaoComplemento,
  removeFixed,
  dimensao,
  botaoSecundario,
  corBotao,
  corFonte,
  isDocWide = false,
  rmMgButton = false
}) {
  const targetRef = useRef();

  useEffect(() => {
    if (targetRef.current && dimensao) {
      dimensao({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      });
    }
  }, []);

  return (
    <div
      className={`botao-fixado ${rmMgButton ? "rm-mg-button" : ""}`}
      ref={targetRef}
      style={{
        backgroundColor: cor ? cor : "#fff",
        borderTop: borderTop ? "1px solid #ccc" : "none",
        position: removeFixed ? "unset" : "fixed",
      }}
    >
      {CheckboxDoc && CheckboxDoc}
      <button
        className={`button-bem-v2 ${botaoSecundario ? "button-branco" : null}`}
        disabled={disabled}
        onClick={onClick}
        style={{ background: corBotao, color: corFonte }}
      >
        {children}
      </button>
      {BotaoComplemento && BotaoComplemento}
    </div>
  );
}
