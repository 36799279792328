import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Webcam from "react-webcam";
import { useApp } from "context/App";
import api from "services/api";
import { VIDEO_BITS } from "services/consts";
import {
  SELFIE_BEMSIGN_NEUTRO,
  SELFIE_AUTENTICACAO_NEUTRO,
  SELFIE_CONCLUSAO,
  TEMPO_TOTAL_FOTOS,
  MENSAGEM_CENTRALIZE_ROSTO,
  DIREITA,
  ESQUERDA,
  CIMA,
  BAIXO,
} from "./consts";
import {
  CONSIGNADO,
  BANRISUL,
  BANRISUL_2,
  FGTS,
  FUNDACAO,
  BANRISUL_PORTAL_EMPRESARIAL,
} from "helpers/consts";
import Pbkdf2 from "helpers/criptografia";
import { blobToBase64 } from "helpers/base64Blob";
import Timer from "components/Timer";
import { useAudio, useMedia } from "react-use";
import BotaoFixado from "components/BotaoFixado";
import "./styles.css";
import { COMPONENTE_DOCUMENTO } from "helpers/enums";
import { SMALL } from "helpers/breakpoints";
import useBestCamera from "helpers/hooks/useBestCamera";

export default function CameraFoto() {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [selfieNeutro, setSelfieNeutro] = useState(false);
  const [esperaGravacao, setEsperaGravacao] = useState(false);
  const [cameraIniciada, setCameraIniciada] = useState(false);
  const [tempo, setTempo] = useState(TEMPO_TOTAL_FOTOS);
  const [mensagemInstrucao, setMensagemInstrucao] = useState(
    MENSAGEM_CENTRALIZE_ROSTO
  );
  const [listaInstrucoes, setListaInstrucoes] = useState([]);
  const [qualidadeFoto, setQualidadeFoto] = useState(1);
  const {
    setSelfie,
    tokenSignatario,
    idEmpresa,
    coletaDocumentoConfig,
    componenteDocumentoConfig,
    proximaPagina,
    alteraProgresso,
    configGlobal,
  } = useApp();
  const [iniciado, setIniciado] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [mimeType, setMimeType] = useState("");
  const [classeInstrucao, setClasseInstrucao] = useState("");
  const [grau, setGrau] = useState("");
  const [srcAudio, setSrcAudio] = useState(null);
  const [idInstrucao, setIdInstrucao] = useState(null);
  const [logoEmpresa, setLogoEmpresa] = useState(null);

  const { deviceId, facingMode, loading } = useBestCamera();

  const history = useHistory();

  const [audio, state, controls, ref] = useAudio({
    src: srcAudio,
    autoPlay: true,
  });

  const isSmall = useMedia(SMALL);

  const tempoCount = useRef();
  tempoCount.current = tempo;

  const MIME_TYPES = [
    'video/webm;codecs="vp8,opus"',
    "video/webm;codecs=h264",
    "video/webm;codecs=vp9",
    "video/webm",
    "video/mp4",
  ];

  async function exibeLogoEmpresa() {
    let logo = null;

    if (idEmpresa === CONSIGNADO) {
      logo = require("assets/logo-consignado-bloco.png");
    } else if (
      idEmpresa === BANRISUL ||
      idEmpresa === BANRISUL_2 ||
      idEmpresa === BANRISUL_PORTAL_EMPRESARIAL
    ) {
      logo = require("assets/logo-banrisul.png");
    } else if (idEmpresa === FGTS) {
      logo = require("assets/logo-fgts.png");
    } else if (idEmpresa === FUNDACAO) {
      logo = require("assets/logo-fundacao.png");
    }

    return logo;
  }

  useEffect(() => {
    const carregaDados = async () => {
      const logo = await exibeLogoEmpresa();

      if (logo) {
        setLogoEmpresa(logo.default);
      }

      const mimeTypePermitido = window.MediaRecorder.isTypeSupported
        ? MIME_TYPES.find(window.MediaRecorder.isTypeSupported)
        : "video/webm";

      setMimeType(mimeTypePermitido);

      await obterInstrucao();

      executaAudioPorIdInstrucao("centralize-iniciar");
    };

    carregaDados();
  }, []);

  useEffect(() => {
    if (srcAudio) {
      controls.play();
    }
  }, [srcAudio]);

  useEffect(() => {
    if (selfieNeutro) {
      capturarCamera(true);
    }
  }, [selfieNeutro]);

  const obterInstrucao = async () => {
    const response = await api.get(`BemSign/ObterInstrucoesVideo`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("autorizacao")}`,
      },
    });

    const instrucoes = response.data.retorno;

    setListaInstrucoes(instrucoes);
  };

  const capturarFoto = useCallback(async () => {
    setEsperaGravacao(false);
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
    confirmarCaptura(imageSrc);
  }, [webcamRef, setImgSrc]);

  const removerCaptura = () => {
    setImgSrc(null);
  };

  const registrarFotoInicioFim = useCallback(
    async (tipo) => {
      try {
        const imageSrc = webcamRef.current.getScreenshot();
        await salvarBiometria(false, tipo, imageSrc);
      } finally {
      }
    },
    [webcamRef]
  );

  const iniciarCaptura = () => {
    setIniciado(true);
    capturarFoto();
  };

  const cameraCarregada = () => {
    if (!cameraIniciada) {
      registrarFotoInicioFim(SELFIE_AUTENTICACAO_NEUTRO);
      setCameraIniciada(true);
    }
  };

  const confirmarCaptura = async (imagem) => {
    setSelfie(imagem.split(",")[1]);

    setQualidadeFoto(0.25);

    if (!selfieNeutro) {
      await salvarBiometria(true, SELFIE_BEMSIGN_NEUTRO, imagem);
      setSelfieNeutro(true);
      removerCaptura();
    }
  };

  const salvarBiometria = async (sucesso, tipo, imagem = null) => {
    if (!esperaGravacao) {
      setEsperaGravacao(true);

      try {
        const data = {
          selfie: imagem ? imagem.split(",")[1] : imgSrc.split(",")[1],
          sucesso: sucesso,
          tipo: tipo,
        };

        const apiKey = Pbkdf2(JSON.stringify(data));

        await api.post(`BemSign/GravarBiometria/${tokenSignatario}`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem(
              "autorizacao"
            )}`,
            "bemsign-key": apiKey,
            "id-sessao-facetec":
              window.localStorage
                .getItem("zoom.installationID")
                .replaceAll('"', "") || "",
          },
        });
        setEsperaGravacao(false);
      } catch (error) {
        setEsperaGravacao(false);
      }
    }
  };

  const geraInstrucao = (idInstrucao) => {
    if (idInstrucao === DIREITA) {
      setClasseInstrucao("seta-direita");
      setGrau("90");
    } else if (idInstrucao === ESQUERDA) {
      setClasseInstrucao("seta-esquerda");
      setGrau("-90");
    } else if (idInstrucao === CIMA) {
      setClasseInstrucao("seta-cima");
      setGrau("0");
    } else if (idInstrucao === BAIXO) {
      setClasseInstrucao("seta-baixo");
      setGrau("180");
    }

    executaAudioPorIdInstrucao(idInstrucao);
  };

  const executaAudioPorIdInstrucao = async (idInstrucao) => {
    try {
      const file = await import(`assets/audios/${idInstrucao}.mp3`);

      setSrcAudio(file.default);
    } catch (error) {
      console.log("Erro ao carregar áudio");
    }
  };

  const executaPorTempo = (
    funcaoPorTempo,
    funcaoFinaliza = () => {},
    ms = 1000
  ) => {
    let instrucaoGerada = retornaInstrucaoAleatoria();
    setTempo(instrucaoGerada.segundos);
    setIdInstrucao(instrucaoGerada.id);
    geraInstrucao(instrucaoGerada.id);

    const intervalTimer = setInterval(() => {
      if (funcaoPorTempo) {
        funcaoPorTempo();
      }

      if (instrucaoGerada) {
        setMensagemInstrucao(instrucaoGerada.descricao);
      }

      if (tempoCount.current > 0) {
        setTempo((tempo) => tempo - 1);
      } else if (tempoCount.current <= 0) {
        if (funcaoFinaliza) {
          funcaoFinaliza();
        }

        clearInterval(intervalTimer);
      }
    }, ms);
  };

  const capturarCamera = (video = false) => {
    if (video) {
      handleStartCaptureClick();

      executaPorTempo(null, handleStopCaptureClick);
    }
  };

  const retornaInstrucaoAleatoria = () => {
    const indice = Math.floor(Math.random() * (listaInstrucoes.length - 1 + 1));

    let mensagemInstrucao = listaInstrucoes[indice];

    setListaInstrucoes(listaInstrucoes.splice(indice, 1));

    return mensagemInstrucao;
  };

  const handleStartCaptureClick = useCallback(() => {
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: mimeType,
      videoBitsPerSecond: VIDEO_BITS,
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, mediaRecorderRef, mimeType]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    []
  );

  const handleStopCaptureClick = useCallback(async () => {
    mediaRecorderRef.current.stop();
    await registrarFotoInicioFim(SELFIE_CONCLUSAO);
  }, [mediaRecorderRef, webcamRef]);

  const gravarBiometriaVideo = async (videoBase64) => {
    const data = {
      videoBase64: videoBase64,
      idInstrucao: idInstrucao,
    };
    const response = await api.post(
      `BemSign/GravarBiometriaVideo/${tokenSignatario}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("autorizacao")}`,
        },
      }
    );
    return response.data.retorno;
  };

  useEffect(() => {
    const salvaVideo = async () => {
      if (recordedChunks.length) {
        const blob = new Blob(recordedChunks, {
          type: mimeType,
        });

        let videoBase64 = await blobToBase64(blob);
        videoBase64 = videoBase64.split(",").pop();

        await gravarBiometriaVideo(videoBase64);

        alteraProgresso();

        if (coletaDocumentoConfig) {
          return history.push("/identificacao");
        } else {
          return history.push(proximaPagina());
        }
      }
    };

    salvaVideo();
  }, [recordedChunks, mimeType]);

  const MensagemInstrucao = (props) => (
    <div className="mensagem-instrucao">
      <span>{props.mensagem}</span>
    </div>
  );

  if (loading) <div>{audio}</div>;

  return (
    <div className="webcam">
      {audio}
      <MensagemInstrucao mensagem={mensagemInstrucao} />
      <div className="camera-foto">
        <div className={`group-template-camera ${classeInstrucao}`}>
          {grau && (
            <div
              className="indicador-instrucao"
              style={{ transform: `rotate(${grau}deg)` }}
            >
              <span className="seta animacao"></span>
            </div>
          )}
          <Webcam
            width={360}
            height={640}
            className="camera"
            audio={false}
            ref={webcamRef}
            imageSmoothing
            videoConstraints={{
              deviceId: deviceId ? deviceId : undefined,
              facingMode: facingMode || "user",
            }}
            screenshotFormat="image/jpeg"
            screenshotQuality={qualidadeFoto}
            mirrored={true}
            onLoadedData={() => {
              cameraCarregada();
            }}
            controls={false}
          />
        </div>
      </div>

      {!iniciado && (
        <BotaoFixado
          onClick={iniciarCaptura}
          cor="transparent"
          corBotao={configGlobal.confirmacao_cor_botao_primario}
          corFonte={configGlobal.confirmacao_cor_fonte_botao_primario}
          removeFixed={!isSmall}
        >
          iniciar
        </BotaoFixado>
      )}

      <Timer timer={tempo} hide={!selfieNeutro} />

      <div className="logo-empresa">
        {logoEmpresa && (
          <img
            src={logoEmpresa}
            alt="logo empresa"
            className={
              idEmpresa == FUNDACAO ? "logo-fundacao" : "logo-empresa-img"
            }
          />
        )}
      </div>
    </div>
  );
}
