import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import OrientacaoImg from "assets/v2/orientacao.svg";

import { MENSAGEM_ORIENTACAO, CODIGO_ERRO_ORIENTACAO } from "./consts";

import { useApp } from "context/App";
import api from "services/api";

import "./styles.css";
import { ContainerApp } from "components/ContainerApp";
import { ContentMain } from "components/ContainerApp/styles";

export default function Orientacao() {
  const { tokenSignatario } = useApp();
  const history = useHistory();

  function continuar() {
    history.goBack();
  }

  useEffect(() => {
    const gravaLog = async () => {
      const data = {
        token: tokenSignatario,
        mensagem: `${MENSAGEM_ORIENTACAO} - Ângulo da orientação: ${
          window.orientation !== undefined ? window.orientation : "indefinido"
        }`,
        tipo: CODIGO_ERRO_ORIENTACAO,
      };

      await api.post(`BemSign/GravarLog`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("autorizacao")}`,
          "Content-Type": "application/json",
        },
      });
    };

    gravaLog();
  }, []);

  return (
    <ContainerApp className="orientacao-container">
      <ContentMain>
        <img src={OrientacaoImg} alt="orientacao" />
        <h3>Ajuste seu aparelho para a vertical</h3>
        <h2>
          Para uma visualização mais clara e uma navegação mais confortável,
          ajuste seu aparelho para a vertical. Isso proporcionará uma
          experiência mais agradável.
        </h2>
        <button className="button-bem-v2" onClick={continuar}>
          Continuar
        </button>
      </ContentMain>
    </ContainerApp>
  );
}
