import styled from "styled-components";

export const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: ${({ isOpen }) =>
    isOpen ? "0px 0px 4px 0px" : "0px 4px 4px 0px"};
  z-index: 98;
  padding: 20px;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};

  .checkbox-audio {
    width: 15px;
    height: 15px;
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
    min-width: 15px;
    min-height: 15px;
  }
`;

export const AudioOption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const AudioLabel = styled.label`
  margin-left: 10px;
  font-size: 14px;
  color: #333;
`;

export const AudioContainer = styled.div`
  position: fixed;
  left: 0;
  z-index: 99;
  cursor: pointer;

  .retratil {
    width: ${({ isOpen }) => (isOpen ? "100% !important" : "15px !important")};
    padding: ${({ isOpen }) => (isOpen ? "0 10px !important" : "0 !important")};
    img {
      display: ${({ isOpen }) => (isOpen ? null : "none !important")};
    }
  }
`;

export const ButtonAudio = styled.button`
  width: ${({ isOpen }) => (isOpen ? "100%" : "60px")};
  height: 60px;
  background-color: ${({ color }) => (color ? color : "#2b3ea1")};
  border-radius: ${({ isOpen }) =>
    isOpen ? "0px 4px 0px 0px" : "0px 4px 4px 0px"};
  display: flex;
  align-items: center;
  justify-content: ${({ isOpen }) => (isOpen ? "space-between" : "center")};
  padding: 0 10px;
  color: #fff;
`;

export const AudioText = styled.span`
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  margin-left: 10px;
  font-size: 16px;
`;
