import React from "react";

import { ReactComponent as Erro } from "assets/erro.svg";

import "./styles.css";
import QrCode from "components/QrCode";
import { useApp } from "context/App";
import { COR_PADRAO } from "helpers/consts";
import { ContainerApp } from "components/ContainerApp";
import { BoxIcon } from "components/BoxIcon";
import AtencaoIcon from "assets/v2/atencao.svg";
import { ContentMain } from "components/ContainerApp/styles";

export default function Aviso({ location }) {
  const { token, configGlobal } = useApp();
  const state = location.state;

  const textoH1 =
    state && state.textoH1 && state.textoH1.length ? state.textoH1 : "";
  const textoH2 =
    state && state.textoH2 && state.textoH2.length ? state.textoH2 : "";
  const qrCodeLink =
    state && state.qrCodeLink && state.qrCodeLink.length
      ? state.qrCodeLink
      : "";
  const showImage =
    state && state.hasOwnProperty("showImage") ? state.showImage : true;
  const alertas =
    state && state.alertas && state.alertas.length ? state.alertas : [];
  const mostrarQrCode =
    alertas.filter((x) => x.mensagem.search("dispositivo não permitido") > 0)
      .length > 0;
  const corPrimaria = configGlobal?.aviso_cor_fundo;
  const imagem = state && state.imagem ? state.imagem : null;
  const textoH3 =
    state && state.textoH3 && state.textoH3.length ? state.textoH3 : "";

  return (
    <ContainerApp
      className="aviso-container"
      showLogo={false}
      image={"aviso"}
      showAudio={false}
    >
      <ContentMain>
        {/* {showImage && <Erro width={150} fill={token ? "#fff" : "#212121"} />} */}
        <BoxIcon
          borderColor="#F6A405"
          bgColor="#FFF7E3"
          img={<img src={AtencaoIcon} alt="Aviso" />}
        />
        {textoH1 && <h1>{textoH1}</h1>}
        {textoH3 && <h3>{textoH3}</h3>}
        {textoH2 && <h2>{textoH2}</h2>}
        {alertas.length > 0 && (
          <ul className="lista-alertas">
            {alertas.map((alerta, index) => (
              <li key={index}>{alerta.mensagem}</li>
            ))}
          </ul>
        )}
        {mostrarQrCode && <QrCode className="qrCode" link={qrCodeLink} />}
      </ContentMain>
    </ContainerApp>
  );
}
