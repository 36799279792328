import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100vh;
  /* height: ${({ isSmall }) => (isSmall ? "calc(55px - 100vh)" : "100vh")}; */
  position: relative;
  padding-top: ${({ isSmall }) => (isSmall ? "55px" : "unset")};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Main = styled.div`
  overflow-y: auto;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding-top: ${({ disablePadding }) => (disablePadding ? "unset" : "60px")};
  padding-right: ${({ disablePadding }) => (disablePadding ? "unset" : "20px")};
  padding-bottom: ${({ disablePadding }) => (disablePadding ? "unset" : "15px")};
  padding-left: ${({ disablePadding }) => (disablePadding ? "unset" : "100px")};
  /* margin-top: 20px; */

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  h1,
  h2 {
    justify-content: flex-start;
    text-align: left;
    font-size: 40px;
    font-weight: 400;
  }

  h2 {
    color: #616161;
    font-size: 16px;
  }

  h3 {
    font-size: 32px;
    font-weight: 700;
  }

  h4 {
    font-size: 24px;
    font-weight: 600;
    color: #212121;
  }

  h4.titulo-tabela {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }

  h4.titulo {
    margin-bottom: 15px;
    font-size: 20px;
  }

  h5 {
    font-size: 20px;
    font-weight: 400;
    color: #616161;
  }

  h6 {
    font-size: 14px;
    font-weight: 600;
    color: #212121;
  }

  span.conteudo-tabela {
    display: block;
    font-size: 16px;
    font-weight: 400;
    color: #616161;
    margin-top: 10px;
  }

  @media screen and (max-width: 800px) {
    padding: 0;
    padding-top: 10px;
  }
`;

export const Bside = styled.div`
  background-color: #e8f1ff;
  width: 100%;
  height: 100%;
  padding: 0 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: ${({ center }) => (center ? "center" : "flex-end")};
  width: 100%;
  height: 100%;

  img.principal {
    height: 90%;
  }
`;

export const HeaderMain = styled.div`
  top: 0;
  width: 100%;
  height: 58px;
  max-height: 58px;
  background-color: #fff;
  position: fixed;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`;

export const ContentMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    padding: 0 16px;
  }
`;

export const WrapperProgressoHeader = styled.div`
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 20px
`;

export const ContainerProgressoHeader = styled.div`
  flex-grow: 1;
  padding-right: 70%;
  overflow-x: auto;

  > div {
    margin: 0;
  }
`;

export const ContainerLogoHeader = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;
