import styled from "styled-components";

export const ContainerMenu = styled.div`
  position: relative;
  margin-right: 10px;

  /* Position and sizing of burger button */
  .bm-burger-button {
    width: 24px;
    height: 24px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
    width: 24px !important;
    height: 4px !important;
  }

  .bm-burger-bars:nth-child(even) {
    /* top: 30% !important; */
  }

  .bm-burger-bars:last-child {
    /* top: 60% !important; */
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #443434;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #212121;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    margin-top: -15px;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #fff;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    overflow: hidden;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #212121;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    display: none !important;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;

    color: #d1d1d1;
    margin-bottom: 20px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
  }

  .bm-item:hover {
    cursor: pointer;
  }
`;

export const ItemMenu = styled.div`
  width: 100%;

  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;

  .checkbox-audio {
    width: 15px;
    height: 15px;
    transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -ms-transform: scale(1.5);
  }

  label {
    color: #212121;
    font-weight: 400;
    font-size: 16px;
  }
`;

export const TitleMenu = styled.div`
  color: #212121;

  text-align: center !important;
`;
