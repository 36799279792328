import React from "react";
import {
  Bside,
  Container,
  ContainerLogoHeader,
  ContainerProgressoHeader,
  ContentMain,
  HeaderMain,
  ImageContainer,
  Main,
  WrapperProgressoHeader,
} from "./styles";
import ImagemPadrao from "assets/v2/bside-padrao-2.png";
import ImagemFigital from "assets/v2/bside-figital.png";
import ImagemDataNascimento from "assets/v2/aniversario.svg";
import ImagemConfirmacao from "assets/v2/verificacao-documentos.svg";
import ImagemDocumentoPDF from "assets/v2/verificacao-documentos-pdf.svg";
import ImagemUpload from "assets/v2/upload-arquivo.svg";
import ImagemCuidado from "assets/v2/cuidado-golpes.svg";
import ImagemEndereco from "assets/v2/endereco.svg";
import ImagemConsulta from "assets/v2/consulta.svg";
import ImagemAviso from "assets/v2/aviso.svg";
import ImagemToken from "assets/v2/token-side.svg";
import { useApp } from "context/App";
import { Logo } from "components/Logo";
import ProgressBar from "components/ProgressBar";
import { Audio } from "components/Audio";
import { useMedia } from "react-use";
import { SMALL } from "helpers/breakpoints";

export const ContainerApp = ({
  children,
  className,
  style,
  showLogo = true,
  showAudio = true,
  image,
  hideBside = false,
  headerMain = false,
  disablePadding = false,
  showProgress = true,
  isAudioRetratil = true,
}) => {
  const { idEmpresa, ajudaLeitura } = useApp();
  const isSmall = useMedia(SMALL);

  const imageMap = {
    padrao: ImagemPadrao,
    figital: ImagemFigital,
    data: ImagemDataNascimento,
    confirmacao: ImagemConfirmacao,
    documento: ImagemDocumentoPDF,
    upload: ImagemUpload,
    cuidado: ImagemCuidado,
    endereco: ImagemEndereco,
    consulta: ImagemConsulta,
    aviso: ImagemAviso,
    token: ImagemToken,
  };

  const imagemInicial = ajudaLeitura ? ImagemFigital : ImagemPadrao;

  const selectedImage = imageMap[image] || imagemInicial;

  const principal = image === "padrao" || image === "figital" || !image;

  const styles = { ...style };

  return (
    <Container isSmall={isSmall}>
      {headerMain && (
        <HeaderMain>
          <WrapperProgressoHeader>
            {!isSmall && (
              <ContainerProgressoHeader>
                <ProgressBar />
              </ContainerProgressoHeader>
            )}

            <ContainerLogoHeader>
              <Logo />
            </ContainerLogoHeader>
          </WrapperProgressoHeader>
        </HeaderMain>
      )}

      {showAudio && !isSmall && idEmpresa && (
        <Audio isRetratil={isAudioRetratil} />
      )}
      <Main
        className={className}
        style={styles}
        disablePadding={disablePadding}
      >
        {showProgress && (
          <ContentMain>
            <ProgressBar />
          </ContentMain>
        )}
        {showLogo && idEmpresa && !headerMain && !isSmall && <Logo mb />}
        {children}
      </Main>
      {!isSmall && !hideBside && (
        <Bside>
          <ImageContainer center={image}>
            <img
              src={selectedImage}
              className={`${principal ? "principal" : null}`}
              alt="pessoa utilizando celular"
            />
          </ImageContainer>
        </Bside>
      )}
    </Container>
  );
};
