import React from "react";
import { Parser } from "html-to-react";
import api from "services/api";
import { useApp } from "context/App";

import "../styles.css";
import "./styles.css";
import { ContainerApp } from "components/ContainerApp";
import { BoxIcon } from "components/BoxIcon";
import AtencaoIcon from "assets/v2/atencao.svg";
import { useMedia } from "react-use";
import { SMALL } from "helpers/breakpoints";
import { ContentMain } from "components/ContainerApp/styles";
import BotaoFixado from "components/BotaoFixado";

export default function InformacoesPadrao({
  informacoes,
  marcarInformacaoValidada,
}) {
  const { tokenSignatario } = useApp();
  const isSmall = useMedia(SMALL);

  const htmlToReactParser = new Parser(React);

  async function buttonHandler() {
    marcarInformacaoValidada(informacoes.id);

    if (informacoes.idAuditoriaTipo) {
      await api.post(
        `/BemSign/GravarAuditoria/${tokenSignatario}/${informacoes.idAuditoriaTipo}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "autorizacao"
            )}`,
            "Content-Type": "application/json",
          },
        }
      );
    }
  }

  return (
    <ContainerApp image={"aviso"} className="informacoes-container">
      <ContentMain>
        <BoxIcon
          borderColor="#F6A405"
          bgColor="#FFF7E3"
          img={<img src={AtencaoIcon} alt="Aviso" />}
        />

        <h2 className="informacoes-frase">
          {htmlToReactParser.parse(informacoes.texto)}
        </h2>

        {!isSmall && (
          <button
            className="button-bem-v2"
            onClick={async () => {
              await buttonHandler();
            }}
          >
            Continuar
          </button>
        )}
      </ContentMain>

      {isSmall && (
        <BotaoFixado
          onClick={async () => {
            await buttonHandler();
          }}
          borderTop
          cor="transparent"
        >
          Continuar
        </BotaoFixado>
      )}
    </ContainerApp>
  );
}
