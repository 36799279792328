import { useApp } from "context/App";
import LogoBemsign from "assets/v2/mini-logo-bemsign.svg";
import LogoFundacao from "assets/logo-fundacao.png";
import LogoBanrisul from "assets/logo-p-banrisul.png";
import {
  CONSIGNADO,
  FUNDACAO,
  BANRISUL,
  FGTS,
  BANRISUL_2,
  BANRISUL_PORTAL_EMPRESARIAL,
  SUBROGACAO,
} from "helpers/consts";
import { LogoMiniContainer } from "./styles";

export const LogoMini = () => {
  const { idEmpresa } = useApp();

  if(!idEmpresa) {
    return ""
  }

  if (idEmpresa === FUNDACAO) {
    return (
      <LogoMiniContainer>
        <img src={LogoFundacao} alt="logo" />
      </LogoMiniContainer>
    );
  } else if (
    idEmpresa === BANRISUL ||
    idEmpresa === BANRISUL_2 ||
    idEmpresa === BANRISUL_PORTAL_EMPRESARIAL
  ) {
    return (
      <LogoMiniContainer>
        <img src={LogoBanrisul} alt="logo" />
      </LogoMiniContainer>
    );
  } else {
    return (
      <LogoMiniContainer>
        <img src={LogoBemsign} alt="logo" />
      </LogoMiniContainer>
    );
  }
};
