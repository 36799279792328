import React, { useState } from "react";
import ImageUploading from "react-images-uploading";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BotaoFixado from "components/BotaoFixado";
import "./styles.css";
import { useMedia } from "react-use";
import { SMALL } from "helpers/breakpoints";
import { ContentMain } from "components/ContainerApp/styles";
import UploadIcon from "assets/v2/upload.svg";
import Modal from "react-responsive-modal";

export default function UploadImage(props) {
  const [images, setImages] = useState([]);
  const maxMbFileSize = 5 * 1024 * 1024;
  const isSmall = useMedia(SMALL);
  const [openModal, setOpenModal] = useState(false);

  function onCloseModal() {
    setOpenModal(false);
  }

  function onChange(imageList, addUpdateIndex) {
    setImages(imageList);
    props.anexo.documento = imageList.length
      ? imageList[0].data_url.split(",")[1]
      : null;
  }

  function onError(erro) {
    if (erro.maxFileSize) {
      toast.error("O tamanho do arquivo deve ter no maximo 5MB");
    } else if (erro.acceptType) {
      toast.error("Os formatos aceitos são: jpg, png, jpeg");
    }
  }

  const ButtonAnexo = ({ onClick, isDragging, dragProps }) => {
    return (
      <button
        className="button-anexo-bem"
        onClick={onClick}
        style={isDragging ? { borderColor: "2b3ea1" } : null}
        {...dragProps}
      >
        <img src={UploadIcon} alt="icone de upload" />
        <p className="titulo">
          Clique ou arraste o arquivo aqui nesta área para fazer o upload
        </p>
        <p className="subtitulo">
          O comprovante deve possuir no máximo 5mb e pode ser nos seguintes
          formatos: JPEG, JPG e PNG.
        </p>
      </button>
    );
  };

  const ModalAnexo = () => {
    return (
      <Modal
        open={openModal}
        onClose={onCloseModal}
        center
        styles={{
          modal: { borderRadius: "8px" },
          closeButton: { right: 0, top: 0 },
        }}
        showCloseIcon={true}
      >
        {images && (
          <img
            src={images[0].data_url.split(",")}
            width={"100%"}
            style={{ maxHeight: "85vh" }}
            alt="imagem anexada"
          />
        )}
      </Modal>
    );
  };

  return (
    <ImageUploading
      value={images}
      onChange={onChange}
      dataURLKey="data_url"
      maxFileSize={maxMbFileSize}
      acceptType={["jpeg", "png", "jpg"]}
      onError={onError}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) =>
        !images.length ? (
          <div className="anexo-container">
            <ToastContainer />
            <ContentMain>
              <ButtonAnexo
                onClick={onImageUpload}
                isDragging={isDragging}
                dragProps={dragProps}
              />
            </ContentMain>
          </div>
        ) : (
          imageList.map((image, index) => (
            <div key={index} className="anexo-container">
              <ModalAnexo />
              <ContentMain>
                <div className="container-imagem-anexada">
                  <img
                    src={image.data_url}
                    alt=""
                    className="imagem-anexada"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  />
                </div>

                {!isSmall && (
                  <div className="acoes-anexo">
                    <button
                      className="button-bem-v2 secundario"
                      onClick={() => onImageRemove(index)}
                    >
                      Excluir documento
                    </button>
                    <button
                      className="button-bem-v2"
                      onClick={() => props.proximoAnexo()}
                    >
                      Continuar
                    </button>
                  </div>
                )}
              </ContentMain>

              {isSmall && (
                <BotaoFixado
                  onClick={() => props.proximoAnexo()}
                  BotaoComplemento={
                    <button
                      onClick={() => onImageRemove(index)}
                      className="button-bem-v2 secundario"
                    >
                      Excluir documento
                    </button>
                  }
                >
                  Continuar
                </BotaoFixado>
              )}
            </div>
          ))
        )
      }
    </ImageUploading>
  );
}
