import React, { useRef, useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Webcam from "react-webcam";
import IdVerso from "assets/v2/verso.svg";
import IdVersoCheck from "assets/v2/verso-check.svg";
import IdFrente from "assets/v2/frente.svg";
import IdFrenteCheck from "assets/v2/frente-check.svg";
import { useApp } from "context/App";
import isMobileDevice from "helpers/isMobile";
import audioFotoIdentificacao from "assets/audios/foto-identificacao-3.mp3";
import audioVersoDocumento from "assets/audios/verso-documento.mp3";
import { useAudio } from "react-use";
import { VOLUME } from "services/consts";
import { rotacionaImagem, ROTACAO_ESPELHO } from "helpers/rotacionaImagem";
import "./styles.css";
import { ContainerCenter } from "components/ContainerCenter";

export default function Identificacao() {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const history = useHistory();
  const {
    documentoIdentidadeFrente,
    documentoIdentidadeVerso,
    setDocumentoIdentidadeFrente,
    setDocumentoIdentidadeVerso,
    proximaPagina,
    ajudaPorVoz,
    alteraProgresso,
  } = useApp();
  const [audio, state, controls, ref] = useAudio({
    src: !documentoIdentidadeFrente
      ? audioFotoIdentificacao
      : audioVersoDocumento,
    autoPlay: false,
  });
  const [activeDeviceId, setActiveDeviceId] = useState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const devicesBack = devices.filter((x) => x.label.indexOf("back") > 0);
      if (devicesBack && devicesBack.length) {
        setActiveDeviceId(devicesBack[devicesBack.length - 1].deviceId);
      }
      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    setDocumentoIdentidadeVerso(null);
  }, []);

  useEffect(() => {
    if (ajudaPorVoz) {
      controls.volume(VOLUME);
      controls.play();
    }
  }, [documentoIdentidadeFrente]);

  const capturarFoto = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const removerCaptura = () => {
    setImgSrc(null);
  };

  const confirmarCaptura = () => {
    if (!documentoIdentidadeFrente) {
      rotacionaImagem(imgSrc, ROTACAO_ESPELHO, function (cb) {
        setDocumentoIdentidadeFrente(
          isMobileDevice() ? imgSrc.split(",")[1] : cb.split(",")[1]
        );
        removerCaptura();
      });
    } else if (!documentoIdentidadeVerso) {
      rotacionaImagem(imgSrc, ROTACAO_ESPELHO, function (cb) {
        setDocumentoIdentidadeVerso(
          isMobileDevice() ? imgSrc.split(",")[1] : cb.split(",")[1]
        );
        alteraProgresso();
        history.push(proximaPagina());
      });
    }
  };

  //Seleciona câmera traseira ou frontal dependendo do device
  const selecionaCameraDispositivo = () => {
    return isMobileDevice() ? { exact: "environment" } : "user";
  };

  const getVideoConstraints = () => {
    let constraint = { facingMode: selecionaCameraDispositivo() };
    if (activeDeviceId) {
      constraint = { ...constraint, deviceId: activeDeviceId };
    }
    return constraint;
  };

  return !loaded ? (
    <div>Carregando...</div>
  ) : (
    <ContainerCenter className="identificacao-container">
      {audio}
      <h4 className="titulo">
        {!imgSrc
          ? !documentoIdentidadeFrente
            ? "Mostre a FRENTE do documento"
            : "Mostre o VERSO do documento"
          : "A foto está clara e legível?"}
      </h4>

      <div className="camera">
        {imgSrc ? (
          <img src={imgSrc} alt="a foto está boa?" className="previsao-foto" />
        ) : (
          <Webcam
            forceScreenshotSourceSize
            videoConstraints={getVideoConstraints()}
            className="camera"
            audio={false}
            ref={webcamRef}
            imageSmoothing
            screenshotFormat="image/jpeg"
            screenshotQuality={1}
            mirrored={!isMobileDevice()}
          />
        )}
      </div>

      {!imgSrc ? (
        <div className="acoes">
          <button className="button-bem" onClick={capturarFoto}>
            Tirar foto
          </button>
        </div>
      ) : (
        <div className="acoes">
          <button
            className="button-bem"
            onClick={removerCaptura}
            style={{ marginRight: "10px" }}
          >
            Tirar novamente
          </button>
          <button className="button-bem" onClick={confirmarCaptura}>
            Confirmo
          </button>
        </div>
      )}

      <div className="frente-verso">
        <img
          src={!documentoIdentidadeFrente ? IdFrente : IdFrenteCheck}
          alt="documento frente"
        />
        <img
          src={!documentoIdentidadeVerso ? IdVerso : IdVersoCheck}
          alt="documento verso"
        />
      </div>
    </ContainerCenter>
  );
}
