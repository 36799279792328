import { useApp } from "context/App";
import { slide as Menu } from "react-burger-menu";
import { ContainerMenu, ItemMenu } from "./styles";

export const MenuHeader = () => {
  const { habilitaAudio, setHabilitaAudio, mudaAjudaPorVoz, ajudaPorVoz } =
    useApp();

  const audioDescricao = () => {
    setHabilitaAudio(!habilitaAudio);
  };

  const instrucaoAudio = () => {
    mudaAjudaPorVoz();
  };

  return (
    <ContainerMenu>
      <Menu
        right
        width={"100%"}
        styles={{ bmMenu: { backgroundColor: "#fff" } }}
      >
        <ItemMenu>
          <label name="audio-descricao">Áudio descrição</label>
          <input
            name="audio-descricao"
            type="checkbox"
            id="audio-description"
            className="checkbox-audio"
            checked={habilitaAudio}
            onChange={audioDescricao}
          />
        </ItemMenu>
        <ItemMenu>
          <label name="ajuda-voz">Instruções por áudio</label>
          <input
            name="ajuda-voz"
            type="checkbox"
            id="audio-instructions"
            className="checkbox-audio"
            checked={ajudaPorVoz}
            onChange={instrucaoAudio}
          />
        </ItemMenu>
      </Menu>
    </ContainerMenu>
  );
};
