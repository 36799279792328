import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatarCPF } from 'helpers/Formatters';
import './styles.css';
import useDownloadBiometria from 'helpers/hooks/useDownloadBiometria';

export default function ScoreExibicao({ data }) {
    const { baixar } = useDownloadBiometria();
    const history = useHistory();

    const voltar = () => {
        history.push('/score');
    }

    return <div className='container score-exibicao-container'>
        <table className="table-group-v2" cellSpacing="0" cellPadding="0">
            <thead>
                <tr>
                    <th colSpan="2">Dados da biometria</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Nome</td>
                    <td>{data.nome}</td>
                </tr>
                <tr>
                    <td>CPF</td>
                    <td>{formatarCPF(data.cpf)}</td>
                </tr>
                {data.scoreReaproveitamento && <tr>
                    <td>Score Reaproveitamento</td>
                    <td>{data.scoreReaproveitamento}</td>
                </tr>}
                {data.scoreUnico && <tr>
                    <td>Score</td>
                    <td>{data.scoreUnico}</td>
                </tr>}
                {data.scoreSerpro && <tr>
                    <td>Serpro</td>
                    <td>{data.scoreSerpro}</td>
                </tr>}
            </tbody>
        </table>
        {data.imagemReaproveitamentoBase64 && <img src={`data:image/png;base64,${data.imagemReaproveitamentoBase64}`} alt='imagem reaproveitamento' />}
        <button className='button-bem finalizar' onClick={() => baixar(data.id)}>Baixar</button>
        <button className='button-bem ' onClick={voltar}>Voltar</button>
    </div>
}