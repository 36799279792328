import React from "react";
import { useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "react-toastify/dist/ReactToastify.css";

import dateTimeMiliseconds from "helpers/dateTimeMiliseconds";

import { useApp } from "context/App";
import BotaoFixado from "components/BotaoFixado";
import "./styles.css";
import { ContainerApp } from "components/ContainerApp";
import { useMedia } from "react-use";
import { SMALL } from "helpers/breakpoints";
import { ContentMain } from "components/ContainerApp/styles";

export default function Passos() {
  const history = useHistory();

  const isSmall = useMedia(SMALL);

  const { setConfirmacaoLgpd, listaPassos, proximaPagina, configGlobal } =
    useApp();

  function iniciar(e) {
    e.preventDefault();

    setConfirmacaoLgpd(dateTimeMiliseconds());

    history.push(proximaPagina());
  }

  return (
    <ContainerApp
      className="passos-container"
      style={{ background: configGlobal.passos_cor_fundo }}
      showInfo
      isAudioRetratil={false}
    >
      <ContentMain>
        <div className="conteudo">
          <h1
            style={{
              fontSize:
                configGlobal.passos_titulo.length > 30 && isSmall
                  ? "24px"
                  : "40px",
            }}
          >
            {configGlobal.passos_titulo}
          </h1>
          <h2>{configGlobal.passos_subtitulo}</h2>

          <div className="instrucoes-container">
            {listaPassos.map((card) => (
              <div
                className="instrucao"
                key={card.altText}
                style={{
                  "--dynamic-line-color": configGlobal.passos_cor_icone,
                }}
              >
                <div
                  className="instrucao-icone"
                  style={{ borderColor: configGlobal.passos_cor_icone }}
                >
                  <card.image color={configGlobal.passos_cor_icone} />
                </div>
                <div className="instrucao-descricao">
                  <span className="titulo">{card.text}</span>
                  <span className="subtitulo">{card.subText}</span>
                </div>
              </div>
            ))}
          </div>

          {!isSmall && (
            <button
              className="button-bem-v2"
              onClick={iniciar}
              style={{
                backgroundColor: configGlobal.passos_cor_botao_primario,
                color: configGlobal.passos_cor_fonte_botao_primario,
              }}
            >
              Iniciar
            </button>
          )}

          <p className="info-adicional">
            {configGlobal?.passos_info_adicional}
          </p>
        </div>
      </ContentMain>

      {isSmall && (
        <BotaoFixado
          onClick={iniciar}
          cor="transparent"
          corBotao={configGlobal.passos_cor_botao_primario}
          corFonte={configGlobal.passos_cor_fonte_botao_primario}
        >
          Iniciar
        </BotaoFixado>
      )}
    </ContainerApp>
  );
}
