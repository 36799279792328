import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import "react-pdf/dist/Page/AnnotationLayer.css";

import "./styles.css";
import { useMedia } from "react-use";
import { SMALL } from "helpers/breakpoints";

export default function PdfResponsivo(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const isSmall = useMedia(SMALL);

  useEffect(() => {
    setPageNumber(1);
    setNumPages(null);
  }, [props.documentoBase64]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    props.documentoBase64 && (
      <div className={`pdf-container ${!isSmall ? "not-small" : "small"}`}>
        <Document
          file={`data:application/pdf;base64,${props.documentoBase64}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {!isSmall ? (
            Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))
          ) : (
            <Page pageNumber={pageNumber} />
          )}
        </Document>
        {isSmall && (
          <div
            className={`page-controls ${numPages > 1 ? "enabled" : "hidden"}`}
          >
            <button
              type="button"
              disabled={pageNumber === 1}
              onClick={() => setPageNumber(pageNumber - 1)}
            >
              ❮
            </button>
            <span>
              {pageNumber} de {numPages}
            </span>
            <button
              type="button"
              disabled={pageNumber === numPages}
              onClick={() => setPageNumber(pageNumber + 1)}
            >
              ❯
            </button>
          </div>
        )}
      </div>
    )
  );
}
