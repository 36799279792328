export const CellIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6315_418)">
        <path
          d="M17 9H7C3 9 2 10 2 14V16C2 20 3 21 7 21H18"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.5069 7.95504L16.8882 16.7052L18.9211 19.0474L21.2633 17.0145L21.882 8.26437M17.5069 7.95504L17.7287 4.81741L17.7289 4.81554C17.7594 4.38363 17.7747 4.16729 17.8706 4.00804C17.955 3.86776 18.0817 3.7578 18.2325 3.69394C18.4035 3.62149 18.6196 3.63677 19.0509 3.66727L20.9538 3.80181C21.387 3.83244 21.6036 3.84776 21.7631 3.94375C21.9034 4.02819 22.0133 4.15488 22.0772 4.30565C22.1498 4.47693 22.1345 4.69328 22.1039 5.12581L22.1038 5.12675L21.882 8.26437M17.5069 7.95504L21.882 8.26437"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.59091 17.1818L7.65909 15.3068L6.07386 16.3125L5.66477 15.5966L7.33523 14.7273L5.66477 13.858L6.07386 13.142L7.65909 14.1477L7.59091 12.2727H8.40909L8.34091 14.1477L9.92614 13.142L10.3352 13.858L8.66477 14.7273L10.3352 15.5966L9.92614 16.3125L8.34091 15.3068L8.40909 17.1818H7.59091Z"
          fill={color}
        />
        <path
          d="M14.5909 17.1818L14.6591 15.3068L13.0739 16.3125L12.6648 15.5966L14.3352 14.7273L12.6648 13.858L13.0739 13.142L14.6591 14.1477L14.5909 12.2727H15.4091L15.3409 14.1477L16.9261 13.142L17.3352 13.858L15.6648 14.7273L17.3352 15.5966L16.9261 16.3125L15.3409 15.3068L15.4091 17.1818H14.5909Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_6315_418">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
