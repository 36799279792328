import { useApp } from "context/App";
import LogoBemsign from "assets/v2/logo-bemsign.svg";
import LogoFundacao from "assets/logo-fundacao.png";
import LogoBanrisul from "assets/logo-banrisul.png";
import {
  CONSIGNADO,
  FUNDACAO,
  BANRISUL,
  FGTS,
  BANRISUL_2,
  BANRISUL_PORTAL_EMPRESARIAL,
  SUBROGACAO,
} from "helpers/consts";
import { LogoContainer } from "./styles";

export const Logo = ({ mb }) => {
  const { idEmpresa } = useApp();

  if(!idEmpresa) {
    return ""
  }

  if (idEmpresa === FUNDACAO) {
    return (
      <LogoContainer mb={mb}>
        <img src={LogoFundacao} alt="logo" className="logo-fundacao" />
      </LogoContainer>
    );
  } else if (
    idEmpresa === BANRISUL ||
    idEmpresa === BANRISUL_2 ||
    idEmpresa === BANRISUL_PORTAL_EMPRESARIAL
  ) {
    return (
      <LogoContainer mb={mb}>
        <img src={LogoBanrisul} alt="logo" className="logo-banrisul" />
      </LogoContainer>
    );
  } else {
    return (
      <LogoContainer mb={mb}>
        <img src={LogoBemsign} alt="logo" />
      </LogoContainer>
    );
  }
};
