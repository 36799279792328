import React, { useEffect, useState } from "react";
import InfoTela from "../components/InfoTela";

import "../styles.css";
import { ContainerApp } from "components/ContainerApp";
import { ContentMain } from "components/ContainerApp/styles";

export default function InformacoesPropostaAlterada({
  informacoes,
  marcarInformacaoValidada,
}) {
  const [listaAlteracoes, setListaAlteracoes] = useState(null);
  const [concordo, setConcordo] = useState(false);
  const [carregando, setCarregando] = useState(true);

  function onChangeCheckbox(e) {
    const target = e.target;
    const value = target.checked;

    setConcordo(value);
  }

  useEffect(() => {
    if (informacoes.texto) {
      const alteracoesToList = informacoes.texto.split("|");
      const alteracoes = alteracoesToList.slice(1);

      const colunas = alteracoes.map((strObj) =>
        strObj.replace(">,", "").trim().split(":")
      );

      const objColunas = colunas.map((col, index) => {
        return {
          id: index,
          descricao: col[0].trim(),
          antes: col[1].trim(),
          depois: col[2].trim(),
        };
      });

      setListaAlteracoes(objColunas);
      setCarregando(false);
    } else {
      setCarregando(false);
    }
  }, []);

  return listaAlteracoes && listaAlteracoes.length ? (
    <ContainerApp image={"aviso"} className="informacoes-container">
      <ContentMain>
        <h4>Dados alterados</h4>

        <table className="table-group-v2" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th>Descrição</th>
              <th>Valor Anterior</th>
              <th>Valor Novo</th>
            </tr>
          </thead>
          <tbody>
            {listaAlteracoes.map((coluna) => (
              <tr key={coluna.id}>
                <td>{coluna.descricao}</td>
                <td>{coluna.antes}</td>
                <td>{coluna.depois}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="checkbox-group">
          <input
            type="checkbox"
            id="concordo"
            className="checkbox-concordo"
            checked={concordo}
            onChange={onChangeCheckbox}
          />
          <label htmlFor="concordo" className="checkbox-label">
            Concordo com a alteração das informações
          </label>
        </div>

        <button
          className="button-bem"
          onClick={() => marcarInformacaoValidada(informacoes.id)}
          disabled={!concordo}
        >
          Continuar
        </button>
      </ContentMain>
    </ContainerApp>
  ) : (
    <InfoTela
      funcaoBotao={() => marcarInformacaoValidada(informacoes.id)}
      carregando={carregando}
    />
  );
}
