import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h3 {
    font-size: 32px;
    font-weight: 600;
    color: #212121;
  }

  h5 {
    font-size: 20px;
    font-weight: 400;
    color: #212121;
  }
`;

export const HeaderContent = styled.div`
  background-color: #dbe3f8;
  width: 100%;
  height: 400px;
  overflow: hidden;

  display: flex;
  justify-content: space-between;
  align-items: center;

  img.imagem-header {
    width: 400px;
    margin-top: 200px;
  }

  .imagem-container-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const MainContent = styled.div`
  background-color: #fff;
  width: 100%;
  flex: 1;

  display: flex;
  align-items: flex-start;

  .mensagem-customizada > div {
    padding: 0 40px;
    margin-top: 40px;
    max-height: 400px;
    height: 100%;
    overflow-y: auto;
  }

  .feedback-conclusao {
    padding: 40px;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    .mensagem-customizada > div {
      padding: 10px 16px;
      margin-top: 0;
      overflow-y: unset;
      max-height: none;
    }

    .feedback-conclusao {
      padding: 10px 16px;

      .feedback-container {
        justify-content: center;
        align-items: center;
        text-align: center;
      }
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
`;

export const HeaderLogoContent = styled.div`
  width: 100%;
  padding-left: 40px;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .download-container {
      width: 100%;
    }
  }
`;
