import React, { useEffect, useState } from "react";
import TabelaResponsiva from "components/TabelaResponsiva";
import InfoTela from "../components/InfoTela";

import "./styles.css";
import "../styles.css";
import { ContainerApp } from "components/ContainerApp";
import { ContentMain } from "components/ContainerApp/styles";

export default function InformacoesSubrogacao({
  informacoes,
  marcarInformacaoValidada,
}) {
  const [cabecalhoTabela, setCabecalhoTabela] = useState(null);
  const [listaDocumentos, setListaDocumentos] = useState(null);
  const [concordo, setConcordo] = useState(false);
  const [carregando, setCarregando] = useState(true);

  function onChangeCheckbox(e) {
    const target = e.target;
    const value = target.checked;

    setConcordo(value);
  }

  useEffect(() => {
    if (informacoes) {
      const cabecalho = {
        correspondente: { titulo: "Correspondente Substabelecido" },
        cnpjMf: { titulo: "CNPJ/MF", styles: { minWidth: "140px" } },
        dataAssinatura: { titulo: "Data da Assinatura do Contrato" },
        valorNominal: {
          titulo:
            "Valor Nominal do Prejuízo decorrente de Operações de Crédito Consignado",
        },
      };

      const dados = informacoes.map((item, index) => {
        const dadosInformacoes = JSON.parse(item.texto);

        return {
          id: index,
          correspondente: dadosInformacoes.correspondente,
          cnpjMf: dadosInformacoes.cnpjMf,
          dataAssinatura: dadosInformacoes.dataAssinatura,
          valorNominal: dadosInformacoes.valorNominal,
        };
      });

      setCabecalhoTabela(cabecalho);
      setListaDocumentos(dados);
      setCarregando(false);
    } else {
      setCarregando(false);
    }
  }, []);

  return listaDocumentos ? (
    <ContainerApp hideBside>
      <ContentMain>
        <div className="informacoes-container subrogacao">
          <h2>TERMO DE DECLARAÇÃO, QUITAÇÃO E SUB-ROGAÇÃO DE DIREITOS</h2>
          <p>
            <b>BANCO DO ESTADO DO RIO GRANDE DO SUL S/A - BANRISUL</b>,
            sociedade de economia mista com sede na Rua Capitão Montanha, 177,
            Centro, Porto Alegre, RS, inscrito no CNPJ/MF sob o nº
            92.702.067/0001-96, representado na forma de seus atos
            constitutivos, doravante denominado simplesmente de BANRISUL,
            DECLARA, para os devidos fins, que na condição de anuente, firmou
            Contrato de Substabelecimento de Prestação de Serviços de
            Correspondente (“CONTRATO DE SUBSTABELECIMENTO DE PRESTAÇÃO DE
            SERVIÇOS”) na forma da Resolução CMN nº 3.954, de 24 de fevereiro de
            2011, com a pessoa jurídica (“SUBSTABELECIDO”) e na data mencionadas
            nos itens 1.1, 1.2 e 1.3 infra.
          </p>
          <p>
            Por força do CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE CORRESPONDENTE
            MASTER, o BANRISUL foi ressarcido pela BEM PROMOTORA DE VENDAS E
            SERVIÇOS S.A (CORRESPONDENTE MASTER) do prejuízo causado pelo
            “CORRESPONDENTE SUBSTABELECIDO”, calculado até a presente data, e
            mencionado no item 1.4 infra.
          </p>

          <div className="table-container">
            <p>
              <b>1.</b> CARACTERÍSCAS:
            </p>
            <TabelaResponsiva
              colunas={cabecalhoTabela}
              valores={listaDocumentos}
            />
          </div>

          <p>
            <b>2.</b> O mencionado CONTRATO DE SUBSTABELECIMENTO DE PRESTAÇÃO DE
            SERVIÇOS DE CORRESPONDENTE MASTER contém as seguintes disposições
            acerca da responsabilidade do SUBSTABELECIDO:
          </p>
          <p className="n2">
            <b>2.1.</b> O SUBSTABELECIDO fica obrigado a indenizar o BANRISUL e
            ou o CORRESPONDENTE pelos prejuízos que venha a sofrer em
            decorrência de perdas no recebimento de crédito, concedido em
            operação de crédito consignado que tenha sido gerada pelo
            SUBSTABELECIDO com base: (i) em documentação falsificada e/ou
            inidônea, e/ou com irregularidade na averbação do respectivo
            contrato de crédito consignado no Órgão Consignante (ii) na
            formalização dos instrumentos contratuais; (ii) na recepção dos
            documentos do mutuário; e/ou (iii) no encaminhamento dos documentos
            referentes às operações de crédito consignado ao Banrisul.
          </p>

          <p>
            <b>3.</b> Os seguintes fatos relacionam-se à atuação do
            SUBSTABELECIDO no âmbito do CONTRATO DE SUBSTABELECIMENTO DE
            PRESTAÇÃO DE SERVIÇOS DE CORRESPONDENTE MASTER:
          </p>
          <p className="n2">
            <b>3.1.</b> Houve irregularidades na formalização dos contratos de
            empréstimo consignado firmados entre o BANRISUL e diversos clientes,
            contratos com base em documentação inidônea, todos relacionados no
            ANEXO I deste instrumento.
          </p>

          <p>
            <b>4.</b> Tendo em vista o pagamento supra mencionado o BANRISUL
            sub-roga à BEM PROMOTORA DE VENDAS E SERVIÇOS S.A de todos os
            direitos, ações, representações, privilégios e garantias que possuía
            para cobrar o ressarcimento do prejuízo mencionado no item 1.4, o
            que faz nos termos dos artigos 347 e 349 do Código Civil Brasileiro,
            para que a BEM PROMOTORA DE VENDAS E SERVIÇOS S.A cobre, diretamente
            do CORRESPONDENTE SUBSTABELECIDO, os valores Devidos.
          </p>

          <p>
            <b>5.</b> Fica desde já ressalvado que o presente{" "}
            <b>Termo de Declaração, Quitação e Sub-rogação de Direitos</b>{" "}
            refere-se apenas e tão somente aos prejuízos apurados e ressarcidos
            até a presente data, e mencionados no item 1.4, do presente Termo.
          </p>

          <p>
            E, por ser esta a expressão da verdade, o BANRISUL S.A. firma o
            presente “
            <b>Termo de Declaração, Quitação e Sub-rogação de Direitos</b>” em
            duas vias, comprometendo-me a reafirma-la perante qualquer Juízo,
            Instância ou Tribunal, para fazer valer os direitos e obrigações
            daqui oriundos.
          </p>

          <div className="checkbox-group">
            <input
              type="checkbox"
              id="concordo"
              className="checkbox-concordo"
              checked={concordo}
              onChange={onChangeCheckbox}
            />
            <label htmlFor="concordo" className="checkbox-label">
              Concordo com as informações
            </label>
          </div>

          <button
            className="button-bem"
            onClick={() => marcarInformacaoValidada()}
            disabled={!concordo}
          >
            Continuar
          </button>
        </div>
      </ContentMain>
    </ContainerApp>
  ) : (
    <InfoTela
      funcaoBotao={() => marcarInformacaoValidada()}
      carregando={carregando}
    />
  );
}
