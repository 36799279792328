import React, { useEffect, useState } from 'react';
import { useApp } from 'context/App';
import api from 'services/api';
import {
  formatarMoeda,
  formatarPercentual,
  formatarCNPJ,
  formatarCPF,
  formatarCep,
  formatarIniciarMaiusculo,
  formatarStringData,
} from 'helpers/Formatters';
import Resumo from './Resumo';
import { LARGURA } from './consts';
import TabelaRetratil from 'components/Retratil';
import { telaVisualizada } from 'helpers/telaVisualizada';
import './styles.css';

export default function TermoAdesaoFGTS(props) {
  const [dadosFGTS, setDadosFGTS] = useState(null);
  const [colSpanTd, setColSpanTd] = useState(2);
  const {
    tokenSignatario,
    autorizoDebitoValorParcelasTotalOuParcialmente,
    autorizoDebitosLimiteCreditoConta,
    autorizoDebitosObrigacaoVencidaInclusive,
    setAutorizoDebitoValorParcelasTotalOuParcialmente,
    setAutorizoDebitosLimiteCreditoConta,
    setAutorizoDebitosObrigacaoVencidaInclusive,
    configuracaoAssinatura,
  } = useApp();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= LARGURA) {
        setColSpanTd(1);
      } else {
        setColSpanTd(2);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fecthData = async () => {
      try {
        props.setIsLoading(true);
        const response = await api.get(
          `Template/ObterDadosTermoAdesaoFGTS/${tokenSignatario}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                'autorizacao',
              )}`,
            },
          },
        );
        props.setIsLoading(false);
        props.setDadosValidos(true);
        setDadosFGTS(response.data.retorno);
        if (configuracaoAssinatura.validaVisualizacaoDocumento) {
          props.setBloqueioScroll(!telaVisualizada());
        }
      } catch (err) {
        props.setDadosValidos(false);
        props.setIsLoading(false);
      }
    };
    fecthData();
  }, []);

  function onChangeCheckbox(e) {
    const target = e.target;
    const value = target.checked;
    const id = target.id;

    if (id === 'checkbox1') {
      setAutorizoDebitoValorParcelasTotalOuParcialmente(value);
    } else if (id === 'checkbox2') {
      setAutorizoDebitosLimiteCreditoConta(value);
    } else if (id === 'checkbox3') {
      setAutorizoDebitosObrigacaoVencidaInclusive(value);
    }
  }

  const renderResumo = dadosFGTS && <Resumo dadosFGTS={dadosFGTS} />;

  return (
    dadosFGTS && (
      <div className="container-termo-adesao-fgts">
        <h4 className="titulo">Termo de adesão do adiantamento FGTS</h4>
        {renderResumo}
        <p>
          Pela presente Proposta/Contrato e Adesão às Condições Gerais do
          Contrato de Concessão de Empréstimo Mediante Consignação em Folha de
          Pagamento (“Proposta”), o cliente qualificado no Quadro I (“Cliente”)
          adere às Condições Gerais do Contrato de Concessão de Empréstimo
          Mediante Consignação em Folha de Pagamento (“Condições Gerais”)
          estabelecidas pelo Banco do Estado do Rio Grande do Sul S.A.,
          instituição financeira, autorizada pelo Banco Central do Brasil para
          operar como Banco Múltiplo, constituído na forma de Sociedade de
          Economia Mista, de Capital Aberto, dotado de personalidade jurídica de
          direito privado, regido pelas normas do Conselho Monetário Nacional e
          demais regulamentações, todas de âmbito Federal, inscrita no Cadastro
          Nacional de Pessoa Jurídica (“CNPJ”) sob o n.º 92.702.067/0001-96, com
          sede na Rua Capitão Montanha, nº 177, em Porto Alegre/RS (“Banrisul”),
          nos termos e cláusulas abaixo estabelecidas:
        </p>

        <TabelaRetratil thTitulo="I - Qualificação do cliente emitente">
          <tbody>
            <tr>
              <td>Nome do cliente</td>
              <td>{formatarIniciarMaiusculo(dadosFGTS.cliente.nome)}</td>
            </tr>
            <tr>
              <td>Sexo</td>
              <td>{dadosFGTS.cliente.sexo}</td>
            </tr>
            <tr>
              <td>Data de nascimento</td>
              <td>{formatarStringData(dadosFGTS.cliente.nascimento)}</td>
            </tr>
            <tr>
              <td>Nacionalidade</td>
              <td>{dadosFGTS.cliente.nacionalidade}</td>
            </tr>
            <tr>
              <td>Cidade onde nasceu</td>
              <td>
                {formatarIniciarMaiusculo(dadosFGTS.cliente.cidadeNascimento)}
              </td>
            </tr>
            <tr>
              <td>Matrícula Funcional ou Benefício INSS</td>
              <td>{dadosFGTS.cliente.matricula}</td>
            </tr>
            <tr>
              <td>Documento de identificação</td>
              <td>{dadosFGTS.cliente.documentoIdentificacao}</td>
            </tr>
            <tr>
              <td>Órgão emissor</td>
              <td>{dadosFGTS.cliente.orgaoEmissor}</td>
            </tr>
            <tr>
              <td>Data de emissão</td>
              <td>
                {formatarStringData(dadosFGTS.cliente.dataEmissaoDocumento)}
              </td>
            </tr>
            <tr>
              <td>CPF</td>
              <td>{formatarCPF(dadosFGTS.cliente.cpf)}</td>
            </tr>
            <tr>
              <td>Estado civil</td>
              <td>{formatarIniciarMaiusculo(dadosFGTS.cliente.estadoCivil)}</td>
            </tr>
            <tr>
              <td>Endereço Residencial</td>
              <td>
                {formatarIniciarMaiusculo(
                  `${dadosFGTS.cliente.endereco.tipoLogradouro} ${
                    dadosFGTS.cliente.endereco.logradouro
                  } ${dadosFGTS.cliente.endereco.numero} ${
                    dadosFGTS.cliente.endereco.complemento
                      ? dadosFGTS.cliente.endereco.complemento
                      : ''
                  }`,
                )}
              </td>
            </tr>
            <tr>
              <td>Bairro</td>
              <td>
                {formatarIniciarMaiusculo(dadosFGTS.cliente.endereco.bairro)}
              </td>
            </tr>
            <tr>
              <td>Cidade</td>
              <td>
                {formatarIniciarMaiusculo(dadosFGTS.cliente.endereco.cidade)}
              </td>
            </tr>
            <tr>
              <td>UF</td>
              <td>{dadosFGTS.cliente.endereco.uf}</td>
            </tr>
            <tr>
              <td>CEP</td>
              <td>{formatarCep(dadosFGTS.cliente.endereco.cep)}</td>
            </tr>
            <tr>
              <td>Telefone</td>
              <td>{dadosFGTS.cliente.telefone}</td>
            </tr>
            <tr>
              <td>E-mail</td>
              <td>
                {dadosFGTS.cliente.email &&
                  dadosFGTS.cliente.email.toLowerCase()}
              </td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="II - Tipo de empréstimo">
          <tbody>
            <tr>
              <td>Nº do contrato</td>
              <td>{`${dadosFGTS.numeroContrato} - Novo`}</td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="III - Forma de liberação do crédito">
          <tbody>
            <tr>
              <td>Banco</td>
              <td>{formatarIniciarMaiusculo(dadosFGTS.banco.nome)}</td>
            </tr>
            <tr>
              <td>Agência</td>
              <td>{dadosFGTS.banco.agencia}</td>
            </tr>
            <tr>
              <td>Conta Nº</td>
              <td>{dadosFGTS.banco.conta}</td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="IV - Dados e características do crédito e CET">
          <tbody>
            {dadosFGTS.adiantamentos.map((itemAdiantamento, index) => {
              return (
                <tr key={index} className="antecipacao-small">
                  <td colSpan={4}>
                    <div className="antecipacoes">
                      <span>
                        Antecipação ano: <b>{itemAdiantamento.antecipacao}</b>
                      </span>
                      <span>
                        Data do saque:{' '}
                        <b>
                          {formatarStringData(itemAdiantamento.dataAntecipacao)}
                        </b>
                      </span>
                      <span>
                        Valor antecipação:
                        <b>
                          {formatarMoeda(itemAdiantamento.valorAntecipacao)}
                        </b>
                      </span>
                      <span>
                        Valor do IOF:{' '}
                        <b>{formatarMoeda(itemAdiantamento.valorIOF)}</b>
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}

            <tr className="cabecalho-lista antecipacao-large">
              <td>Antecipação ano</td>
              <td>Data do saque</td>
              <td>Valor antecipação</td>
              <td>Valor do IOF</td>
            </tr>
            {dadosFGTS.adiantamentos.map((itemAdiantamento, index) => {
              return (
                <tr key={index} className="antecipacao-large">
                  <td>{itemAdiantamento.antecipacao}</td>
                  <td>
                    {formatarStringData(itemAdiantamento.dataAntecipacao)}
                  </td>
                  <td>{formatarMoeda(itemAdiantamento.valorAntecipacao)}</td>
                  <td>{formatarMoeda(itemAdiantamento.valorIOF)}</td>
                </tr>
              );
            })}
            <tr className="valor-termo">
              <td colSpan={colSpanTd}>A. Valor financiado</td>
              <td colSpan={colSpanTd}>
                {formatarMoeda(
                  dadosFGTS.caracteristicaCreditoCet.valorFinanciado,
                )}
              </td>
            </tr>
            <tr className="valor-termo">
              <td colSpan={colSpanTd}>B. Taxa de juros ao mês(%)</td>
              <td colSpan={colSpanTd}>
                {formatarPercentual(
                  dadosFGTS.caracteristicaCreditoCet.taxaJurosMes,
                )}
              </td>
            </tr>
            <tr className="valor-termo">
              <td colSpan={colSpanTd}>C. CET ao mês(%)</td>
              <td colSpan={colSpanTd}>
                {formatarPercentual(dadosFGTS.caracteristicaCreditoCet.cetMes)}
              </td>
            </tr>
            <tr className="valor-termo">
              <td colSpan={colSpanTd}>D. Taxa de juros ao ano(%)</td>
              <td colSpan={colSpanTd}>
                {formatarPercentual(
                  dadosFGTS.caracteristicaCreditoCet.taxaJurosAno,
                )}
              </td>
            </tr>
            <tr className="valor-termo">
              <td colSpan={colSpanTd}>E. CET ao ano(%)</td>
              <td colSpan={colSpanTd}>
                {formatarPercentual(dadosFGTS.caracteristicaCreditoCet.cetAno)}
              </td>
            </tr>
            <tr className="valor-termo">
              <td colSpan={colSpanTd}>
                F. Seguros
                <br />% (F/A)
              </td>
              <td colSpan={colSpanTd}>
                {formatarMoeda(dadosFGTS.caracteristicaCreditoCet.valorSeguros)}
                <br />
                {formatarPercentual(
                  dadosFGTS.caracteristicaCreditoCet
                    .percentualEquivalenteSeguros,
                )}
              </td>
            </tr>
            <tr className="valor-termo">
              <td colSpan={colSpanTd}>
                G. Tributos
                <br />% (G/A)
              </td>
              <td colSpan={colSpanTd}>
                {formatarMoeda(dadosFGTS.caracteristicaCreditoCet.tributos)}
                <br />
                {formatarPercentual(
                  dadosFGTS.caracteristicaCreditoCet
                    .percentualEquivalenteTributos,
                )}
              </td>
            </tr>
            <tr className="valor-termo">
              <td colSpan={colSpanTd}>
                H. IOF
                <br />% (H/A)
              </td>
              <td colSpan={colSpanTd}>
                {formatarMoeda(dadosFGTS.caracteristicaCreditoCet.iof)}
                <br />
                {formatarPercentual(
                  dadosFGTS.caracteristicaCreditoCet.percentualEquivalenteIof,
                )}
              </td>
            </tr>
            <tr className="valor-termo">
              <td colSpan={colSpanTd}>I. Valor total a pagar</td>
              <td colSpan={colSpanTd}>
                {formatarMoeda(
                  dadosFGTS.caracteristicaCreditoCet.valorTotalPagar,
                )}
              </td>
            </tr>
            <tr className="valor-termo">
              <td colSpan={colSpanTd}>
                J. Tarifas
                <br />% (J/A)
              </td>
              <td colSpan={colSpanTd}>
                {formatarMoeda(dadosFGTS.caracteristicaCreditoCet.tarifas)}
                <br />
                {formatarPercentual(
                  dadosFGTS.caracteristicaCreditoCet
                    .percentualEquivalenteTarifas,
                )}
              </td>
            </tr>
            <tr className="valor-termo">
              <td colSpan={colSpanTd}>
                K. Outras despesas
                <br />% (K/A)
              </td>
              <td colSpan={colSpanTd}>
                {formatarMoeda(
                  dadosFGTS.caracteristicaCreditoCet.outrasDespesas,
                )}
                <br />
                {formatarPercentual(
                  dadosFGTS.caracteristicaCreditoCet
                    .percentualEquivalenteOutrasDespesas,
                )}
              </td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="V - Valor líquido a ser creditado">
          <tbody>
            <tr>
              <td>Q. Valor líquido a ser creditado[A-(J+K+P+N+O)-L]</td>
              <td>{formatarMoeda(dadosFGTS.valorLiquidoASerCreditado)}</td>
            </tr>
            <tr>
              <td>% (Q/A)</td>
              <td>
                {formatarPercentual(
                  dadosFGTS.percentualEquivalenteValorLiquidoASerCreditado,
                )}
              </td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="VI - Dados do correspondente no país/substabelecido">
          <tbody>
            <tr>
              <td>Correspondente</td>
              <td>{`${
                dadosFGTS.correspondente.codigo
              } - ${formatarIniciarMaiusculo(
                dadosFGTS.correspondente.nome,
              )}`}</td>
            </tr>
            <tr>
              <td>Cnpj</td>
              <td>{formatarCNPJ(dadosFGTS.correspondente.cnpj)}</td>
            </tr>
            <tr>
              <td>Endereço</td>
              <td>
                {formatarIniciarMaiusculo(
                  `${dadosFGTS.correspondente.endereco.tipoLogradouro || ''} ${
                    dadosFGTS.correspondente.endereco.logradouro
                  } ${dadosFGTS.correspondente.endereco.numero || ''}`,
                )}
              </td>
            </tr>
            <tr>
              <td>Bairro</td>
              <td>
                {formatarIniciarMaiusculo(
                  dadosFGTS.correspondente.endereco.bairro,
                )}
              </td>
            </tr>
            <tr>
              <td>Cidade</td>
              <td>
                {formatarIniciarMaiusculo(
                  dadosFGTS.correspondente.endereco.cidade,
                )}
              </td>
            </tr>
            <tr>
              <td>Cep</td>
              <td>{formatarCep(dadosFGTS.correspondente.endereco.cep)}</td>
            </tr>
            <tr>
              <td>Telefone</td>
              <td>{`${dadosFGTS.correspondente.ddd} ${dadosFGTS.correspondente.telefone}`}</td>
            </tr>
            <tr>
              <td>Agente de venda</td>
              <td>
                {formatarIniciarMaiusculo(dadosFGTS.correspondente.agenteVenda)}
              </td>
            </tr>
            <tr>
              <td>Cpf/mf</td>
              <td>{formatarCPF(dadosFGTS.correspondente.agenteCPF)}</td>
            </tr>
            <tr>
              <td>Comissão</td>
              <td>{dadosFGTS.correspondente.comissao || ''}</td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="VII - Autorizações">
          <tbody>
            <tr>
              <td colSpan="2">
                <input
                  type="checkbox"
                  checked={autorizoDebitoValorParcelasTotalOuParcialmente}
                  onChange={onChangeCheckbox}
                  id="checkbox1"
                />
                Autorizo o débito do valor das parcelas, total ou parcialmente,
                na conta em que será creditada esta operação ou nas contas e na
                ordem abaixo especificada:
                <div>
                  <table className="info-conta">
                    <tbody>
                      <tr className="titulo">
                        <td>Banco</td>
                        <td>Agência</td>
                        <td>Conta</td>
                      </tr>
                      <tr>
                        <td>
                          {formatarIniciarMaiusculo(dadosFGTS.banco.nome)}
                        </td>
                        <td>{dadosFGTS.banco.agencia}</td>
                        <td>{dadosFGTS.banco.conta}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <input
                  type="checkbox"
                  checked={autorizoDebitosLimiteCreditoConta}
                  onChange={onChangeCheckbox}
                  id="checkbox2"
                />
                Autorizo que os valores sejam debitados do limite de crédito em
                conta, nos casos aplicáveis
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <input
                  type="checkbox"
                  checked={autorizoDebitosObrigacaoVencidaInclusive}
                  onChange={onChangeCheckbox}
                  id="checkbox3"
                />
                Autorizo débitos decorrentes de obrigação vencida, inclusive por
                meio de lançamentos parciais
              </td>
            </tr>
          </tbody>
        </TabelaRetratil>
        <TabelaRetratil thTitulo="Condições gerais">
          <div className="lista-condicoes">
            <ol>
              <li>
                <span>PROPOSTA/CONTRATO E CONDIÇÕES GERAIS</span>
                <ol>
                  <li>
                    Pela presente Proposta/Contrato (“Proposta”), o Cliente
                    qualificado no Quadro I, declara expressamente:
                    <ol>
                      <li>
                        Aderir às Condições Gerais, registradas sob o número
                        1.731.159, em 07 de junho de 2022, no Cartório de
                        Registro de Títulos e Documentos das Pessoas Jurídicas
                        de Porto Alegre/RS, estabelecidas pelo Banco do Estado
                        do Rio Grande do Sul S.A., estando ciente e de pleno
                        acordo com as disposições contidas nas Condições Gerais
                      </li>
                      <li>
                        Que as informações prestadas nesta Proposta são a
                        verdade, à vista dos originais dos documentos de
                        identidade, Cadastro de Pessoa Física (“CPF”),
                        comprovante de endereço e rendimento, comprometendo-se a
                        manter atualizados seus dados cadastrais junto ao
                        Banrisul.
                      </li>
                      <li>
                        Que recebeu, previamente à solicitação desta Proposta,
                        todas as informações detalhadas acerca dos valores que
                        compõem o custo efetivo total (“CET”) desta Proposta.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <span>MÚTUO, LIBERAÇÃO DO CRÉDITO E JUROS</span>
                <ol>
                  <li>
                    Ao assinar de forma digital esta Proposta, o Cliente
                    solicita ao Banrisul empréstimo no valor, prazo e condições
                    discriminadas no Quadro IV, o qual representará as
                    características deste empréstimo, caso aprovado pelo
                    Banrisul.
                  </li>
                  <li>
                    A liberação do valor do empréstimo, conforme solicitado pelo
                    Cliente no Quadro IV, dar-se-á a partir da confirmação do
                    bloqueio do(s) valor(es) na(s) contas do FGTS do cliente,
                    pela Caixa Econômica Federal-CEF.
                  </li>
                  <li>
                    O presente empréstimo estará formalizado e aperfeiçoado, na
                    ocorrência das situações previstas na cláusula 2.2.
                  </li>
                  <li>
                    Em sendo aprovada a Proposta, o Cliente pagará ao Banrisul,
                    sobre o valor do empréstimo concedido, desde a data do
                    desembolso até a data da exigibilidade, a taxa de juros
                    definida nos itens B e D do Quadro IV, capitalizada
                    mensalmente, sem prejuízo dos encargos decorrentes da mora e
                    inadimplemento.
                  </li>
                  <li>
                    A liberação do empréstimo dar-se-á após a aplicação da
                    fórmula constante no Quadro V, a saber: [“A” – (“F” + “G” +
                    “J” + “K”) - “H”], onde (A) é o Valor do Empréstimo; menos o
                    somatório de (F) Seguros; (G) Tributos; (J) Tarifas; e, (K)
                    Outras Despesas; e, após o resultado, deduz-se o (H) IOF,
                    que incidirá nos termos previstos na Cláusula 6.6.
                  </li>
                </ol>
              </li>
              <li>
                <span>CESSÃO DE DIREITOS CREDITÓRIOS</span>
                <ol>
                  <li>
                    Nas operações de Antecipação de Saque Aniversário da conta
                    vinculada do FGTS, em ato concomitante a antecipação, na
                    forma autorizada pelo §3º do art. 20-D da Lei nº 8.036, o
                    CLIENTE cede e transfere, de forma irrevogável e
                    irretratável em favor do BANRISUL, nos termos do art. 66-B
                    da Lei nº 4.728/65 e artigos 18, 19 e 20 da Lei nº 9.514/97,
                    a propriedade fiduciária e a posse indireta dos direitos de
                    créditos futuros de que é titular referente ao valor do FGTS
                    da(s) competência(s) descrita(s) na contratação, referente
                    ao Saque Aniversário de contas vinculadas do FGTS.
                  </li>
                  <li>
                    Em caso de alteração dos valores das faixas, das alíquotas e
                    das parcelas adicionais da tabela do saque aniversário FGTS,
                    o cliente autoriza a elevação do valor bloqueado de FGTS, no
                    caso de existência de saldo, de modo a manter inalterado o
                    valor total do saque aniversário cedido e satisfazer o
                    pagamento da obrigação contraída. Em caso de insuficiência
                    de saldo na conta FGTS, poderá ocorrer a ampliação dos
                    prazos e da quantidade de saque-aniversário cujos direitos
                    foram cedidos. Ainda, caso o valor oriundo do FGTS no
                    vencimento não seja suficiente para efetuar a liquidação da
                    operação, será realizado o débito do saldo não liquidado nas
                    contas autorizadas, total ou parcial, até que a operação
                    seja efetivamente liquidada. Em caso de insuficiência de
                    fundos para liquidar o saldo não liquidado nas contas
                    autorizadas para débito, o cliente continuará com o valor do
                    FGTS bloqueado até que ocorra a liquidação integral da
                    operação de crédito.
                  </li>
                  <li>
                    Ocorrendo qualquer situação de movimentação que enseje o
                    saque de recursos das contas vinculadas do FGTS, o valor
                    cedido será liberado ao BANRISUL e o presente empréstimo
                    será liquidado antecipadamente.
                  </li>
                  <li>
                    O CLIENTE declara que é o legítimo titular dos Créditos
                    cedidos e que tais créditos não são objeto de qualquer
                    contestação judicial ou extrajudicial por parte de
                    terceiros.
                  </li>
                  <li>
                    O BANRISUL renuncia à sua faculdade de ter a posse direta
                    sobre os documentos que comprovam os DIREITOS CEDIDOS. O
                    CLIENTE, por sua vez, mantém os documentos que comprovam os
                    DIREITOS CEDIDOS sob sua posse direta, a título de FIEL
                    DEPOSITÁRIO, obrigando-se a entregá-los em 48 (quarenta e
                    oito) horas quando, para tanto, solicitado pelo BANRISUL,
                    declarando-se ciente de suas responsabilidades civis e
                    penais pela conservação e entrega desses documentos.
                  </li>
                  <li>
                    O CLIENTE se compromete, sob pena de vencimento antecipado,
                    a não ceder ou onerar em favor de terceiros os
                    créditos/direitos que serão cedidos.
                  </li>
                  <li>
                    A presente cessão vigorará e permanecerá integra, desde a
                    presente data até a final liquidação de todas as obrigações
                    do CLIENTE decorrentes da operação.
                  </li>
                  <li>
                    Constituirá causa de vencimento antecipado da obrigação, a
                    não concretização, perecimento ou alteração do crédito
                    cedido com relação à operação”
                  </li>
                </ol>
              </li>
              <li>
                <span>INADIMPLEMENTO</span>
                <ol>
                  <li>
                    Em caso de inadimplemento de quaisquer das obrigações
                    estipuladas nesta Proposta e/ou nas Condições Gerais ou em
                    eventual vencimento antecipado, o Cliente incorrerá em mora,
                    independentemente de aviso ou notificação de qualquer
                    espécie, passando a incidir, sobre o débito, além dos juros
                    remuneratórios pactuados, juros de mora de 1,00% (um por
                    cento) ao mês e multa de 2,00% (esta sobre o principal e
                    acessórios do débito), até a sua definitiva liquidação, sem
                    prejuízo das demais sanções contratuais e legais cabíveis.
                  </li>
                </ol>
              </li>
              <li>
                <span>TRATAMENTO DE DADOS PESSOAIS</span>
                <ol>
                  <li>
                    Pela presente Proposta/Contrato, o Banrisul, como
                    Controlador de Dados, declara que tratará os dados pessoais
                    fornecidos para a execução do Contrato de acordo com a Lei
                    Geral de Proteção de Dados Pessoais (Lei nº 13.709/18) e
                    legislação correlata aplicável, assegurando o devido sigilo,
                    segurança, transparência e respeito à sua privacidade.
                    Informações detalhadas sobre o tema poderão ser encontradas
                    nas Condições Gerais e na “Política Geral de Privacidade e
                    às Diretrizes para Proteção de Dados Pessoais”, ambos
                    disponíveis no site do Banrisul
                    (http://www.banrisul.com.br).
                  </li>
                </ol>
              </li>
              <li>
                <span>DISPOSIÇÕES GERAIS</span>
                <ol>
                  <li>
                    O Cliente satisfará, nos termos constantes nas Condições
                    Gerais, todas as despesas que o Banrisul fizer para a
                    segurança e regularidade de seus direitos.
                  </li>
                  <li>
                    Não havendo saldo na(s) conta(s) autorizada(s) para débito e
                    nem pagamento através de boleto bancário ou outra forma
                    disponibilizada, o Cliente autoriza o Banrisul a resgatar,
                    se necessário, aplicação financeira ou qualquer outra
                    disponibilidade financeira m nome do cliente, existente
                    na(s) conta(s) autorizada(s) para débito.
                  </li>
                  <li>
                    Em caso de encerramento da(s) conta(s) autorizada(s) para
                    débito, por qualquer motivo, o débito ocorrerá na próxima
                    conta autorizada, conforme previsto na autorização constante
                    deste instrumento.
                  </li>
                  <li>
                    O Cliente declara-se ciente que, caso houver valores em
                    aberto, o Banrisul efetuará a liquidação dos débitos mais
                    antigos, conforme ocorrerem os pagamentos por parte do
                    Cliente.
                  </li>
                  <li>
                    Na hipótese de liquidação antecipada desta operação, parcial
                    ou total, em conformidade com a legislação em vigor, o valor
                    presente do débito será calculado, utilizando a taxa de
                    juros pactuada nesta Proposta.
                  </li>
                  <li>
                    Sobre esta operação incide o IOF, conforme legislação em
                    vigor, sendo que tal valor se encontra a disposição do
                    Cliente, através do extrato do empréstimo ou aviso de
                    movimentação de cobrança.
                  </li>
                  <li>
                    O Cliente autoriza o Banrisul, empresas a ele ligadas e/ou
                    por ele controladas, e demais instituições que adquiram ou
                    manifestem interesse de adquirir ou de receber em garantia,
                    total ou parcialmente, operações de crédito sob
                    responsabilidade do Cliente, a consultar e registrar seus
                    dados e responsabilidades decorrentes de operações com
                    características de crédito realizadas por esse junto ao
                    Banrisul e demais instituições sujeitas ao envio de
                    informações ao Sistema de Informações Consolidadas do Banco
                    Central do Brasil (SCR - Sistema de Informações de Crédito)
                    ou que referenciarem suas operações de crédito, consoante
                    normativas vigentes e também nos eventuais sistemas que
                    venham a substituir ou a complementar o SCR.
                    <ol>
                      <li>
                        O Cliente declara estar ciente de que as finalidades
                        (prover informações ao Banco Central do Brasil, para
                        fins de monitoramento do crédito no sistema financeiro e
                        propiciar o intercâmbio de informações entre
                        instituições financeiras) e demais informações sobre o
                        SCR podem ser obtidas junto ao Banco Central do Brasil,
                        em www.bcb.gov.br/scr, ou pelo telefone 145, nos dias
                        úteis, das 8h às 20h.
                      </li>
                    </ol>
                  </li>
                  <li>
                    O Cliente desde já autoriza o Banrisul a consultar o Serviço
                    de Proteção ao Crédito (SPC) e o Serasa, com vistas a
                    verificar a sua situação cadastral.
                  </li>
                  <li>
                    O Cliente autoriza, caso aplicável, a cobrança de tarifas
                    conforme previsto na Tabela de Serviços Bancários, exposta
                    nas LOJAS BEM PRODUTOS E SERVIÇOS, Agências do BANRISUL e na
                    internet na página do Banco, site: www.banrisul.com.br
                  </li>
                  <li>
                    É direito do Cliente, nas operações de crédito consignado
                    realizadas através de correspondente bancário, desistir do
                    contrato no prazo de até 7 (sete) dias úteis, a contar do
                    recebimento do crédito, devendo restituir o valor total
                    concedido e que lhe foi entregue pelo Banrisul, acrescido de
                    eventuais tributos incidentes sobre a operação.
                  </li>
                  <li>
                    Este Contrato será assinado eletronicamente por meio da
                    plataforma BemSign, pelo que o Cliente expressamente
                    declara, de maneira inequívoca, que tal modalidade de
                    assinatura é juridicamente válida, exequível e suficiente
                    para vincular o Cliente a todos os termos e condições deste
                    Contrato, desde que firmada pelo representante legal do
                    Cliente e/ou Cliente, nos termos do artigo 10, §1º e §2º, da
                    Medida Provisória 2.200-2, de 24 de agosto de 2001.
                  </li>
                  <li>
                    É direito do Cliente, em caso de portabilidade, a liquidação
                    antecipada, se houver solicitação formal ao Banrisul.
                  </li>
                  <li>
                    O Cliente declara que este Proposta foi celebrada
                    respeitando princípios de probidade e boa-fé, sendo firmado
                    por sua livre e espontânea vontade, obrigando-se assim a
                    todos seus exatos termos e condições. O não exercício, ou
                    tolerância do Banrisul, de qualquer direito ou faculdade
                    aqui previsto, não implicará qualquer novação ou renúncia.
                  </li>
                  <li>
                    O Cliente está ciente que, havendo divergência entre esta
                    Proposta e as Condições Gerais, valerá o entabulado nessa
                    Proposta.
                  </li>
                  <li>
                    Para sugestões, reclamações e solução de conflitos, o
                    Cliente deve entrar em contato pelos seguintes canais do
                    Banrisul: Central de Relacionamento ao Cliente: Capitais e
                    Regiões Metropolitanas - 3003.0511 e demais localidades -
                    0800.7200011; Serviço de Atendimento ao Cliente:
                    0800.6461515; Ouvidoria: 0800.6442200, em dias úteis, das 9h
                    às 17h.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </TabelaRetratil>
      </div>
    )
  );
}
