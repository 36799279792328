import { LogoMini } from "components/LogoMini";
import { Container, LogoContainer } from "./styles";
import { MenuHeader } from "components/MenuHeader";

export const HeaderV2 = () => {
  return (
    <Container>
      <LogoContainer>
        <LogoMini />
      </LogoContainer>
      <MenuHeader />
    </Container>
  );
};
