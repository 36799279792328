import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useApp } from "context/App";

import virtualLocation from "helpers/virtualLocation";

import LocalizacaoIcon from "assets/v2/localizacao.svg";
import api from "services/api";
import "./styles.css";
import { ContainerApp } from "components/ContainerApp";
import { ContentMain } from "components/ContainerApp/styles";
import BotaoFixado from "components/BotaoFixado";
import { useMedia } from "react-use";
import { SMALL } from "helpers/breakpoints";

export default function Localizacao() {
  const {
    setLatitude,
    setLongitude,
    setGeolocalizacaoPorIp,
    proximaPagina,
    configGlobal,
  } = useApp();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const isSmall = useMedia(SMALL);

  function getLocation() {
    if (!loading && navigator.geolocation) {
      setLoading(true);
      navigator.geolocation.getCurrentPosition(showPosition, errorPosition);
    }
  }

  function navegarProximaPagina() {
    history.push(proximaPagina());
  }

  async function showPosition(position) {
    if (!position.coords.latitude || !position.coords.longitude) {
      await obterGeolocalizacaoPorIp();
    } else {
      await gravarGeolocalizacaoAssinatura({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        precisao: position.coords.accuracy,
      });

      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    }

    setLoading(false);
    navegarProximaPagina();
  }

  async function obterGeolocalizacaoPorIp() {
    try {
      const ipLocation = await virtualLocation();

      await gravarGeolocalizacaoAssinatura({
        latitude: ipLocation.latitude,
        longitude: ipLocation.longitude,
      });

      setLatitude(ipLocation.latitude);
      setLongitude(ipLocation.longitude);
      setGeolocalizacaoPorIp(true);
    } catch (error) {}

    setLoading(false);
  }

  async function errorPosition(error) {
    if (error.code === error.PERMISSION_DENIED) {
      await obterGeolocalizacaoPorIp();
    }

    setLoading(false);
    navegarProximaPagina();
  }

  // useEffect(() => {
  //   getLocation();
  // }, []);

  const gravarGeolocalizacaoAssinatura = async (dados) => {
    try {
      await api.post("BemSign/GravarGeolocalizacaoAssinatura", dados, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("autorizacao")}`,
        },
      });
    } catch (error) {}
  };

  return (
    <ContainerApp
      className="localizacao-container"
      style={{ background: configGlobal.localizacao_cor_fundo }}
    >
      <ContentMain>
        <img src={LocalizacaoIcon} alt="Solicitação de acesso a localização" />
        <h4>Permita o acesso a sua localização</h4>
        <h5>
          Para você poder completar todas as etapas da assinatura você precisa
          permitir o acesso a sua localização.
        </h5>

        {!isSmall && (
          <button
            className="button-bem-v2"
            onClick={getLocation}
            disabled={loading}
            style={{
              backgroundColor: configGlobal.acesso_cor_botao_primario,
              color: configGlobal.acesso_cor_fonte_botao_primario,
            }}
          >
            Solicitar acesso a localização
          </button>
        )}
      </ContentMain>

      {isSmall && (
        <BotaoFixado
          cor="transparent"
          corBotao={configGlobal.acesso_cor_botao_primario}
          corFonte={configGlobal.acesso_cor_fonte_botao_primario}
          onClick={getLocation}
          disabled={loading}
        >
          Solicitar acesso a localização
        </BotaoFixado>
      )}
    </ContainerApp>
  );
}
