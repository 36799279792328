import React, { useState, useEffect } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import api from 'services/api';

import { useApp } from 'context/App';
import {
  formatarMoeda,
  formatarPercentual,
  formatarCNPJ,
  formatarCPF,
  formatarCep,
  formatarIniciarMaiusculo,
  formatarStringData,
} from 'helpers/Formatters';
import PageLoader from 'components/PageLoader';
import ResumoCCB from './ResumoCCB';

import './styles.css';

export default function CedulaCreditoBancario(props) {
  const { tokenSignatario } = useApp();
  const [dadosCCB, setDadosCCB] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const fecthData = async () => {
      try {
        props.setIsLoading(true);
        const response = await api.get(
          `Template/ObterDadosCCB/${tokenSignatario}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                'autorizacao'
              )}`,
            },
          }
        );
        props.setIsLoading(false);
        props.setDadosValidos(true);
        setDadosCCB(response.data.retorno);
        openModal();
      } catch (err) {
        props.setDadosValidos(false);
        props.setIsLoading(false);
      }
    };

    fecthData();
  }, [props.idDocumento]);

  function openModal() {
    setOpen(true);
  }

  function closeModal() {
    setOpen(false);
  }

  const renderModalResumo = dadosCCB && (
    <Modal
      open={open}
      center
      styles={{ modal: { borderRadius: '8px' } }}
      showCloseIcon={false}
    >
      <ResumoCCB dadosCCB={dadosCCB} />
      <button className="button-bem" onClick={closeModal}>
        Continuar
      </button>
    </Modal>
  );

  const renderContratos = (contratosLiquidados) => {
    return !contratosLiquidados.contratos.length ? (
      <tbody>
        <tr className="titulo">
          <td colSpan="3" className="center">
            Nenhum contrato encontrado.
          </td>
        </tr>
      </tbody>
    ) : (
      <tbody>
        <tr className="titulo">
          <td>Instituição Financeira</td>
          <td>Contrato nº</td>
          <td>Firmado em</td>
        </tr>
        {contratosLiquidados.contratos.map((itemContrato, index) => {
          return (
            <tr key={index}>
              <td>{itemContrato.insituicaoFinanceira}</td>
              <td>{itemContrato.contrato}</td>
              <td>{formatarStringData(itemContrato.firmadoEm)}</td>
            </tr>
          );
        })}
        <tr>
          <td>Valor total liquidado</td>
          <td colSpan="2">
            {formatarMoeda(contratosLiquidados.valorTotalLiquidado)}
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    dadosCCB && (
      <div className="container-ccb">
        <h2>Cédula de Crédito Bancário (CCB)</h2>
        <p className="p-cabecalho">Lei nº 10.931, de 02 de agosto de 2004</p>
        <p className="p-cabecalho">
          Mútuo mediante consignação em folha de pagamento e autorização para
          desconto
        </p>
        <p className="p-condicoes">
          <b>BANCO CREDOR: BANCO DO ESTADO DO RIO GRANDE DO SUL S.A.</b>,
          CNPJ/MF nº 92.702.067/0001-96, com sede à Rua Capitão Montanha nº 177,
          Porto Alegre - RS, doravante simplesmente denominado BANRISUL.
        </p>
        <table className="table-group-v2" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th colSpan="2">I - Qualificação do cliente emitente</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nome do cliente</td>
              <td>{formatarIniciarMaiusculo(dadosCCB.cliente.nome)}</td>
            </tr>
            <tr>
              <td>Sexo</td>
              <td>{dadosCCB.cliente.sexo}</td>
            </tr>
            <tr>
              <td>Data de nascimento</td>
              <td>{formatarStringData(dadosCCB.cliente.nascimento)}</td>
            </tr>
            <tr>
              <td>Nacionalidade</td>
              <td>{dadosCCB.cliente.nacionalidade}</td>
            </tr>
            <tr>
              <td>Cidade onde nasceu</td>
              <td>
                {formatarIniciarMaiusculo(dadosCCB.cliente.cidadeNascimento)}
              </td>
            </tr>
            <tr>
              <td>Matrícula Funcional ou Benefício</td>
              <td>{dadosCCB.cliente.matricula}</td>
            </tr>
            <tr>
              <td>Documento de identificação</td>
              <td>{dadosCCB.cliente.documentoIdentificacao}</td>
            </tr>
            <tr>
              <td>Órgão emissor</td>
              <td>{dadosCCB.cliente.orgaoEmissor}</td>
            </tr>
            <tr>
              <td>Data de emissão</td>
              <td>{formatarStringData(dadosCCB.cliente.documentoEmitidoEm)}</td>
            </tr>
            <tr>
              <td>CPF</td>
              <td>{formatarCPF(dadosCCB.cliente.cpf)}</td>
            </tr>
            <tr>
              <td>Estado civil</td>
              <td>{formatarIniciarMaiusculo(dadosCCB.cliente.estadoCivil)}</td>
            </tr>
            <tr>
              <td>Endereço Residencial</td>
              <td>
                {formatarIniciarMaiusculo(
                  `${dadosCCB.cliente.endereco.tipoLogradouro} ${dadosCCB.cliente.endereco.logradouro} ${dadosCCB.cliente.endereco.numero}`
                )}
              </td>
            </tr>
            <tr>
              <td>Bairro</td>
              <td>
                {formatarIniciarMaiusculo(dadosCCB.cliente.endereco.bairro)}
              </td>
            </tr>
            <tr>
              <td>Cidade</td>
              <td>
                {formatarIniciarMaiusculo(dadosCCB.cliente.endereco.cidade)}
              </td>
            </tr>
            <tr>
              <td>UF</td>
              <td>{dadosCCB.cliente.endereco.uf}</td>
            </tr>
            <tr>
              <td>CEP</td>
              <td>{formatarCep(dadosCCB.cliente.endereco.cep)}</td>
            </tr>
            <tr>
              <td>Telefone</td>
              <td>{dadosCCB.cliente.telefone}</td>
            </tr>
            <tr>
              <td>E-mail</td>
              <td>
                {dadosCCB.cliente.email && dadosCCB.cliente.email.toLowerCase()}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table-group-v2" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th colSpan="2">II - Empregador/ Entidade pública pagadora</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nome</td>
              <td>
                {formatarIniciarMaiusculo(
                  dadosCCB.nomeEmpregadorEntidadePagadora
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <table className="table-group-v2" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th colSpan="2"> III - Tipo de empréstimo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nº do contrato</td>
              <td>{dadosCCB.numeroContrato}</td>
            </tr>
          </tbody>
        </table>

        <table className="table-group-v2" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th colSpan="2">IV - Forma de liberação do crédito</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Banco</td>
              <td>{formatarIniciarMaiusculo(dadosCCB.banco.nome)}</td>
            </tr>
            <tr>
              <td>Agência</td>
              <td>{dadosCCB.banco.agencia}</td>
            </tr>
            <tr>
              <td>Conta Nº</td>
              <td>{dadosCCB.banco.conta}</td>
            </tr>
          </tbody>
        </table>

        <table className="table-group-v2" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th colSpan="2">V - Dados e características do crédito e CET</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>A. Valor Financiado</td>
              <td>
                {formatarMoeda(
                  dadosCCB.caracteristicaCreditoCet.valorFinanciado
                )}
              </td>
            </tr>
            <tr>
              <td>B. Quantidade de Prestações</td>
              <td>{dadosCCB.caracteristicaCreditoCet.quantidadePrestacao}</td>
            </tr>
            <tr>
              <td>C. Valor das Prestações</td>
              <td>
                {formatarMoeda(
                  dadosCCB.caracteristicaCreditoCet.valorPrestacao
                )}
              </td>
            </tr>
            <tr>
              <td>D. Taxa de Juros ao Mês (%)</td>
              <td>
                {formatarPercentual(
                  dadosCCB.caracteristicaCreditoCet.taxaJurosMes
                )}
              </td>
            </tr>
            <tr>
              <td>E. CET ao Mês (%)</td>
              <td>
                {formatarPercentual(dadosCCB.caracteristicaCreditoCet.cetMes)}
              </td>
            </tr>
            <tr>
              <td>F. Primeiro Vencimento</td>
              <td>
                {formatarStringData(
                  dadosCCB.caracteristicaCreditoCet.primeiroVencimento
                )}
              </td>
            </tr>
            <tr>
              <td>G. Taxa de Juros ao ano (%)</td>
              <td>
                {formatarPercentual(
                  dadosCCB.caracteristicaCreditoCet.taxaJurosAno
                )}
              </td>
            </tr>
            <tr>
              <td>H. CET ao ano (%)</td>
              <td>
                {formatarPercentual(dadosCCB.caracteristicaCreditoCet.cetAno)}
              </td>
            </tr>
            <tr>
              <td>I. Último Vencimento</td>
              <td>
                {formatarStringData(
                  dadosCCB.caracteristicaCreditoCet.ultimoVencimento
                )}
              </td>
            </tr>
            <tr>
              <td>J. Seguros</td>
              <td>
                {formatarMoeda(dadosCCB.caracteristicaCreditoCet.valorSeguros)}
              </td>
            </tr>
            <tr>
              <td>K. Tributos</td>
              <td>
                {formatarMoeda(dadosCCB.caracteristicaCreditoCet.tributos)}
              </td>
            </tr>
            <tr>
              <td>K. IOF</td>
              <td>{formatarMoeda(dadosCCB.caracteristicaCreditoCet.iof)}</td>
            </tr>
          </tbody>
        </table>

        <table
          className="table-group-v2"
          id="valorasercreditado"
          cellSpacing="0"
          cellPadding="0"
        >
          <thead>
            <tr>
              <th colSpan="3">VI - Contratos a serem liquidados</th>
            </tr>
          </thead>
          {renderContratos(dadosCCB.contratosASeremLiquidados)}
        </table>

        <table className="table-group-v2" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th colSpan="2">VII - Valor líquido a ser creditado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Valor líquido a ser creditado [A-(J+K+M)–L]</td>
              <td>{formatarMoeda(dadosCCB.valorLiquidoASerCreditado)}</td>
            </tr>
          </tbody>
        </table>

        <table className="table-group-v2" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th colSpan="2">
                VIII - Dados do correspondente no país/substabelecido
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Correspondente</td>
              <td>{`${
                dadosCCB.correspondente.codigo
              } - ${formatarIniciarMaiusculo(
                dadosCCB.correspondente.nome
              )}`}</td>
            </tr>
            <tr>
              <td>Cnpj</td>
              <td>{formatarCNPJ(dadosCCB.correspondente.cnpj)}</td>
            </tr>
            <tr>
              <td>Endereço</td>
              <td>
                {formatarIniciarMaiusculo(
                  `${dadosCCB.correspondente.endereco.tipoLogradouro || ''} ${
                    dadosCCB.correspondente.endereco.logradouro
                  } ${dadosCCB.correspondente.endereco.numero || ''}`
                )}
              </td>
            </tr>
            <tr>
              <td>Bairro</td>
              <td>
                {formatarIniciarMaiusculo(
                  dadosCCB.correspondente.endereco.bairro
                )}
              </td>
            </tr>
            <tr>
              <td>Cidade</td>
              <td>
                {formatarIniciarMaiusculo(
                  dadosCCB.correspondente.endereco.cidade
                )}
              </td>
            </tr>
            <tr>
              <td>Cep</td>
              <td>{formatarCep(dadosCCB.correspondente.endereco.cep)}</td>
            </tr>
            <tr>
              <td>Telefone</td>
              <td>{`${dadosCCB.correspondente.ddd} ${dadosCCB.correspondente.telefone}`}</td>
            </tr>
            <tr>
              <td>Agente de venda</td>
              <td>
                {formatarIniciarMaiusculo(dadosCCB.correspondente.agenteVenda)}
              </td>
            </tr>
            <tr>
              <td>Cpf/mf</td>
              <td>{formatarCPF(dadosCCB.correspondente.agenteCPF)}</td>
            </tr>
            <tr>
              <td>Comissão</td>
              <td>{dadosCCB.correspondente.comissao || ''}</td>
            </tr>
          </tbody>
        </table>
        <br />
        <div className="div-condicoes">
          <p>
            Para sugestões, reclamações e solução de conflitos, o EMITENTE
            deverá entrar em contato pelos canais: Central de Relacionamento ao
            Cliente: Capitais e regiões metropolitanas - 3003.0511 e demais
            localidades - 0800.7200011; Serviço de Atendimento ao Cliente:
            0800.6461515; Ouvidoria: 0800.6442200, em dias úteis, das 09h às
            17h.
          </p>
          <p>
            <b>CONDIÇÕES GERAIS e CLÁUSULAS DA CCB:</b>
          </p>
          <p>
            O EMITENTE qualificado no Quadro I do preâmbulo, declarando sob as
            penas da Lei, que as informações prestadas são a verdade, à vista
            dos originais dos documentos de identidade, do CPF, comprovante de
            endereço e rendimento, EMITE a presente CCB em favor do BANRISUL,
            prometendo pagar, o valor aqui contratado, na forma e prazos
            descritos nesta CCB. Declara que previamente à contratação, recebeu
            todas as informações detalhadas acerca dos valores que compõem o
            Custo Efetivo Total - CET, e, se compromete a manter atualizados
            seus dados cadastrais junto ao BANRISUL.
          </p>
          <p>
            <b>1. DO MÚTUO, DA LIBERAÇÃO DO CRÉDITO E DOS JUROS</b>
          </p>
          <p>
            1.1 O BANRISUL concede ao EMITENTE empréstimo no valor, prazo e
            condições discriminados no Quadro V do preâmbulo desta CCB, os quais
            representam as características da presente CCB, a ser formalizada
            mediante aceitação do INSS da UNIÃO ou do Ente Consignante. O desembolso do valor,
            e na forma autorizada pelo EMITENTE, conforme definido no Quadro IV,
            dar-se-á a partir da autorização da averbação de margem no INSS,
            pela UNIÃO ou pelo Ente Consignante, condicionado tal ato, a confirmação oficial e
            definitiva da averbação dos descontos referentes ao pagamento das
            parcelas deste empréstimo na folha de pagamento de benefício ou de
            remuneração do EMITENTE. 1.2 O EMITENTE autoriza, expressamente,
            neste ato, o BANRISUL a liquidar: (i) o(s) contrato(s) mencionados
            no Quadro VI; (ii) eventual Operação de Crédito inadimplida junto ao
            BANRISUL; reconhecendo o(s) saldo(s) devedor(es) do(s) mesmo(s) para
            todos os fins de direito. 1.3 O EMITENTE pagará ao BANRISUL, sobre o
            valor do empréstimo, desde a data do desembolso, até a data da
            exigibilidade, a taxa de juros, capitalizados mensalmente, definida
            nos itens D e G do Quadro V, desta CCB, sem prejuízo dos ônus de
            mora. 1.4 A liberação do crédito dar-se-á após a aplicação da
            fórmula constante no Quadro VII, a saber: [“A” - (“J” + “K” + “M”) –
            “L”], onde (A) é o Valor do Empréstimo; menos o somatório de (J)
            Seguros; (K) Tributos; e, (M) Valor Total Liquidado; e, após o
            resultado, deduz-se o (L) IOF, que incidirá nos termos previstos na
            Cláusula 4.3.
          </p>
          <p>
            <b>2.DA AUTORIZAÇÃO DE DESCONTO E DAS PRESTAÇÕES MENSAIS.</b>
          </p>
          <p>
            2.1 O EMITENTE autoriza, na forma da lei, o INSS a UNIÃO ou Ente Consignante,
            em caráter irrevogável, irretratável e irrenunciável, a promover os
            descontos no benefício previdenciário, pensão ou na folha de
            pagamento de sua matrícula funcional, identificada no Quadro I, das
            prestações mensais e sucessivas em quantidade e valor conforme itens
            B e C do Quadro V desta CCB, bem como dos tributos e tarifas
            incidentes sobre a presente operação, que se encontrarem
            incorporados às prestações ajustadas, descontos estes que deverão
            perdurar até a integral liquidação do saldo devedor de
            responsabilidade do EMITENTE em favor do BANRISUL, e repassá-los ao
            BANRISUL quando dos respectivos vencimentos mensais. 2.2. Caso, na
            vigência do empréstimo, ocorra redução da margem consignável no
            benefício previdenciário, pensão ou na folha de pagamento da
            matrícula funcional do EMITENTE, que impossibilite o desconto
            integral das prestações mensais e sucessivas, a autorização da
            cláusula precedente compreende o desconto parcial das prestações, no
            montante máximo da margem mensal disponível, situação em que a
            quantidade de prestações mensais e o vencimento do empréstimo se
            postergarão até que ocorra o pagamento integral da operação de
            crédito. 2.3. No caso da não consignação da prestação devida no
            benefício, pensão ou folha de pagamento do EMITENTE, este se obriga
            a pagar ao BANRISUL os valores correspondentes da prestação não
            consignada. 2.4 O EMITENTE autoriza o BANRISUL ou empresa por ele
            contratada, a proceder a cobrança da(s) parcela(s) impaga(s), por
            impossibilidade de consignação total ou parcial no seu benefício,
            pensão ou folha de pagamento, através de débito em conta corrente de
            sua titularidade na instituição financeira indicada para a liberação
            do Crédito, prevista no Quadro IV do preâmbulo desta CCB.2.5. O
            EMITENTE autoriza o BANRISUL ou empresa por ele contratada, de forma
            irrevogável e irretratável, a proceder a cobrança a qualquer tempo
            desta operação de crédito, através de débito em conta corrente de
            sua titularidade e/ou boleto de cobrança emitido pelo Banrisul,
            da(s) parcela(s) retida(s) pelo INSS, pela UNIÃO ou pelo Ente Consignante e
            repassada(s) ao BANRISUL, mas que posteriormente foi objeto de
            pedido de devolução (glosa).
          </p>
          <p>
            <b>3. DO INADIMPLEMENTO</b>
          </p>
          <p>
            3.1. Em caso de inadimplemento de quaisquer das obrigações
            estipuladas neste instrumento ou em eventual vencimento antecipado,
            o EMITENTE incorrerá em mora, independentemente de aviso ou
            notificação de qualquer espécie, passando a incidir, sobre o débito,
            juros remuneratórios pactuados, juros de mora de 1% (um por cento)
            ao mês e multa de 2% (esta sobre o principal e acessórios do
            débito), até a sua definitiva liquidação, sem prejuízo das demais
            sanções contratuais e legais cabíveis.
          </p>
          <p>
            <b>4. DAS DISPOSIÇÕES GERAIS</b>
          </p>
          <p>
            4.1. O EMITENTE satisfará, incontinenti, por sua conta todas as
            despesas que o BANRISUL fizer para a segurança e regularidade de
            seus direitos. 4.2 Na hipótese de liquidação antecipada desta
            operação, parcial ou total, em conformidade com o CMN/Banco Central
            do Brasil, o valor presente do débito será calculado, utilizando a
            taxa de juros pactuada neste instrumento. 4.3 Sobre esta operação
            incide o IOF, conforme legislação em vigor, sendo que tal valor se
            encontra a disposição do cliente, através do extrato da operação de
            crédito ou aviso de movimentação de cobrança. 4.4 O EMITENTE
            autoriza o Banrisul, empresas a ele ligadas e/ou por ele
            controladas, e demais instituições que adquiram ou manifestem
            interesse de adquirir ou de receber em garantia, total ou
            parcialmente, operações de crédito sob responsabilidade do cliente,
            a consultar e registrar seus dados e responsabilidades decorrentes
            de operações com características de crédito realizadas por esse
            junto ao Banrisul e demais instituições sujeitas ao envio de
            informações ao Sistema de Informações Consolidadas do Banco Central
            do Brasil (SCR - Sistema de Informações de Crédito) ou que
            referenciarem suas operações de crédito, consoante normativas
            vigentes e também nos eventuais sistemas que venham a substituir ou
            a complementar o SCR. O cliente declara estar ciente de que as
            finalidades e demais informações sobre o SCR, como sua utilização,
            podem ser obtidas junto ao Banco Central do Brasil, em
            www.bcb.gov.br/ scr, ou pelo telefone 145 (dias úteis, das 8h às
            20h). 4.5. O EMITENTE autoriza o Banrisul a consultar o Serviço de
            Proteção ao Crédito (SPC) e o Serasa com vistas a verificar a sua
            situação cadastral. 4.6 O EMITENTE autoriza, desde já, a cobrança de
            tarifas conforme Tabela de Serviços Bancários, exposta nas LOJAS BEM
            PRODUTOS E SERVIÇOS, Agências do BANRISUL e na internet na página do
            Banco, site: www.banrisul.com.br. 4.7 É direito do EMITENTE, nas
            operações de crédito consignado realizadas através de correspondente
            bancário, desistir do contrato no prazo de até 7 (sete) dias úteis a
            contar do recebimento do crédito, devendo restituir o valor total
            concedido e que lhe foi entregue pelo BANRISUL, acrescido de
            eventuais tributos incidentes sobre a operação. 4.8 É direito do
            EMITENTE, em caso de portabilidade, a liquidação antecipada, se
            houver solicitação formal ao BANRISUL. 4.9 O EMITENTE declara que o
            negócio desta CCB foi celebrado respeitando princípios de probidade
            e boa-fé, sendo firmado por sua livre e espontânea vontade,
            obrigando-se assim a todos seus exatos termos e condições. O não
            exercício, ou tolerância do BANRISUL, de qualquer direito ou
            faculdade aqui previsto, não implicará qualquer novação ou renúncia.
            4.10 Esta CCB é emitida em tantas vias quanto forem os subscritores,
            além da via do BANRISUL, sendo apenas esta negociável.
          </p>
          <p className="p-cabecalho">Versão 007 - Expurgável em 6 anos</p>
        </div>
        {renderModalResumo}
      </div>
    )
  );
}
