export const SelfieIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5157 3.78336V3.47588C15.5157 2.66078 14.8549 2 14.0398 2H6.47588C5.66077 2 5 2.66078 5 3.47588V19.6491C5 20.4642 5.66078 21.125 6.47588 21.125H14.0398C14.8549 21.125 15.5157 20.4642 15.5157 19.6491V15.6519C15.5157 14.8368 14.8549 14.176 14.0398 14.176H12.2564C11.4413 14.176 10.7805 13.5153 10.7805 12.7002V8.76447C10.7805 7.94936 11.4413 7.28859 12.2564 7.28859H12.383C12.7745 7.28859 13.1499 7.13309 13.4266 6.85631L13.8535 6.42946C14.1303 6.15268 14.5057 5.99719 14.8971 5.99719H15.8268C16.2182 5.99719 16.5936 6.15268 16.8704 6.42946L17.2972 6.85631C17.574 7.13309 17.9505 7.28859 18.3419 7.28859V7.28859C19.157 7.28859 19.8203 7.94936 19.8203 8.76447V12.7002C19.8203 13.5153 19.1596 14.176 18.3445 14.176H17.7295M8.44373 19.0957H12.1334"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <circle cx="15.3312" cy="10.6093" r="1.22186" stroke={color} />
    </svg>
  );
};
