import styled from "styled-components";

export const LogoContainer = styled.div`
  margin-bottom: ${({ mb }) => (mb ? "40px" : "none")};

  .logo-fundacao {
    width: 200px;
  }

  .logo-banrisul {
    width: 200px;
  }
`;
