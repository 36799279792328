export const DocumentoIcon = ({ color }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.3048 13.0378V1.54408C17.3048 1.24359 17.0612 1 16.7607 1H7.6927H3.54408C3.24359 1 3 1.24359 3 1.54408V5.6927V19.7254C3 20.0259 3.24359 20.2695 3.54408 20.2695H7.6927M5 9H15M5 13.0378H15M5.54408 6H14.4559C14.7564 6 15 5.75641 15 5.45592V5.04408C15 4.74359 14.7564 4.5 14.4559 4.5H5.54408C5.24359 4.5 5 4.74359 5 5.04408V5.45592C5 5.75641 5.24359 6 5.54408 6ZM11.3652 16.8463H13.3263C13.5038 16.8463 13.6702 16.7597 13.772 16.6143L14.3993 15.7182C14.5011 15.5728 14.6675 15.4861 14.845 15.4861H17.3663C17.5777 15.4861 17.77 15.6086 17.8594 15.8001L18.2011 16.5324C18.2905 16.7239 18.4827 16.8463 18.6941 16.8463H20.4559C20.7564 16.8463 21 17.0899 21 17.3904V22.4005C21 22.701 20.7564 22.9446 20.4559 22.9446H11.3652C11.0648 22.9446 10.8212 22.701 10.8212 22.4005V17.3904C10.8212 17.0899 11.0648 16.8463 11.3652 16.8463Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <circle cx="15.8992" cy="19.5214" r="1.22292" stroke={color} />
    </svg>
  );
};
