import styled from "styled-components";

export const ProgressBarWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: 800px){
    margin-bottom: 20px;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  min-width: 100%;
  scroll-snap-type: x mandatory;
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  scroll-snap-align: center;
`;

export const Line = styled.div`
  height: 2px;
  width: 60px;
  background-color: ${(props) => (props.completed ? "#2B3EA1" : "#d3d3d3")};
`;

export const Circle = styled.div`
  background-color: ${(props) => (props.completed || props.current) ? "#2B3EA1" : "#d3d3d3"};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MiniCircle = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: ${(props) => (props.current ? "#fff" : "#9E9E9E")};
`;

export const CheckIcon = styled.img`
  width: 16px;
  height: 16px;
`;
