const TERMO_ADESAO = 45;
const TERMO_ADESAO_FGTS = 53;
const TERMO_FIGITAL = 56;
const TERMO_AUTORIZACAO = 38;
const COMPROVANTE_RESIDENCIA = 23;

export {
  TERMO_ADESAO,
  TERMO_ADESAO_FGTS,
  TERMO_FIGITAL,
  TERMO_AUTORIZACAO,
  COMPROVANTE_RESIDENCIA,
};
