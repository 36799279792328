import React, { useState } from "react";
import api from "services/api";
import { useApp } from "context/App";
import { toast } from "react-toastify";
import { AUMENTO_TIMEOUT_DOWNLOAD, QUANTIDADE_REQUESTS, AUMENTO_TIMEOUT_EMAIL } from "./consts";
import PageLoader from "components/PageLoader";
import "./styles.css";

let repeticoes = 1;

export default function DocumentoDownload({ resetTimer }) {
  const {
    tokenSignatario,
    configuracaoAssinatura,
    ultimaAssinatura,
    email,
    configGlobal,
  } = useApp();
  const [emailDefinido, setEmailDefinido] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  async function handleDefinirEmail() {
    api
      .put(`Email/DefinirEmailSignatario/${tokenSignatario}`, { email: emailInput}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("autorizacao")}`,
        },
      })
      .then(async (res) => {
        if (res.status != 200) {
          const error = JSON.parse(await res.data.text());
          if (error.erros.length) {
            error.erros.map((erro) => toast.error(erro.mensagem));
          }
        } else {
          setEmailDefinido(true);
        }
      });
  }

  if(configuracaoAssinatura.habilitaDownload && (!email || ultimaAssinatura))
    setTimeout(x => resetTimer(), !email ? AUMENTO_TIMEOUT_EMAIL : AUMENTO_TIMEOUT_DOWNLOAD)


  async function handlerEmailChange(email) {
    setEmailInput(email);
    resetTimer();
  }

  async function baixarDocumentos(repeticoes) {
    resetTimer();
    setIsLoading(true);

    api
      .get(`Documento/Download/${tokenSignatario}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("autorizacao")}`,
          "Content-Type": "application/octet-stream",
        },
        validateStatus: (s) => s <= 500,
        responseType: "blob",
      })
      .then(async (res) => {
        if (res.status !== 200) {
          const error = JSON.parse(await res.data.text());

          if (repeticoes >= QUANTIDADE_REQUESTS) {
            repeticoes = 1;

            setIsLoading(false);

            if (error.erros.length) {
              error.erros.map((erro) => toast.error(erro.mensagem));
            }
          } else {
            downloadTimeout();
          }
        } else {
          resetTimer();
          setIsLoading(false);

          const fileName = `documentos-assinados-${tokenSignatario}`;

          const blob = new Blob([res.data], { type: "application/zip" });

          const downloadUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
        }
      });
  }

  const repeteDownload = () => {
    setIsLoading(true);

    repeticoes = 1;

    baixarDocumentos(repeticoes);
  };

  const downloadTimeout = () => {
    setTimeout(() => {
      repeticoes++;
      baixarDocumentos(repeticoes);
    }, 5000);
  };

  const renderDownloadSection = () => (
    <>
      <section
        className={`loader-animation ${isLoading ? "enabled" : "hidden"}`}
      >
        <PageLoader isOverlay="true" width={125} height={250} />
      </section>
      <p>Você pode baixar o documento assinado através do botão abaixo.</p>
      <button
        className="button-bem-v2"
        onClick={repeteDownload}
        style={{
          backgroundColor: configGlobal.conclusao_cor_botao_primario,
          color: configGlobal.conclusao_cor_fonte_botao_primario,
        }}
      >
        Download
      </button>
    </>
  );

  const renderEmailInputSection = () => (
    <>
      <p>
        Insira seu e-mail para poder receber um lembrete para baixar o documento
        quando todos assinarem o lote.
      </p>
      <input
        type="email"
        onChange={(e) => handlerEmailChange(e.target.value)}
        placeholder="Digite seu e-mail"
        value={emailInput}
      />
      <button
        className="button-bem-v2"
        onClick={handleDefinirEmail}
        style={{
          backgroundColor: configGlobal.conclusao_cor_botao_secundario,
          color: configGlobal.conclusao_cor_fonte_botao_secundario,
        }}
      >
        Definir Email
      </button>
    </>
  );

  const renderEmailSection = () => {
    if(email)
      return (<p>
        Quando todos os signatários assinarem o lote, você receberá um lembrete
        para baixar o documento assinado diretamente no seu e-mail.
      </p>)
    if (!emailDefinido) {
      return renderEmailInputSection();
    }
    return renderEmailReceivedSection();
  };

  const renderEmailReceivedSection = () => <p>E-mail definido com sucesso.</p>;

  const renderDownloadDisabledSection = () => <p>Assinatura concluída com sucesso.</p>

  const renderContent = () => {
    if(!configuracaoAssinatura.habilitaDownload){
      return renderDownloadDisabledSection()
    } 
    else {
      return (<>
      {ultimaAssinatura ? renderDownloadSection() : renderEmailSection()}
      </>)
    }
  };

  return <div className="download-container">{renderContent()}</div>;
}
