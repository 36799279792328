import React, { useEffect, useState } from "react";

// import CameraIcon from "assets/v2/camera-info.svg";
import { CameraInfoIcon } from "components/Icons/CameraInfoIcon";

import { useApp } from "context/App";

import { CODIGO_ERRO_CAMERA } from "./consts";

import api from "services/api";

import "./styles.css";
import { useHistory } from "react-router-dom";
import { ContainerApp } from "components/ContainerApp";
import { ContentMain } from "components/ContainerApp/styles";
import { useMedia } from "react-use";
import { SMALL } from "helpers/breakpoints";
import BotaoFixado from "components/BotaoFixado";

export default function Camera() {
  const { tokenSignatario, proximaPagina, configGlobal } = useApp();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const isSmall = useMedia(SMALL);

  const executaCamera = async () => {
    if (!loading) {
      setLoading(true);
      await enviaDadosDispositivo();

      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(sucessoCamera)
        .catch(erroCamera);
    }
  };

  // useEffect(() => {
  //   const executaCamera = async () => {
  //     await enviaDadosDispositivo();

  //     navigator.mediaDevices
  //       .getUserMedia({ video: true })
  //       .then(sucessoCamera)
  //       .catch(erroCamera);
  //   };

  //   executaCamera();
  // }, []);

  function navegarProximaPagina() {
    history.push(proximaPagina());
  }

  function sucessoCamera() {
    setLoading(false);
    navegarProximaPagina();
  }

  async function erroCamera() {
    try {
      const data = {
        token: tokenSignatario,
        mensagem: "O acesso da camera não foi permitido.",
        tipo: CODIGO_ERRO_CAMERA,
      };

      await api.post(`BemSign/GravarLog`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("autorizacao")}`,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
    } finally {
      setLoading(false);
      history.push("/instrucao-camera");
    }
  }

  const enviaDadosDispositivo = async () => {
    const listaDispositivos = [];

    try {
      navigator.mediaDevices.enumerateDevices().then(async function (devices) {
        const lista = devices.map((d) => {
          return {
            idDispositivo: d.deviceId,
            tipo: d.kind,
            descricao: d.label,
            idGrupo: d.groupId,
          };
        });

        listaDispositivos.push(...lista);

        await api.post(`BemSign/GravarDispositivosMidia`, listaDispositivos, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "autorizacao"
            )}`,
            "Content-Type": "application/json",
          },
        });
      });
    } catch (error) {}
  };

  return (
    <ContainerApp
      className="camera-container"
      style={{ background: configGlobal.camera_cor_fundo }}
    >
      <ContentMain>
        <CameraInfoIcon color={configGlobal.camera_cor_icone} />
        <h4>Ative a câmera para prosseguir com a assinatura</h4>
        <h5>
          É necessário ativar para poder dar continuidade no processo de
          assinatura.
        </h5>
        {!isSmall && (
          <button
            className="button-bem-v2"
            onClick={executaCamera}
            disabled={loading}
            style={{
              backgroundColor: configGlobal.acesso_cor_botao_primario,
              color: configGlobal.acesso_cor_fonte_botao_primario,
            }}
          >
            Solicitar acesso a câmera
          </button>
        )}
      </ContentMain>

      {isSmall && (
        <BotaoFixado
          cor="transparent"
          corBotao={configGlobal.acesso_cor_botao_primario}
          corFonte={configGlobal.acesso_cor_fonte_botao_primario}
          onClick={executaCamera}
          disabled={loading}
        >
          Solicitar acesso a câmera
        </BotaoFixado>
      )}
    </ContainerApp>
  );
}
