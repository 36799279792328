import React, { useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import api from "services/api";
import PdfResponsivo from "components/PdfResponsivo";
import { useApp } from "context/App";

export default function DocumentoBase64(props) {
  const [base64, setBase64] = useState(null);
  const { tokenSignatario } = useApp();

  useEffect(() => {
    if (!props.idDocumento) {
      return;
    }
    props.setPdfRenderizado(true);
    props.setBloqueioScroll(false);
    setBase64(null);
    props.setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await api.get(
          `Documento/ObterDocumento/${tokenSignatario}/${props.idDocumento}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "autorizacao"
              )}`,
            },
          }
        );

        setBase64(response.data.retorno);
        props.setIsLoading(false);
      } catch (error) {
        toast.error("Erro ao obter documento");
      }
    };

    fetchData();
  }, [props.idDocumento]);

  return (
    <div style={{ marginTop: "-40px" }}>
      <PdfResponsivo documentoBase64={base64} />
      <ToastContainer />
    </div>
  );
}
