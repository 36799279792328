import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useApp } from "context/App";

import dateTimeMiliseconds from "helpers/dateTimeMiliseconds";
import {
  formatarCPF,
  formatarTelefone,
  formatarIniciarMaiusculo,
} from "helpers/Formatters";
import api from "services/api";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import audioConfirmeSeusDados from "assets/audios/confirme-seus-dados-3.mp3";

import { useAudio, useMedia } from "react-use";
import { VOLUME } from "services/consts";
import BotaoFixado from "components/BotaoFixado";
import "./styles.css";
import { ContainerApp } from "components/ContainerApp";
import { SMALL } from "helpers/breakpoints";
import { ContentMain } from "components/ContainerApp/styles";

export default function ConfirmacaoDados() {
  const [open, setOpen] = useState(false);
  const [naoPerturbeStatus, setNaoPerturbeStatus] = useState(false);
  const [naoPerturbeAceito, setNaoPerturbeAceito] = useState(false);
  const [esperaGravacao, setEsperaGravacao] = useState(false);
  const {
    cliente,
    cpf,
    email,
    celular,
    setConfirmacaoDados,
    tokenSignatario,
    dadosInvalidosConfig,
    proximaPagina,
    ajudaPorVoz,
    configuracaoAssinatura,
    alteraProgresso,
    configGlobal,
  } = useApp();
  const history = useHistory();
  const [audio, state, controls, ref] = useAudio({
    src: audioConfirmeSeusDados,
    autoPlay: false,
  });
  const isSmall = useMedia(SMALL);

  const corPrimaria = configGlobal.confirmacao_cor_fundo;
  const corSecundaria = configGlobal.confirmacao_cor_fundo_secundaria;
  const bordaPrimaria = configGlobal.confirmacao_cor_fundo_secundaria
    ? `1px solid ${configGlobal.confirmacao_cor_fundo_secundaria}`
    : null;

  useEffect(() => {
    if (ajudaPorVoz) {
      controls.volume(VOLUME);
      controls.play();
    }

    setNaoPerturbeStatus(
      configuracaoAssinatura && configuracaoAssinatura.naoPerturbe
    );
  }, []);

  async function gravarAuditoriaConfirmacaoConsentimento() {
    if (!esperaGravacao) {
      setEsperaGravacao(true);

      await api.post(
        `BemSign/GravarAuditoriaConfirmacaoConsentimento/${tokenSignatario}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "autorizacao"
            )}`,
            "Content-Type": "application/json",
          },
        }
      );

      setEsperaGravacao(false);
    }
  }

  async function confirmar(e) {
    e.preventDefault();

    setConfirmacaoDados(dateTimeMiliseconds());

    if (naoPerturbeStatus) {
      await gravarAuditoriaConfirmacaoConsentimento();
    }

    alteraProgresso();
    history.push(proximaPagina());
  }

  function onOpenModal(e) {
    e.preventDefault();

    setOpen(true);
  }

  function onCloseModal() {
    setOpen(false);
  }

  async function dadosErrados(e) {
    e.preventDefault();

    try {
      await api.post(
        `Assinatura/GravarInconsistencia/${tokenSignatario}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              "autorizacao"
            )}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (err) {
    } finally {
      history.push("/aviso", {
        textoH3: "Processo encerrado",
        textoH2:
          "Enviamos uma notificação para a central, e em breve, um de nossos especialistas entrará em contato com você.",
        showImage: false,
        imagem: "confirmacao",
      });
    }
  }

  function onChangeCheckbox(e) {
    const target = e.target;
    const value = target.checked;

    setNaoPerturbeAceito(value);
  }

  return (
    <ContainerApp
      className="confirmacao-dados-container"
      style={{ background: corPrimaria }}
      image="confirmacao"
    >
      {audio}
      <ContentMain>
        <h4 className="titulo">Confirme seus dados abaixo:</h4>

        <form>
          <table
            className="table-group-v2 arredonda-ultimo"
            cellSpacing="0"
            cellPadding="0"
            style={{
              borderLeft: bordaPrimaria,
              borderRight: bordaPrimaria,
              borderBottom: bordaPrimaria,
            }}
          >
            <thead>
              <tr>
                <th colSpan="2" style={{ backgroundColor: corSecundaria }}>
                  <h4 className="titulo-tabela">Meus dados</h4>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ borderTop: bordaPrimaria }}>
                  <h6>Nome</h6>
                  <span className="conteudo-tabela">
                    {formatarIniciarMaiusculo(cliente)}
                  </span>
                </td>
              </tr>
              <tr>
                <td style={{ borderTop: bordaPrimaria }}>
                  <h6>CPF</h6>
                  <span className="conteudo-tabela">{formatarCPF(cpf)}</span>
                </td>
              </tr>
              {celular && (
                <tr>
                  <td style={{ borderTop: bordaPrimaria }}>
                    <h6>Celular</h6>
                    <span className="conteudo-tabela">
                      {formatarTelefone(celular)}
                    </span>
                  </td>
                </tr>
              )}
              {email && (
                <tr>
                  <td style={{ borderTop: bordaPrimaria }}>
                    <h6>E-mail</h6>
                    <span className="conteudo-tabela">
                      {email.toLowerCase()}
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {naoPerturbeStatus && (
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="concordo"
                className="checkbox-concordo"
                checked={naoPerturbeAceito}
                onChange={onChangeCheckbox}
              />
              <label htmlFor="concordo" className="checkbox-label">
                Telefone cadastrado na lista <b>Não Perturbe</b>. Confirmo que
                estou ciente e autorizo prosseguir com a operação de assinatura
                eletrônica.
              </label>
            </div>
          )}
        </form>
        {!isSmall && (
          <div className="acoes">
            {dadosInvalidosConfig && (
              <a onClick={onOpenModal}>Dados incorretos</a>
            )}
            <button
              className="button-bem-v2"
              style={{
                backgroundColor: configGlobal.confirmacao_cor_botao_primario,
                color: configGlobal.confirmacao_cor_fonte_botao_primario,
              }}
              onClick={confirmar}
            >
              Dados corretos
            </button>
          </div>
        )}
      </ContentMain>

      <Modal
        open={open}
        onClose={onCloseModal}
        center
        styles={{ modal: { borderRadius: "8px" } }}
        showCloseIcon={false}
      >
        <div>
          <h4
            style={{ fontSize: "24px", fontWeight: "600", marginBottom: "5px" }}
          >
            Dados incorretos
          </h4>
          <span className="titulo-tabela">
            Seus dados estão incorretos, você pode continuar com o processo ou
            informar que os dados estão incorretos.
          </span>

          <div className="acoes-modal-confirmacao">
            <button className="button-bem-v2" onClick={onCloseModal}>
              Continuar assinatura
            </button>
            <button className="button-bem-v2 secundario" onClick={dadosErrados}>
              Estão errados
            </button>
          </div>
        </div>
      </Modal>
      {isSmall && (
        <BotaoFixado
          onClick={confirmar}
          disabled={(!naoPerturbeAceito && naoPerturbeStatus) || esperaGravacao}
          borderTop
          corBotao={configGlobal.confirmacao_cor_botao_primario}
          corFonte={configGlobal.confirmacao_cor_fonte_botao_primario}
          cor="transparent"
          BotaoComplemento={
            dadosInvalidosConfig ? (
              <button
                className="button-bem-v2 secundario"
                onClick={onOpenModal}
              >
                Dados incorretos
              </button>
            ) : null
          }
        >
          Dados corretos
        </BotaoFixado>
      )}
    </ContainerApp>
  );
}
