import React, { useState, useEffect } from 'react';

import api from 'services/api';
import { useApp } from 'context/App';

import { formatarCPF, formatarStringData, formatarTelefone } from 'helpers/Formatters';
import { telaVisualizada } from 'helpers/telaVisualizada';

import './styles.css';

export default function CadastroAgente(props) {
    const [dadosAgente, setDadosAgente] = useState(null);
    const { tokenSignatario, configuracaoAssinatura } = useApp();
    const { setIsLoading, setDadosValidos, setBloqueioScroll } = props;

    useEffect(() => {
        const fecthData = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(
                    `Template/ObterDadosTermoValidacaoAgente/${tokenSignatario}`,
                    {
                        headers: {
                            Authorization: `Bearer ${window.localStorage.getItem(
                                'autorizacao',
                            )}`,
                        },
                    },
                );

                setIsLoading(false);
                setDadosValidos(true);
                setDadosAgente(response.data.retorno);
                if (configuracaoAssinatura.validaVisualizacaoDocumento) {
                    setBloqueioScroll(!telaVisualizada());
                }
            } catch (err) {
                setDadosValidos(false);
                setIsLoading(false);
            }
        };
        fecthData();
    }, []);

    return (
        dadosAgente && (
            <div className="container-cadastro-agente">
                <h4 className="titulo">Formulário validação de dados</h4>
                <p>Este termo tem por finalidade registrar a manifestação voluntária, informada e expressa do Agente, concordando de forma inequívoca com o tratamento de seus dados pessoais para uma finalidade específica, em especial: confirmação, segurança e validação de dados cadastrais, de acordo com as disposições da Lei nº 13.709 - Lei Geral de Proteção de Dados Pessoais (LGPD). Estou de acordo com tal processamento.</p>
                <table className="table-group-v2" cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th colSpan="2">Identificacao do agente</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Nome</td>
                            <td>{dadosAgente.nome}</td>
                        </tr>
                        <tr>
                            <td>CPF</td>
                            <td>{formatarCPF(dadosAgente.cpf)}</td>
                        </tr>
                        <tr>
                            <td>Data nascimento</td>
                            <td>{formatarStringData(dadosAgente.dataNascimento)}</td>
                        </tr>
                        <tr>
                            <td>Telefone</td>
                            <td>{formatarTelefone(dadosAgente.numeroCelular)}</td>
                        </tr>
                        <tr>
                            <td>E-mail</td>
                            <td>{dadosAgente.email}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-group-v2" cellSpacing="0" cellPadding="0">
                    <thead>
                        <tr>
                            <th colSpan="2">Endereço</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Rua</td>
                            <td>{dadosAgente.rua}</td>
                        </tr>
                        <tr>
                            <td>Número</td>
                            <td>{dadosAgente.enderecoNumero}</td>
                        </tr>
                        <tr>
                            <td>Complemento</td>
                            <td>{dadosAgente.complemento}</td>
                        </tr>
                        <tr>
                            <td>Bairro</td>
                            <td>{dadosAgente.bairro}</td>
                        </tr>
                        <tr>
                            <td>CEP</td>
                            <td>{dadosAgente.cep}</td>
                        </tr>
                        <tr>
                            <td>Município</td>
                            <td>{dadosAgente.municipio}</td>
                        </tr>
                        <tr>
                            <td>Estado</td>
                            <td>{dadosAgente.uf}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    );
}
