import React from "react";
import { Container, MainContent } from "./styles";

import { useMedia } from "react-use";
import { SMALL } from "helpers/breakpoints";

export const ContainerCenter = ({ children, className, isLoading }) => {
  const isSmall = useMedia(SMALL);

  return (
    <Container className={className} isLoading={isLoading}>
        <MainContent className={`${isSmall ? null : "box-centro"}`}>
          {children}
        </MainContent>
    </Container>
  );
};
