import styled from "styled-components";

export const ContainerCard = styled.div`
  width: 100%;

  display: flex;
  gap: 20px;

  @media screen and (max-width: 1410px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  width: 100%;
  border: 1px solid #2b3ea1;
  border-radius: 8px;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Titulo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  h6 {
    font-size: 16px;
    color: #2b3ea1;
    font-weight: 600;
  }
`;

export const Conteudo = styled.div`
  font-size: 40px;
  font-weight: 600;
  color: #2b3ea1;

  @media screen and (max-width: 320px) {
    font-size: 30px;
  }
`;
