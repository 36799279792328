import React from "react";
import { Container } from "./styles";

export const BoxIcon = ({ borderColor, bgColor, img, padding, mb, w, h }) => {
  return (
    <Container
      borderColor={borderColor}
      bgColor={bgColor}
      padding={padding}
      mb={mb}
      w={w}
      h={h}
    >
      {img}
    </Container>
  );
};
