import React, { useEffect, useRef, useState } from "react";
import {
  AudioContainer,
  ButtonAudio,
  DrawerContainer,
  AudioOption,
  AudioLabel,
  AudioText,
} from "./styles";
import AudioIcon from "../../assets/v2/audio.svg";
import { useApp } from "context/App";

export const Audio = ({isRetratil = true}) => {
  const {
    ajudaPorVoz,
    mudaAjudaPorVoz,
    habilitaAudio,
    setHabilitaAudio,
    configGlobal,
  } = useApp();
  const [isOpen, setIsOpen] = useState(false);
  const drawerRef = useRef(null);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const instrucaoAudio = () => {
    mudaAjudaPorVoz();
  };

  const audioDescricao = () => {
    setHabilitaAudio(!habilitaAudio);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <AudioContainer>
      <ButtonAudio
        onClick={toggleDrawer}
        isOpen={isOpen}
        color={configGlobal?.passos_cor_botao_audio}
        className={isRetratil && !isOpen ? "retratil" : null}
      >
        <img src={AudioIcon} alt="ajuda por áudio" />
        <AudioText isOpen={isOpen}>Opções de áudio</AudioText>
      </ButtonAudio>
      <DrawerContainer ref={drawerRef} isOpen={isOpen}>
        <AudioOption>
          <input
            type="checkbox"
            id="audio-description"
            className="checkbox-audio"
            checked={habilitaAudio}
            onChange={audioDescricao}
          />
          <AudioLabel htmlFor="audio-description">Áudio descrição</AudioLabel>
        </AudioOption>
        <AudioOption>
          <input
            type="checkbox"
            id="audio-instructions"
            className="checkbox-audio"
            checked={ajudaPorVoz}
            onChange={instrucaoAudio}
          />
          <AudioLabel htmlFor="audio-instructions">
            Instruções por áudio
          </AudioLabel>
        </AudioOption>
      </DrawerContainer>
    </AudioContainer>
  );
};
