import styled from "styled-components";

export const Container = styled.div`
  border: ${({ borderColor }) =>
    `1px solid ${borderColor ? borderColor : "#2B3EA1"}`};
  border-radius: 8px;
  background-color: ${({ bgColor }) => `${bgColor ? bgColor : "#E8F1FF"}`};
  padding: ${({ padding }) => `${padding ? padding : "20px"}`};
  margin-bottom: ${({ mb }) => `${mb ? mb : "25px"}`};
  width: ${({ w }) => `${w ? w : "unset"}`};
  height: ${({ h }) => `${h ? h : "unset"}`};

  display: ${({ w, h }) => `${w && h ? "flex" : "unset"}`};
  justify-content: ${({ w, h }) => `${w ? "center" : "unset"}`};
  align-items: ${({ w, h }) => `${w ? "center" : "unset"}`};
`;
