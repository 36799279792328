import React, { useEffect } from 'react';

import { useApp } from 'context/App';
import TabelaRetratil from 'components/Retratil';
import { telaVisualizada } from 'helpers/telaVisualizada';
import audioConsultaDataPrev from 'assets/audios/consulta-dataprev.mp3';
import { useAudio } from 'react-use';
import { VOLUME } from 'services/consts';

import './styles.css';

export default function TermoAutorizacao(props) {
  const { cliente, cpf, configuracaoAssinatura, habilitaAudio } = useApp();
  const [audio, state, controls, ref] = useAudio({
    src: audioConsultaDataPrev,
    autoPlay: false
  });

  useEffect(() => {
    if (habilitaAudio){
      controls.volume(VOLUME);
      controls.play();
    }

    props.setDadosValidos(true);
    if (configuracaoAssinatura.validaVisualizacaoDocumento) {
      props.setBloqueioScroll(!telaVisualizada());
    }
  }, []);

  return (
    <div className="termo-autorizacao-container">
      {audio}
      <h4 className="titulo">Autorize a consulta de seus dados</h4>
      <table className="table-group-v2" cellSpacing="0" cellPadding="0">
        <thead>
          <tr>
            <th>Dados a serem consultados na Dataprev</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Número/Situação/Espécie do benefício</td>
          </tr>
          <tr>
            <td>Margem consignável disponível</td>
          </tr>
          <tr>
            <td>Agência pagadora</td>
          </tr>
          <tr>
            <td>Conta Corrente onde o benefício é pago</td>
          </tr>
        </tbody>
      </table>
      <TabelaRetratil thTitulo="Outros dados">
        <tbody>
          <tr>
            <td>CPF</td>
          </tr>
          <tr>
            <td>Data de nascimento</td>
          </tr>
          <tr>
            <td>Nome completo</td>
          </tr>

          <tr>
            <td>Indicação de que o benefício foi concedido por liminar</td>
          </tr>
          <tr>
            <td>Data de cessação do benefício - DCB (se houver)</td>
          </tr>
          <tr>
            <td>Possui representante legal</td>
          </tr>
          <tr>
            <td>Possui procurador</td>
          </tr>
          <tr>
            <td>Possui entidade representação</td>
          </tr>
          <tr>
            <td>Pensão alimentícia</td>
          </tr>
          <tr>
            <td>Bloqueado para empréstimo</td>
          </tr>
          <tr>
            <td>Data da última perícia médica</td>
          </tr>
          <tr>
            <td>Data do despacho do benefício - DDB</td>
          </tr>
          <tr>
            <td>UF onde o beneficiário recebe os proventos</td>
          </tr>
          <tr>
            <td>Tipo de crédito (Cartão ou Conta Corrente)</td>
          </tr>
          <tr>
            <td>Indicação da Instituição Financeira que paga o benefício</td>
          </tr>

          <tr>
            <td>Margem consignável disponível para Cartão</td>
          </tr>
          <tr>
            <td>Valor limite para Cartão</td>
          </tr>
          <tr>
            <td>Quantidade de empréstimos ativos / suspensos</td>
          </tr>
        </tbody>
      </TabelaRetratil>
      <p>
        Eu, <b>{cliente}</b>, portador do CPF <b>{cpf}</b>, autorizo o INSS /
        DATAPREV a disponibilizar as informações abaixo indicadas para apoiar a
        contratação / simulação de Empréstimo Consignado / Cartão Consignado de
        benefícios do INSS para subsidiar a proposta pelo Banco Credor
      </p>
    </div>
  );
}
