export const SolicitacaoCompletoIcon = ({ color }) => {
  return (
    <svg
      width="143"
      height="98"
      viewBox="0 0 143 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="106.382"
        cy="61.794"
        r="34.0043"
        fill={color}
        stroke="white"
        strokeWidth="3.5794"
      />
      <g clipPath="url(#clip0_281_31)">
        <path
          d="M114.771 57.0963L123.518 50.9722C123.644 50.884 123.791 50.8321 123.944 50.822C124.098 50.812 124.251 50.8442 124.387 50.9152C124.523 50.9861 124.637 51.0931 124.717 51.2245C124.797 51.3558 124.839 51.5065 124.838 51.6601V71.9285C124.839 72.0821 124.797 72.2328 124.717 72.3641C124.637 72.4955 124.523 72.6025 124.387 72.6734C124.251 72.7444 124.098 72.7766 123.944 72.7666C123.791 72.7565 123.644 72.7046 123.518 72.6164L114.771 66.4923V73.5392C114.771 73.9842 114.595 74.411 114.28 74.7256C113.965 75.0403 113.538 75.217 113.093 75.217H89.6036C89.1586 75.217 88.7319 75.0403 88.4172 74.7256C88.1026 74.411 87.9258 73.9842 87.9258 73.5392V50.0494C87.9258 49.6044 88.1026 49.1776 88.4172 48.863C88.7319 48.5483 89.1586 48.3716 89.6036 48.3716H113.093C113.538 48.3716 113.965 48.5483 114.28 48.863C114.595 49.1776 114.771 49.6044 114.771 50.0494V57.0963ZM114.771 62.3966L121.483 67.0946V56.4923L114.771 61.1903V62.395V62.3966ZM91.2815 51.7272V71.8614H111.416V51.7272H91.2815ZM94.6372 55.0829H97.9928V58.4386H94.6372V55.0829Z"
          fill="white"
        />
      </g>
      <circle
        cx="57.794"
        cy="61.794"
        r="34.0043"
        fill={color}
        stroke="white"
        strokeWidth="3.5794"
      />
      <path
        d="M61.2642 77.8615C65.5968 74.0485 73.3049 66.2583 73.3049 59.1094C73.3049 55.154 71.7336 51.3605 68.9366 48.5635C66.1397 45.7666 62.3462 44.1953 58.3907 44.1953C54.4352 44.1953 50.6418 45.7666 47.8448 48.5635C45.0479 51.3605 43.4766 55.154 43.4766 59.1094C43.4766 66.2583 51.1822 74.0485 55.5173 77.8615C56.308 78.5673 57.3308 78.9574 58.3907 78.9574C59.4506 78.9574 60.4735 78.5673 61.2642 77.8615ZM53.4193 59.1094C53.4193 57.7909 53.9431 56.5265 54.8754 55.5941C55.8077 54.6618 57.0722 54.1381 58.3907 54.1381C59.7092 54.1381 60.9737 54.6618 61.906 55.5941C62.8383 56.5265 63.3621 57.7909 63.3621 59.1094C63.3621 60.4279 62.8383 61.6924 61.906 62.6247C60.9737 63.5571 59.7092 64.0808 58.3907 64.0808C57.0722 64.0808 55.8077 63.5571 54.8754 62.6247C53.9431 61.6924 53.4193 60.4279 53.4193 59.1094Z"
        fill="white"
      />
      <path
        d="M41.2561 19.2408V3M27.0467 25.7371L13.9303 14.9099M18.3024 39.8125H3"
        stroke={color}
        strokeWidth="5.96566"
        strokeLinecap="round"
      />
      <defs>
        <clipPath id="clip0_281_31">
          <rect
            width="40.2682"
            height="40.2682"
            fill="white"
            transform="translate(86.248 41.6602)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
