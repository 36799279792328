import React, { useState, useEffect } from 'react';

import api from 'services/api';
import 'react-responsive-modal/styles.css';
import { useApp } from 'context/App';
import Resumo from './Resumo';

import {
  formatarMoeda,
  formatarPercentual,
  formatarCNPJ,
  formatarCPF,
  formatarCep,
  formatarIniciarMaiusculo,
  formatarStringData,
} from 'helpers/Formatters';

import TabelaRetratil from 'components/Retratil';
import { telaVisualizada } from 'helpers/telaVisualizada';
import './styles.css';

export default function TermoAdesao(props) {
  const [dadosCCB, setDadosTermoAdesao] = useState(null);
  const {
    tokenSignatario,
    autorizoDebitoValorParcelasTotalOuParcialmente,
    autorizoDebitosLimiteCreditoConta,
    autorizoDebitosObrigacaoVencidaInclusive,
    setAutorizoDebitoValorParcelasTotalOuParcialmente,
    setAutorizoDebitosLimiteCreditoConta,
    setAutorizoDebitosObrigacaoVencidaInclusive,
    configuracaoAssinatura,
  } = useApp();

  function onChangeCheckbox(e) {
    const target = e.target;
    const value = target.checked;
    const id = target.id;

    if (id === 'checkbox1') {
      setAutorizoDebitoValorParcelasTotalOuParcialmente(value);
    } else if (id === 'checkbox2') {
      setAutorizoDebitosLimiteCreditoConta(value);
    } else if (id === 'checkbox3') {
      setAutorizoDebitosObrigacaoVencidaInclusive(value);
    }
  }

  useEffect(() => {
    const fecthData = async () => {
      try {
        props.setIsLoading(true);
        const urlIdExterno = props.idExterno ? `/${props.idExterno}` : '';

        const response = await api.get(
          `Template/ObterDadosTermoAdesao/${tokenSignatario}${urlIdExterno}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                'autorizacao',
              )}`,
            },
          },
        );
        props.setIsLoading(false);
        props.setDadosValidos(true);
        setDadosTermoAdesao(response.data.retorno);

        if (configuracaoAssinatura.validaVisualizacaoDocumento) {
          props.setBloqueioScroll(!telaVisualizada());
        }
      } catch (err) {
        props.setDadosValidos(false);
        props.setIsLoading(false);
      }
    };
    fecthData();
  }, [props.idDocumento, props.idExterno]);

  const renderContratos = (contratosLiquidados) => {
    return !contratosLiquidados.contratos.length ? (
      <tbody>
        <tr className="titulo">
          <td colSpan="3" className="center">
            Nenhum contrato encontrado.
          </td>
        </tr>
      </tbody>
    ) : (
      <tbody>
        <tr className="titulo">
          <td>Instituição Financeira</td>
          <td>Contrato nº</td>
          <td>Firmado em</td>
        </tr>
        {contratosLiquidados.contratos.map((itemContrato, index) => {
          return (
            <tr key={index}>
              <td>{itemContrato.insituicaoFinanceira}</td>
              <td>{itemContrato.contrato}</td>
              <td>{formatarStringData(itemContrato.firmadoEm)}</td>
            </tr>
          );
        })}
        <tr>
          <td>P. Valor total liquidado</td>
          <td colSpan="2">
            {formatarMoeda(contratosLiquidados.valorTotalLiquidado)}
          </td>
        </tr>
      </tbody>
    );
  };

  const renderResumo = dadosCCB && <Resumo dadosCCB={dadosCCB} />;

  return (
    dadosCCB && (
      <div className="container-termo-adesao">
        <h4 className="titulo">Contrato de empréstimo consignado</h4>
        {renderResumo}
        <p>
          Pela presente Proposta/Contrato e Adesão às Condições Gerais do
          Contrato de Concessão de Empréstimo Mediante Consignação em Folha de
          Pagamento (“Proposta”), o cliente qualificado no Quadro I (“Cliente”)
          adere às Condições Gerais do Contrato de Concessão de Empréstimo
          Mediante Consignação em Folha de Pagamento (“Condições Gerais”)
          estabelecidas pelo Banco do Estado do Rio Grande do Sul S.A.,
          instituição financeira, autorizada pelo Banco Central do Brasil para
          operar como Banco Múltiplo, constituído na forma de Sociedade de
          Economia Mista, de Capital Aberto, dotado de personalidade jurídica de
          direito privado, regido pelas normas do Conselho Monetário Nacional e
          demais regulamentações, todas de âmbito Federal, inscrita no Cadastro
          Nacional de Pessoa Jurídica (“CNPJ”) sob o n.º 92.702.067/0001-96, com
          sede na Rua Capitão Montanha, nº 177, em Porto Alegre/RS (“Banrisul”),
          nos termos e cláusulas abaixo estabelecidas: n.º 92.702.067/0001-96,
          com sede na Rua Capitão Montanha, nº 177, em Porto Alegre/RS
          (“Banrisul”), nos termos e cláusulas abaixo estabelecidas:
        </p>
        <TabelaRetratil thTitulo="I - Qualificação do cliente emitente">
          <tbody>
            <tr>
              <td>Nome do cliente</td>
              <td>{formatarIniciarMaiusculo(dadosCCB.cliente.nome)}</td>
            </tr>
            <tr>
              <td>Sexo</td>
              <td>{dadosCCB.cliente.sexo}</td>
            </tr>
            <tr>
              <td>Data de nascimento</td>
              <td>{formatarStringData(dadosCCB.cliente.nascimento)}</td>
            </tr>
            <tr>
              <td>Nacionalidade</td>
              <td>{dadosCCB.cliente.nacionalidade}</td>
            </tr>
            <tr>
              <td>Cidade onde nasceu</td>
              <td>
                {formatarIniciarMaiusculo(dadosCCB.cliente.cidadeNascimento)}
              </td>
            </tr>
            <tr>
              <td>Matrícula Funcional ou Benefício</td>
              <td>{dadosCCB.cliente.matricula}</td>
            </tr>
            <tr>
              <td>Documento de identificação</td>
              <td>{dadosCCB.cliente.documentoIdentificacao}</td>
            </tr>
            <tr>
              <td>Órgão emissor</td>
              <td>{dadosCCB.cliente.orgaoEmissor}</td>
            </tr>
            <tr>
              <td>Data de emissão</td>
              <td>{formatarStringData(dadosCCB.cliente.documentoEmitidoEm)}</td>
            </tr>
            <tr>
              <td>CPF</td>
              <td>{formatarCPF(dadosCCB.cliente.cpf)}</td>
            </tr>
            <tr>
              <td>Estado civil</td>
              <td>{formatarIniciarMaiusculo(dadosCCB.cliente.estadoCivil)}</td>
            </tr>
            <tr>
              <td>Endereço Residencial</td>
              <td>
                {formatarIniciarMaiusculo(
                  `${dadosCCB.cliente.endereco.tipoLogradouro} ${
                    dadosCCB.cliente.endereco.logradouro
                  } ${dadosCCB.cliente.endereco.numero} ${
                    dadosCCB.cliente.endereco.complemento
                      ? dadosCCB.cliente.endereco.complemento
                      : ''
                  }`,
                )}
              </td>
            </tr>
            <tr>
              <td>Bairro</td>
              <td>
                {formatarIniciarMaiusculo(dadosCCB.cliente.endereco.bairro)}
              </td>
            </tr>
            <tr>
              <td>Cidade</td>
              <td>
                {formatarIniciarMaiusculo(dadosCCB.cliente.endereco.cidade)}
              </td>
            </tr>
            <tr>
              <td>UF</td>
              <td>{dadosCCB.cliente.endereco.uf}</td>
            </tr>
            <tr>
              <td>CEP</td>
              <td>{formatarCep(dadosCCB.cliente.endereco.cep)}</td>
            </tr>
            <tr>
              <td>Telefone</td>
              <td>{dadosCCB.cliente.telefone}</td>
            </tr>
            <tr>
              <td>E-mail</td>
              <td>
                {dadosCCB.cliente.email && dadosCCB.cliente.email.toLowerCase()}
              </td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="II - Empregador/ Entidade pública pagadora">
          <tbody>
            <tr>
              <td>Nome</td>
              <td>
                {formatarIniciarMaiusculo(
                  dadosCCB.nomeEmpregadorEntidadePagadora,
                )}
              </td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="III - Tipo de empréstimo">
          <tbody>
            <tr>
              <td>Nº do contrato</td>
              <td>{dadosCCB.numeroContrato}</td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="IV - Forma de liberação do crédito">
          <tbody>
            <tr>
              <td>Banco</td>
              <td>{formatarIniciarMaiusculo(dadosCCB.banco.nome)}</td>
            </tr>
            <tr>
              <td>Agência</td>
              <td>{dadosCCB.banco.agencia}</td>
            </tr>
            <tr>
              <td>Conta Nº</td>
              <td>{dadosCCB.banco.conta}</td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="V - Dados e características do crédito e CET">
          <tbody>
            <tr>
              <td>A. Valor Financiado</td>
              <td>
                {formatarMoeda(
                  dadosCCB.caracteristicaCreditoCet.valorFinanciado,
                )}
              </td>
            </tr>
            <tr>
              <td>B. Quantidade de Prestações</td>
              <td>{dadosCCB.caracteristicaCreditoCet.quantidadePrestacao}</td>
            </tr>
            <tr>
              <td>C. Valor das Prestações</td>
              <td>
                {formatarMoeda(
                  dadosCCB.caracteristicaCreditoCet.valorPrestacao,
                )}
              </td>
            </tr>
            <tr>
              <td>D. Taxa de Juros ao Mês (%)</td>
              <td>
                {formatarPercentual(
                  dadosCCB.caracteristicaCreditoCet.taxaJurosMes,
                )}
              </td>
            </tr>
            <tr>
              <td>E. CET ao Mês (%)</td>
              <td>
                {formatarPercentual(dadosCCB.caracteristicaCreditoCet.cetMes)}
              </td>
            </tr>
            <tr>
              <td>F. Primeiro Vencimento</td>
              <td>
                {formatarStringData(
                  dadosCCB.caracteristicaCreditoCet.primeiroVencimento,
                )}
              </td>
            </tr>
            <tr>
              <td>G. Taxa de Juros ao ano (%)</td>
              <td>
                {formatarPercentual(
                  dadosCCB.caracteristicaCreditoCet.taxaJurosAno,
                )}
              </td>
            </tr>
            <tr>
              <td>H. CET ao ano (%)</td>
              <td>
                {formatarPercentual(dadosCCB.caracteristicaCreditoCet.cetAno)}
              </td>
            </tr>
            <tr>
              <td>I. Último Vencimento</td>
              <td>
                {formatarStringData(
                  dadosCCB.caracteristicaCreditoCet.ultimoVencimento,
                )}
              </td>
            </tr>
            <tr>
              <td>
                J. Seguros
                <br />% (J/A)
              </td>
              <td>
                {formatarMoeda(dadosCCB.caracteristicaCreditoCet.valorSeguros)}
                <br />
                {formatarPercentual(
                  dadosCCB.caracteristicaCreditoCet
                    .percentualEquivalenteSeguros,
                )}
              </td>
            </tr>
            <tr>
              <td>
                K. Tributos
                <br />% (K/A)
              </td>
              <td>
                {formatarMoeda(dadosCCB.caracteristicaCreditoCet.tributos)}
                <br />
                {formatarPercentual(
                  dadosCCB.caracteristicaCreditoCet
                    .percentualEquivalenteTributos,
                )}
              </td>
            </tr>
            <tr>
              <td>
                L. IOF
                <br />% (L/A)
              </td>
              <td>
                {formatarMoeda(dadosCCB.caracteristicaCreditoCet.iof)}
                <br />
                {formatarPercentual(
                  dadosCCB.caracteristicaCreditoCet.percentualEquivalenteIof,
                )}
              </td>
            </tr>
            <tr>
              <td>M. Valor total a pagar</td>
              <td>
                {formatarMoeda(
                  dadosCCB.caracteristicaCreditoCet.valorTotalPagar,
                )}
              </td>
            </tr>
            <tr>
              <td>
                N. Tarifas
                <br />% (N/A)
              </td>
              <td>
                {formatarMoeda(dadosCCB.caracteristicaCreditoCet.tarifas)}
                <br />
                {formatarPercentual(
                  dadosCCB.caracteristicaCreditoCet
                    .percentualEquivalenteTarifas,
                )}
              </td>
            </tr>
            <tr>
              <td>
                O. Outras despesas
                <br />% (O/A)
              </td>
              <td>
                {formatarMoeda(
                  dadosCCB.caracteristicaCreditoCet.outrasDespesas,
                )}
                <br />
                {formatarPercentual(
                  dadosCCB.caracteristicaCreditoCet
                    .percentualEquivalenteOutrasDespesas,
                )}
              </td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="VI - Contratos a serem liquidados">
          {renderContratos(dadosCCB.contratosASeremLiquidados)}
        </TabelaRetratil>

        <TabelaRetratil thTitulo="VII - Valor líquido a ser creditado">
          <tbody>
            <tr>
              <td>Q. Valor líquido a ser creditado[A-(J+K+P+N+O)-L]</td>
              <td>{formatarMoeda(dadosCCB.valorLiquidoASerCreditado)}</td>
            </tr>
            <tr>
              <td>% (Q/A)</td>
              <td>
                {formatarPercentual(
                  dadosCCB.percentualEquivalenteValorLiquidoASerCreditado,
                )}
              </td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="VIII - Dados do correspondente no país/substabelecido">
          <tbody>
            <tr>
              <td>Correspondente</td>
              <td>{`${
                dadosCCB.correspondente.codigo
              } - ${formatarIniciarMaiusculo(
                dadosCCB.correspondente.nome,
              )}`}</td>
            </tr>
            <tr>
              <td>Cnpj</td>
              <td>{formatarCNPJ(dadosCCB.correspondente.cnpj)}</td>
            </tr>
            <tr>
              <td>Endereço</td>
              <td>
                {formatarIniciarMaiusculo(
                  `${dadosCCB.correspondente.endereco.tipoLogradouro || ''} ${
                    dadosCCB.correspondente.endereco.logradouro
                  } ${dadosCCB.correspondente.endereco.numero || ''}`,
                )}
              </td>
            </tr>
            <tr>
              <td>Bairro</td>
              <td>
                {formatarIniciarMaiusculo(
                  dadosCCB.correspondente.endereco.bairro,
                )}
              </td>
            </tr>
            <tr>
              <td>Cidade</td>
              <td>
                {formatarIniciarMaiusculo(
                  dadosCCB.correspondente.endereco.cidade,
                )}
              </td>
            </tr>
            <tr>
              <td>Cep</td>
              <td>{formatarCep(dadosCCB.correspondente.endereco.cep)}</td>
            </tr>
            <tr>
              <td>Telefone</td>
              <td>{`${dadosCCB.correspondente.ddd} ${dadosCCB.correspondente.telefone}`}</td>
            </tr>
            <tr>
              <td>Agente de venda</td>
              <td>
                {formatarIniciarMaiusculo(dadosCCB.correspondente.agenteVenda)}
              </td>
            </tr>
            <tr>
              <td>Cpf/mf</td>
              <td>{formatarCPF(dadosCCB.correspondente.agenteCPF)}</td>
            </tr>
            <tr>
              <td>Comissão</td>
              <td>{dadosCCB.correspondente.comissao || ''}</td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="IX - Autorizações">
          <tbody>
            <tr>
              <td colSpan="2">
                <input
                  type="checkbox"
                  checked={autorizoDebitoValorParcelasTotalOuParcialmente}
                  onChange={onChangeCheckbox}
                  id="checkbox1"
                />
                Autorizo o débito do valor das parcelas, total ou parcialmente,
                na conta em que será creditada esta operação ou nas contas e na
                ordem abaixo especificada:
                <div>
                  <table className="info-conta">
                    <tbody>
                      <tr className="titulo">
                        <td>Banco</td>
                        <td>Agência</td>
                        <td>Conta</td>
                      </tr>
                      <tr>
                        <td>{formatarIniciarMaiusculo(dadosCCB.banco.nome)}</td>
                        <td>{dadosCCB.banco.agencia}</td>
                        <td>{dadosCCB.banco.conta}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <input
                  type="checkbox"
                  checked={autorizoDebitosLimiteCreditoConta}
                  onChange={onChangeCheckbox}
                  id="checkbox2"
                />
                Autorizo que os valores sejam debitados do limite de crédito em
                conta, nos casos aplicáveis
              </td>
            </tr>
            <tr>
              <td colSpan="2">
                <input
                  type="checkbox"
                  checked={autorizoDebitosObrigacaoVencidaInclusive}
                  onChange={onChangeCheckbox}
                  id="checkbox3"
                />
                Autorizo débitos decorrentes de obrigação vencida, inclusive por
                meio de lançamentos parciais
              </td>
            </tr>
          </tbody>
        </TabelaRetratil>

        <TabelaRetratil thTitulo="Condições gerais">
          <div className="lista-condicoes">
            <ol>
              <li>
                <span>PROPOSTA/CONTRATO E CONDIÇÕES GERAIS</span>
                <ol>
                  <li>
                    Pela presente Proposta/Contrato (“Proposta”), o Cliente
                    qualificado no Quadro I, declara expressamente:
                    <ol>
                      <li>
                        Aderir às Condições Gerais, registradas sob o número
                        1793582, em 11 de abril de 2024, no Cartório de
                        Registro de Títulos e Documentos das Pessoas Jurídicas
                        de Porto Alegre/RS, estabelecidas pelo Banco do Estado
                        do Rio Grande do Sul S.A., estando ciente e de pleno
                        acordo com as disposições contidas nas Condições Gerais.
                      </li>
                      <li>
                        Que as informações prestadas nesta Proposta são a
                        verdade, à vista dos originais dos documentos de
                        identidade, Cadastro de Pessoa Física (“CPF”),
                        comprovante de endereço e rendimento, comprometendo-se a
                        manter atualizados seus dados cadastrais junto ao
                        Banrisul.
                      </li>
                      <li>
                        Que recebeu, previamente à solicitação desta Proposta,
                        todas as informações detalhadas acerca dos valores que
                        compõem o custo efetivo total (“CET”) desta Proposta.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                <span>MÚTUO, LIBERAÇÃO DO CRÉDITO E JUROS</span>
                <ol>
                  <li>
                    Ao assinar de forma digital esta Proposta, o Cliente
                    solicita ao Banrisul empréstimo no valor, prazo e condições
                    discriminadas no Quadro V, desta Proposta, o qual
                    representará as características deste empréstimo, caso
                    aprovado pelo Banrisul e mediante aceitação do INSS, da
                    UNIÃO ou do Ente Consignante.
                  </li>
                  <li>
                    A liberação do valor do empréstimo, conforme solicitado
                    pelo Cliente no Quadro IV, dar-se-á a partir da autorização
                    da averbação da margem no INSS, pela UNIÃO ou pelo Ente
                    Consignante, condicionado tal ato, a confirmação oficial e
                    definitiva da averbação dos descontos referentes ao
                    pagamento das parcelas deste empréstimo em folha de
                    pagamento de benefício ou de remuneração do Cliente.
                  </li>
                  <li>
                    Com a aceitação desta Proposta pelo Banrisul, o Cliente
                    autoriza expressamente a liquidação do(s) contrato(s)
                    elencados no Quadro VI, bem como eventual operação de
                    crédito inadimplida junto ao Banrisul, reconhecendo desde
                    já, o(s) saldo(s) devedor(es) do(s) mesmo(s), para todos os
                    fins de direito.
                  </li>
                  <li>
                    Após a(s) liquidação(ões) acima previstas, eventual saldo
                    remanescente, será colocado à disposição do(a) EMITENTE,
                    nos termos e condições aprovados nesta proposta.
                  </li>
                  <li>
                    O presente empréstimo estará formalizado e aperfeiçoado, na
                    ocorrência das situações previstas na cláusula 2.2, e/ou,
                    na ocorrência da situação constante na cláusula 2.4.
                  </li>
                  <li>
                    Se, em 5 dias, após a data da emissão da presente proposta,
                    não ocorrer a averbação ou crédito conforme definidos nas
                    cláusulas 2.2 e 2.4, esta proposta será aditada, pelo valor
                    realmente dispendido pelo BANRISUL, consolidando-se a dívida
                    neste valor, com o expresso reconhecimento pelo(a) EMITENTE,
                    de sua certeza, liquidez e exigibilidade.
                  </li>
                  <li>
                    Em sendo aprovada a Proposta, o Cliente pagará ao Banrisul,
                    sobre o valor do empréstimo concedido, desde a data do
                    desembolso até a data da exigibilidade, a taxa de juros
                    definida nos itens D e G do Quadro V, capitalizada
                    mensalmente, sem prejuízo dos encargos decorrentes da mora e
                    inadimplemento.
                  </li>
                  <li>
                    A liberação do empréstimo dar-se-á após a aplicação da
                    fórmula constante no Quadro VII, a saber: [“A” - (“J” + “K”
                    + “M”) – “L”], onde (A) é o Valor do Empréstimo; menos o
                    somatório de (J) Seguros; (K) Tributos; e, (M) Valor Total
                    Liquidado; e, após o resultado, deduz-se o (L) IOF, que
                    incidirá nos termos previstos na legislação vigente.
                  </li>
                  {dadosCCB.numeroMesesCarencia != 0 ? 
                    <li>
                      O(A) EMITENTE manifesta, expressamente, que (i) optou pelo
                      benefício da carência para pagamento da primeira parcela, e 
                      que (ii) concorda com a concessão de ({dadosCCB.numeroMesesCarencia}) meses de carência, 
                      com início do pagamento deste empréstimo na data estabelecida 
                      no QUADRO V item F.
                    </li> : null
                  }
                </ol>
              </li>
              <li>
                <span>
                  AUTORIZAÇÃO DE DESCONTO EM FOLHA E PRESTAÇÕES MENSAIS
                </span>
                <ol>
                  <li>
                    O Cliente autoriza nesta Proposta e na forma da legislação
                    em vigor, que o INSS, a UNIÃO ou o Ente Consignante promova
                    no benefício previdenciário, pensão ou folha de pagamento
                    identificada no Quadro I desta Proposta, em caráter
                    irrevogável, irretratável e irrenunciável, os descontos das
                    prestações, de forma mensal e sucessiva, em quantidade e
                    valor definidos nos itens “B” e “C”, do Quadro V, desta
                    Proposta, bem como dos tributos e tarifas incidentes sobre o
                    presente empréstimo, que se encontrarem incorporados às
                    prestações ajustadas.
                    <ol>
                      <li>
                        Referidos descontos deverão perdurar até a integral
                        liquidação do saldo devedor deste empréstimo, devendo
                        ser repassados imediatamente ao Banrisul nas datas
                        aprazadas de vencimento.
                      </li>
                    </ol>
                  </li>
                  <li>
                    Se, durante a vigência deste empréstimo, ocorrer redução na
                    margem consignável do Cliente, que impossibilite o desconto
                    integral das prestações, de forma mensal e sucessiva, a
                    autorização prevista nesta Proposta compreende o desconto
                    parcial das prestações, no montante máximo da margem mensal
                    disponível, situação na qual a quantidade das prestações e o
                    vencimento do empréstimo postergar-se-ão até que ocorra o
                    pagamento integral deste empréstimo.
                  </li>
                  <li>
                    Não ocorrendo a consignação da prestação devida pelo Cliente
                    no benefício, pensão ou folha de pagamento, este se obriga a
                    pagar ao Banrisul, os valores correspondentes da prestação
                    não consignada.
                  </li>
                  <li>
                    O Cliente autoriza o Banrisul ou empresa por ele contratada a
                    proceder, na impossibilidade de consignação total ou parcial
                    das prestações em seu benefício, pensão ou folha de pagamento,
                    a cobrança da(s) prestação(ões) impagas, através de débito em
                    conta-corrente de sua titularidade, na instituição financeira
                    indicada no Quadro IV, desta Proposta, para a liberação do
                    empréstimo ou nas contas e nos termos dispostos Quadro IX,
                    conforme legislação em vigor, obrigando-se a prover a conta com
                    recursos suficientes e disponíveis para o débito do valor
                    devido, sem prejuízo da incidência de encargos por inadimplemento.
                    Também autoriza, caso o EMITENTE/Cliente possua ou venha a possuir
                    conta de depósitos ou salário no BANRISUL, o débito dos valores
                    devidos ao BANRISUL, nestas contas.
                    <ol>
                      <li>
                        Não havendo saldo na conta(s) autorizada(s) para débito, o
                        Cliente autoriza o Banrisul a resgatar, se necessário, aplicação
                        financeira ou qualquer outra disponibilidade financeira em nome
                        do Cliente existente na(s) conta(s) autorizada(s) para débito.
                      </li>
                      <li>
                        Em caso de encerramento da(s) conta(s) autorizada(s) para débito,
                        por qualquer motivo, o débito ocorrerá na próxima conta autorizada,
                        conforme previsto na autorização constante desde instrumento
                        contratual.
                        <ol>
                          <li>
                            O Cliente declara-se ciente de que, caso houver valores em 
                            aberto, o Banrisul efetuará a liquidação dos débitos mais antigos,
                            conforme ocorrerem os pagamentos por parte do Cliente.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li>
                    O Cliente também autoriza o Banrisul ou empresa por ele
                    contratada, a proceder, de forma irrevogável e irretratável,
                    a cobrança deste empréstimo, a qualquer tempo, através de
                    débito em conta-corrente de sua titularidade e/ou através de
                    boleto de cobrança emitido pelo Banrisul, da(s) prestação(
                    ões ) retida(s) pelo INSS, pela UNIÃO ou pelo Ente
                    Consignante e repassada(s) ao BANRISUL, mas que
                    posteriormente foi(ram) objeto de pedido de devolução
                    (glosa).
                  </li>
                  <li>
                    O Cliente está ciente que, em razão dos prazos necessários para
                    o processamento das informações relativas à consignação, pagamento,
                    amortização ou liquidação antecipada, poderá o desconto da parcela
                    referente ao Empréstimo Consignado ocorrer, mesmo após a comunicação 
                    da amortização ou liquidação antecipada pelo Banrisul. Ocorrendo o 
                    débito da parcela amortizada ou liquidada na folha de pagamento do 
                    Cliente, o Banrisul, ao receber o repasse do valor referente a esta 
                    parcela, procederá ao seu crédito na conta salário, conta registro ou 
                    conta corrente do Cliente ou disponibilizará uma ordem de pagamento do 
                    valor da parcela, caso este não possua conta no Banrisul.
                  </li>
                  <li>
                    O Banrisul isenta-se de qualquer responsabilidade decorrente do fato de
                    seu Empregador, por qualquer motivo, continuar a descontar em folha de
                    pagamento, a parcela do Empréstimo Consignado, mesmo após a comunicação
                    da liquidação pelo Banrisul.
                  </li>
                </ol>
              </li>
              <li>
                <span>INADIMPLEMENTO</span>
                <ol>
                  <li>
                    Em caso de inadimplemento de quaisquer das obrigações
                    estipuladas nesta Proposta e/ou nas Condições Gerais ou em
                    eventual vencimento antecipado, o Cliente incorrerá em mora,
                    independentemente de aviso ou notificação de qualquer
                    espécie, passando a incidir, sobre o débito, além dos juros
                    remuneratórios pactuados, juros de mora de 1,00% (um por
                    cento) ao mês e multa de 2,00% ( esta sobre o principal e
                    acessórios do débito), até a sua definitiva liquidação, sem
                    prejuízo das demais sanções contratuais e legais cabíveis.
                  </li>
                </ol>
              </li>
              <li>
                <span>TRATAMENTO DE DADOS PESSOAIS</span>
                <ol>
                  <li>
                    Pela presente Proposta/Contrato, o Banrisul, como
                    Controlador de Dados, declara que tratará os dados pessoais
                    fornecidos para a execução do Contrato de acordo com a Lei
                    Geral de Proteção de Dados Pessoais (Lei nº 13.709/18) e
                    legislação correlata aplicável, assegurando o devido sigilo,
                    segurança, transparência e respeito à sua privacidade.
                    Informações detalhadas sobre o tema poderão ser encontradas
                    nas Condições Gerais e na “Política Geral de Privacidade e
                    às Diretrizes para Proteção de Dados Pessoais”, ambos
                    disponíveis no site do Banrisul
                    (http://www.banrisul.com.br).
                  </li>
                </ol>
              </li>
              <li>
                <span>DISPOSIÇÕES GERAIS</span>
                <ol>
                  <li>
                    O Cliente satisfará, nos termos constantes nas Condições
                    Gerais, todas as despesas que o Banrisul fizer para a
                    segurança e regularidade de seus direitos.
                  </li>
                  <li>
                    Na hipótese de liquidação antecipada desta operação, parcial
                    ou total, em conformidade com a legislação em vigor, o valor
                    presente do débito será calculado, utilizando a taxa de
                    juros pactuada nesta Proposta.
                  </li>
                  <li>
                    Sobre esta operação incide o IOF, conforme legislação em
                    vigor, sendo que tal valor se encontra a disposição do
                    Cliente, através do extrato do empréstimo ou aviso de
                    movimentação de cobrança.
                  </li>
                  <li>
                    O Cliente (e os demais terceiros presentes nesta relação
                    contratual) autoriza o Banrisul a informar ao Banco Central
                    do Brasil (“BCB”) sobre a presente operação de crédito
                    contratada nos termos deste instrumento contratual, conforme
                    determina a legislação vigente. Ademais, o Cliente (e os
                    demais terceiros presentes nesta relação contratual) autoriza
                    o Banrisul a consultar o Sistema de Informações de Crédito
                    (“SCR”), organizado pelo Banco Central do Brasil (“BCB”), com
                    vistas a verificar a situação cadastral.
                    <ol>
                      <li>
                        O Cliente declara estar ciente de que as finalidades
                        (prover informações ao Banco Central do Brasil, para
                        fins de monitoramento do crédito no sistema financeiro e
                        propiciar o intercâmbio de informações entre
                        instituições financeiras) e demais informações sobre o
                        SCR podem ser obtidas junto ao Banco Central do Brasil,
                        em www.bcb.gov.br/scr, ou pelo telefone 145, nos dias
                        úteis, das 8h às 20h.
                      </li>
                    </ol>
                  </li>
                  <li>
                    O Cliente desde já autoriza o Banrisul a consultar o Serviço
                    de Proteção ao Crédito (SPC) e o Serasa, com vistas a
                    verificar a sua situação cadastral.
                  </li>
                  <li>
                    O Cliente autoriza, caso aplicável, a cobrança de tarifas
                    conforme previsto na Tabela de Serviços Bancários, exposta
                    nas LOJAS BEM PRODUTOS E SERVIÇOS, Agências do BANRISUL e na
                    internet na página do Banco, site: www.banrisul.com.br.
                  </li>
                  <li>
                    O Cliente autoriza expressamente o Banrisul a compartilhar com 
                    outras instituições financeiras, instituições de pagamento e 
                    demais instituições autorizadas a funcionar pelo Banco Central 
                    do Brasil, dados e informações sobre indícios de ocorrências ou 
                    de tentativas de fraudes. A finalidade de tal compartilhamento 
                    é subsidiar os procedimentos e controles das referidas 
                    instituições para a prevenção de fraudes no âmbito do Sistema 
                    Financeiro Nacional, conforme exigência da Resolução Conjunta 
                    nº 06/2023 do BACEN/CMN.
                  </li>
                  <li>
                    É direito do Cliente, nas operações de crédito consignado
                    realizadas através de correspondente bancário, desistir do
                    contrato no prazo de até 7 (sete) dias úteis, a contar do
                    recebimento do crédito, devendo restituir o valor total
                    concedido e que lhe foi entregue pelo Banrisul, acrescido de
                    eventuais tributos incidentes sobre a operação.
                  </li>
                  <li>
                    É direito do Cliente, em caso de portabilidade, a liquidação
                    antecipada, se houver solicitação formal ao Banrisul.
                  </li>
                  <li>
                    O Cliente declara que não está em situação de superendividamento
                    e que o pagamento dos valores devidos por este instrumento não
                    comprometerá a renda mínima necessária para o seu sustento.
                  </li>
                  <li>
                    O Cliente declara que este Proposta foi celebrada
                    respeitando princípios de probidade e boa-fé, sendo firmado
                    por sua livre e espontânea vontade, obrigando-se assim a
                    todos seus exatos termos e condições. O não exercício, ou
                    tolerância do Banrisul, de qualquer direito ou faculdade
                    aqui previsto, não implicará qualquer novação ou renúncia.
                  </li>
                  <li>
                    O Cliente está ciente que, havendo divergência entre esta
                    Proposta e as Condições Gerais, valerá o entabulado nessa
                    Proposta.
                  </li>
                  <li>
                    Este Contrato será assinado eletronicamente por meio da
                    plataforma BemSign, pelo que o Cliente expressamente
                    declara, de maneira inequívoca, que tal modalidade de
                    assinatura é juridicamente válida, exequível e suficiente
                    para vincular o Cliente a todos os termos e condições deste
                    Contrato, desde que firmada pelo representante legal do
                    Cliente e/ou Cliente, nos termos do artigo 10, §1º e §2º, da
                    Medida Provisória 2.200-2, de 24 de agosto de 2001.
                  </li>
                  <li>
                    Para sugestões, reclamações e solução de conflitos, o
                    CLIENTE deverá entrar em contato pelos seguintes canais do
                    Banrisul: Central de Relacionamento ao Cliente: Capitais e
                    regiões metropolitanas - 3003.0511 e demais localidades -
                    0800.7200011; Serviço de Atendimento ao Cliente:
                    0800.6461515; Ouvidoria: 0800.6442200, em dias úteis, das
                    09h às 17h.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </TabelaRetratil>
      </div>
    )
  );
}
