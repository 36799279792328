import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PageLoader from 'components/PageLoader';

import { isBrowserValid } from 'helpers/isBrowserValid';

import isMobileDevice from 'helpers/isMobile';

import listaCards from 'helpers/cardList';
import listaPaginas from 'helpers/pageList';
import inicializaFacetec from 'helpers/initializeFacetec';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { getAutenticacaoIdBase64 } from '../../auth';
import { AiFillSound } from 'react-icons/ai';

import { useApp } from 'context/App';

import api from 'services/api';

import { responseCompleto, responseNulo } from 'services/mockapi';

import validaAmbiente from 'helpers/validaAmbiente';
import geraConfigGlobal from 'helpers/geraConfigGlobal';

import {
   ERRO_NEGOCIO,
   ERRO_FORMULARIO,
   CODIGO_ERRO_FACETEC,
   CODIGO_SUCESSO_FACETEC,
   PATH_URL,
} from './consts';
import { CONSIGNADO, FGTS } from 'helpers/consts';

import { cancelarAudio } from 'components/AudioAcessibilidade/utils';

import './styles.css';
import { ContainerApp } from 'components/ContainerApp';
import { ContentMain } from 'components/ContainerApp/styles';
import AudioIcon from 'assets/v2/audio-info.svg';
import { useMedia } from 'react-use';
import { SMALL } from 'helpers/breakpoints';
import BotaoFixado from 'components/BotaoFixado';

export default function Autenticacao(props) {
   const [isLoading, setIsLoading] = useState(false);
   const {
      setCpf,
      setCliente,
      setEmail,
      setCelular,
      setIp,
      setToken,
      setTokenSignatario,
      setPathToken,
      setLivenessConfig,
      setFacematchConfig,
      setQtdTentativasFacematchConfig,
      setTokenSMSConfig,
      setValidarNascimentoConfig,
      setConfirmacaoDadosConfig,
      setDadosInvalidosConfig,
      setFacetecIndisponivel,
      setListaPassos,
      setExibirPassosConfig,
      setExibirDocumentosConfig,
      setListaPages,
      setStatusFacetec,
      orientacaoVerificada,
      setOrientacaoVerificada,
      setIdUsuario,
      setInformacoes,
      setQtdTentativasLivenessConfig,
      setConfiguracaoAssinatura,
      setIdEmpresa,
      setHabilitaAudio,
      setHabilitaLibras,
      setColetaDocumentoConfig,
      setComponenteDocumentoConfig,
      setQuantidadeProgresso,
      setTema,
      setConfigGlobal,
      setAjudaLeitura,
      proximaPagina
   } = useApp();
   const [abreModal, setAbreModal] = useState(false);
   const [configAtual, setConfigAtual] = useState(null);
   const isSmall = useMedia(SMALL);

   const history = useHistory();

   function setValues(dados) {
      setCliente(dados.nome);
      setEmail(dados.email);
      setCelular(dados.celular);
      setCpf(dados.cpf);
      setToken(dados.tokenAutenticacao);
      setTokenSignatario(dados.token);
      setIdUsuario(dados.idUsuario);
      setIp(dados.enderecoIp);
      setInformacoes(dados.avisos);
      setIdEmpresa(dados.idEmpresa);
   }

   function verificaAcaoPagina(config) {
      if (window.orientation !== 0 && !orientacaoVerificada && isMobileDevice()) {
         setOrientacaoVerificada(true);
         return history.push('/orientacao');
      }

      if (config.habilitaAudio) {
         setAbreModal(true);
      } else {
         navegaProximaPagina(config);
      }
   }

   function navegaProximaPagina(config) {
      if (config.exibirPassos) {
         history.push('/passos');
      } else {
         history.push('/solicitacao-acesso');
      }
   }

   async function setVisibleCards(config, idEmpresa) {
      window.localStorage.setItem(
         'tentativasFacematch',
         config.quantidadeTentativasFacematch
      );

      window.localStorage.setItem(
         'tentativasLiveness',
         config.quantidadeTentativasLiveness
      );

      if (config.realizarLiveness || config.realizarFacematch) {
         setIsLoading(true);

         let facetecRetorno = await inicializaFacetec(idEmpresa);

         console.log(facetecRetorno);

         setStatusFacetec(facetecRetorno.status);

         setFacetecIndisponivel(!facetecRetorno.inicializado);

         setIsLoading(false);
      }

      setLivenessConfig(config.realizarLiveness);
      setFacematchConfig(config.realizarFacematch);
      setQtdTentativasFacematchConfig(config.quantidadeTentativasFacematch);
      setTokenSMSConfig(config.validarTokenSms);
      setValidarNascimentoConfig(config.validarDataNascimento);
      setConfirmacaoDadosConfig(config.realizarConfirmacaoDados);
      setDadosInvalidosConfig(config.informarDadosInvalidos);
      setExibirDocumentosConfig(config.exibirDocumentos);
      setExibirPassosConfig(config.exibirPassos);
      setQtdTentativasLivenessConfig(config.quantidadeTentativasLiveness);
      setHabilitaAudio(config.habilitaAudio);
      setAjudaLeitura(config.habilitaAudio);
      setHabilitaLibras(config.habilitaLibras);
      setColetaDocumentoConfig(config.coletaDocumento);
      setComponenteDocumentoConfig(config.componenteDocumento);
      setQuantidadeProgresso(config.passos);

      setListaPassos(listaCards(config));
   }

   async function obterConfiguracao(token, autenticacaoDados) {
      try {
         const response = await api.get(`BemSign/ObterConfiguracao/${token}`, {
            headers: {
               Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
            },
         });

         const dados = response.data.retorno;

         dados.informacoes =
            autenticacaoDados.avisos && autenticacaoDados.avisos.length;

         dados.autenticacaoIdbase64 = getAutenticacaoIdBase64();

         const lista = listaPaginas(dados);

         setListaPages(lista);

         setIsLoading(false);

         return dados;
      } catch (error) {
         setIsLoading(false);
         toast.error('Erro ao obter configurações.');
      }
   }

   useEffect(() => {
      const fecthData = async () => {
         if (props.match.params.token) {
            cancelarAudio(props.match.params.token, false);

            const pathname = window.location.pathname;
            setPathToken(pathname);

            if (ignoraAmbiente()) {
               const { ambienteValido, mensagemAviso } = await validaAmbiente(
                  pathname,
               );
               if (mensagemAviso) {
                  return history.push('/aviso', {
                     textoH1: mensagemAviso.titulo,
                     textoH2: mensagemAviso.subTitulo,
                     showImage: true,
                  });
               }
               if (!ambienteValido) return;
            }

            autenticar(props.match.params.token);
         }
      };

      fecthData();
   }, []);

   async function autenticar(token) {
      try {
         setIsLoading(true);

         const response = await api.get(`Autenticacao/Autenticar/${token}`);

         const dados = response.data.retorno;

         if (response.data.alertas.length) {
            const alertas = response.data.alertas.filter(
               (alerta) =>
                  alerta.tipo === ERRO_NEGOCIO || alerta.tipo === ERRO_FORMULARIO
            );

            return history.push('/aviso', {
               textoH3: 'Ops',
               showImage: true,
               alertas: alertas,
               qrCodeLink: window.location.href
            });
         }

         if (token) {
            window.localStorage.setItem(
               'url-token',
               `${PATH_URL}${props.match.params.token}`,
            );
         }

         dados.avisos = dados.avisos?.map(aviso => (
            {
               ...aviso,
               avisoValidado: false
            })
         )

         setValues(dados);

         window.localStorage.setItem('autorizacao', dados.tokenAutenticacao);

         if (dados && dados.idEmpresa) {
            await obterConfiguracaoTelas(dados);
         }

         const configuracoes = await obterConfiguracao(token, dados);

         setConfigAtual(configuracoes);

         await obterConfiguracaoAssinatura(token);

         await setVisibleCards(configuracoes, dados.idEmpresa);

         setIsLoading(false);

         if (isBrowserValid()) {
            verificaAcaoPagina(configuracoes);
         } else {
            history.push('/instrucao-navegador');
         }
      } catch (error) {
         return history.push('/aviso', {
            textoH1: 'Ops',
            showImage: true,
            alertas: [
               {
                  mensagem:
                     error.response &&
                        error.response.data &&
                        error.response.data.alertas.length
                        ? error.response.data.alertas[0].mensagem
                        : 'Erro ao autenticar.',
               },
            ],
         });
      }
   }

   async function obterConfiguracaoAssinatura(token) {
      try {
         setIsLoading(true);

         const response = await api.get(
            `BemSign/ObterConfiguracaoEmpresaAssinatura/${token}`,
            {
               headers: {
                  Authorization: `Bearer ${window.localStorage.getItem(
                     'autorizacao'
                  )}`,
               },
            }
         );

         const objRetorno = response.data.retorno;

         window.localStorage.setItem('facematchMinimo', objRetorno.facematchMinimo || 3);

         setIsLoading(false);

         setConfiguracaoAssinatura(objRetorno);
      } catch (error) {
         toast.error('Ocorreu um erro ao obter a configuração de assinatura');
         setIsLoading(false);
      }
   }

   const ignoraAmbiente = () => !history.location.pathname.includes('ignora');

   const onCloseModal = () => {
      setAbreModal(false);
      navegaProximaPagina(configAtual);
   };

   const obterConfiguracaoTelas = async (dados) => {

      try {
         if (dados.idEmpresa === CONSIGNADO) {
            handleConfigGlobal(null, dados.nome);
         } else {
            const response = await api.get(`BemSign/ObterConfiguracaoTelas/${dados.idEmpresa}`, {
               headers: {
                  Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
               },
            });

            handleConfigGlobal(response.data.retorno, dados.nome);
         }

      } catch (error) {
         handleConfigGlobal(null, dados.nome);
      }
   }

   const handleConfigGlobal = (objeto, nome) => {
      const configGlobal = geraConfigGlobal(objeto, nome);

      setConfigGlobal(configGlobal);
   }

   return (
      <div className="autenticacao-container">
         <section
            className={`loader-animation ${isLoading ? 'enabled' : 'hidden'}`}
         >
            <PageLoader isOverlay="true" width={125} height={250} />
         </section>

         {!isLoading && configAtual?.habilitaAudio &&
         <ContainerApp>
            <ContentMain>
               <img src={AudioIcon} alt="Ajuda por áudio" />
               <h4>Aumente o som do seu dispositivo para ouvir as informações.</h4>
               <h5>
                  Ajuste o volume a um nível confortável. Se estiver usando fones de ouvido, verifique a conexão. Em ambientes silenciosos, ajuste o volume para não incomodar. 
               </h5>

               {!isSmall && (
                  <button
                     className="button-bem-v2"
                     onClick={onCloseModal}
                     disabled={isLoading}
                  >
                     Entendi
                  </button>
               )}
            </ContentMain>

            {isSmall && (
            <BotaoFixado
               onClick={onCloseModal}
               borderTop
               cor="transparent"
            >
               Entendi
            </BotaoFixado>
            )}
         </ContainerApp>}
         {/* <Modal
            open={abreModal}
            onClose={onCloseModal}
            center
            styles={{ modal: { borderRadius: '8px' } }}
            showCloseIcon={false}
         >
            <div className="modal-container">
               <h3>Aumente o volume do seu dispositivo para ouvir as informações</h3>

               <AiFillSound size={50} />

               <button className="button-bem" onClick={onCloseModal}>
                  Entendi
               </button>
            </div>
         </Modal> */}
         
         <ToastContainer />
      </div>
   );
}
