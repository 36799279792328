import React from "react";
import {
  Container,
  Content,
  HeaderContent,
  HeaderLogoContent,
  MainContent,
} from "./styles";
import ImagemPadrao from "assets/v2/bside-padrao-2.png";
import ImagemFigital from "assets/v2/bside-figital.png";
import { Logo } from "components/Logo";
import { useMedia } from "react-use";
import { SMALL } from "helpers/breakpoints";
import { useApp } from "context/App";

export const ContainerAppRow = ({ children, headerContent }) => {
  const { ajudaLeitura } = useApp();
  const isSmall = useMedia(SMALL);

  const imagem = ajudaLeitura ? ImagemFigital : ImagemPadrao;

  return (
    <Container>
      <HeaderContent>
        <HeaderLogoContent>
          <Logo mb />
          {headerContent}
        </HeaderLogoContent>
        {!isSmall && (
          <Content className="imagem-container-row">
            <img
              src={imagem}
              className="imagem-header"
              alt="pessoa utilizando celular"
            />
          </Content>
        )}
      </HeaderContent>
      <MainContent>{children}</MainContent>
    </Container>
  );
};
