import React, { useState, useEffect } from 'react';

import api from 'services/api';

import { useApp } from 'context/App';
import PageLoader from 'components/PageLoader';

import datePtBr from 'helpers/datePtBr';

import {
  formatarCPF,
  formatarPercentual,
  formatarMoeda,
  formatarStringData,
  formatarCNPJ,
  formatarTelefone,
} from 'helpers/Formatters';
import { telaVisualizada } from 'helpers/telaVisualizada';
import './styles.css';

export default function AumentoPortabilidade(props) {
  const [dadosPortabilidade, setDadosPortabilidade] = useState(null);
  const dataAtual = datePtBr();
  const { tokenSignatario, configuracaoAssinatura } = useApp();

  useEffect(() => {
    const fecthData = async () => {
      try {
        props.setIsLoading(true);
        const urlIdExterno = props.idExterno ? `/${props.idExterno}` : '';

        const url = `Template/ObterDadosPortabilidadeMajoracao/${tokenSignatario}${urlIdExterno}`;

        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'autorizacao'
            )}`,
          },
        });
        props.setIsLoading(false);
        props.setDadosValidos(true);
        setDadosPortabilidade(response.data.retorno);
        if (configuracaoAssinatura.validaVisualizacaoDocumento) {
          props.setBloqueioScroll(!telaVisualizada());
        }
      } catch (err) {
        props.setDadosValidos(false);
        props.setIsLoading(false);
      }
    };
    fecthData();
  }, [props.idDocumento, props.idExterno]);

  return (
    dadosPortabilidade && (
      <div className="container-aumento-portabilidade">
        <h2>Formulário Portabilidade</h2>
        <h2>Majoração de valor da prestação</h2>
        <h2>BACEN - Resolução N º4.292/2013</h2>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">À instituição proponente</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Razão Social</td>
              <td>
                <b>Banrisul S.A.</b> CNPJ Base: 92702067
              </td>
            </tr>
            <tr>
              <td>Endereço</td>
              <td>Rua Caldas Júnior, 108 – Porto Alegre/RS CEP: 90.018-900</td>
            </tr>
          </tbody>
        </table>
        <p>
          De acordo com as regras do BACEN (parágrafo único do artigo terceiro
          da Resolução nº4.292/2013), venho, por meio desta, comunicar minha
          concordância com o aumento do valor da prestação da operação - objeto
          da Portabilidade - conforme abaixo pontuado.
        </p>
        <table className="table-group-v2" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Identificação do cliente (solicitante)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nome</td>
              <td>{dadosPortabilidade.nome}</td>
            </tr>
            <tr>
              <td>CPF</td>
              <td>{formatarCPF(dadosPortabilidade.cpf)}</td>
            </tr>
            <tr>
              <td>Data de nascimento</td>
              <td>{formatarStringData(dadosPortabilidade.dataNascimento)}</td>
            </tr>
            <tr>
              <td>Telefone</td>
              <td>
                {formatarTelefone(
                  `${dadosPortabilidade.ddd}${dadosPortabilidade.telefone}`
                )}
              </td>
            </tr>
            <tr>
              <td>Nome da mãe</td>
              <td>{dadosPortabilidade.nomeMae}</td>
            </tr>
            <tr>
              <td>Documento de identificação</td>
              <td>{dadosPortabilidade.numeroDocumentoIdentificacao}</td>
            </tr>
            <tr>
              <td>Tipo</td>
              <td>{dadosPortabilidade.tipoDocumentoIdentificacao}</td>
            </tr>
            <tr>
              <td>Data de emissão</td>
              <td>
                {formatarStringData(
                  dadosPortabilidade.dataEmissaoDocumentoIdentificacao
                )}
              </td>
            </tr>
            <tr>
              <td>Órgão emissor</td>
              <td>{dadosPortabilidade.orgaoEmissorDocumentoIdentificacao}</td>
            </tr>
          </tbody>
        </table>
        <table className="table-group-v2" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Dados da operação original</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Número do contrato</td>
              <td>{dadosPortabilidade.numeroContrato}</td>
            </tr>
            <tr>
              <td>CNPJ base IF credora original</td>
              <td>
                {formatarCNPJ(dadosPortabilidade.cnpjBaseIfCredoraOriginal)}
              </td>
            </tr>
            <tr>
              <td>Data de contratação da operação</td>
              <td>{dadosPortabilidade.dataContratacaoOperacao}</td>
            </tr>
            <tr>
              <td>Valor da prestação</td>
              <td>{formatarMoeda(dadosPortabilidade.valorPrestacao)}</td>
            </tr>
          </tbody>
        </table>
        <table className="table-group-v2" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Dados da operação de portabilidade</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nº portabilidade CTC</td>
              <td>{dadosPortabilidade.numeroPortabilidadeCtc}</td>
            </tr>
            <tr>
              <td>Valor do contrato</td>
              <td>{formatarMoeda(dadosPortabilidade.valorContrato)}</td>
            </tr>
            <tr>
              <td>Data de contratação da operação</td>
              <td>
                {formatarStringData(
                  dadosPortabilidade.dataContratacaoOperacaoPortabilidade
                )}
              </td>
            </tr>
            <tr>
              <td>Taxa juros nominal % a.a.</td>
              <td>{formatarPercentual(dadosPortabilidade.taxaJurosNominal)}</td>
            </tr>
            <tr>
              <td>Taxa juros efetiva % a.a.</td>
              <td>
                {dadosPortabilidade.taxaJurosEfetiva
                  ? formatarPercentual(dadosPortabilidade.taxaJurosEfetiva)
                  : ''}
              </td>
            </tr>
            <tr>
              <td>CET % a.a.</td>
              <td>{formatarPercentual(dadosPortabilidade.cet)}</td>
            </tr>
            <tr>
              <td>Prazo</td>
              <td>{dadosPortabilidade.prazo}</td>
            </tr>
            <tr>
              <td>Valor da prestação</td>
              <td>
                {formatarMoeda(dadosPortabilidade.valorPrestacaoPortabilidade)}
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text-align-right">
          <b>{dataAtual}</b>
        </p>
        <div className="div-condicoes">
          <p>
            Para sugestões, reclamações e solução de conflitos, o <b>CLIENTE</b>{' '}
            deve entrar em contato pelos seguintes canais: Central de
            Relacionamento ao Cliente - Capitais e regiões metropolitanas -
            3003.0511 / Demais localidades - 0800.7200011; Serviço de
            Atendimento ao Cliente - 0800.6461515; Ouvidoria - 0800.6442200, em
            dias úteis, das 9h às 17h.
          </p>
        </div>
      </div>
    )
  );
}
