import React, { useEffect } from "react";
import {
  formatarMoeda,
  formatarPercentual,
  formatarIniciarMaiusculo,
  formatarStringData,
} from "helpers/Formatters";
import audioResumoProposta from "assets/audios/resumo-proposta.mp3";
import { useAudio } from "react-use";
import { VOLUME } from "services/consts";
import { useApp } from "context/App";
import { AudioAcessibilidade } from "components/AudioAcessibilidade";
import { cancelarAudio } from "components/AudioAcessibilidade/utils";
import { TIPO_TERMO_FIGITAL } from "components/AudioAcessibilidade/consts";
import "./styles.css";
import { ContainerCard } from "components/BoxMonetario/styles";
import { BoxMonetario } from "components/BoxMonetario";
import { Espaco } from "components/Espaco";
import {
  ContainerInfoBox,
  InfoBlock,
  Text,
  Title,
} from "components/InfoBox/styles";

export default function Resumo(props) {
  const { dadosFigital } = props;
  const { ajudaPorVoz, tokenSignatario, habilitaAudio } = useApp();
  const [audio, state, controls, ref] = useAudio({
    src: audioResumoProposta,
    autoPlay: false,
  });

  useEffect(() => {
    if (ajudaPorVoz && !habilitaAudio) {
      controls.volume(VOLUME);
      controls.play();
    }

    if (habilitaAudio) {
      executaAudioResumo();
    }

    return () => {
      if (habilitaAudio) cancelarAudio(tokenSignatario);
    };
  }, []);

  const executaAudioResumo = () => {
    const dadosAudio = {
      operacao: dadosFigital.operacao,
      valorFinanciado: dadosFigital.valorFinanciado,
      valorLiquido: dadosFigital.valorLiquido,
      parcela: dadosFigital.valorParcela,
      prazo: dadosFigital.prazo,
      cetMes: dadosFigital.cetMes,
      primeiroVencimento: dadosFigital.primeiroVencimento,
    };

    AudioAcessibilidade(TIPO_TERMO_FIGITAL, dadosAudio, tokenSignatario);
  };

  return (
    <div className="resumo-figital-container">
      {audio}
      <h4 className="titulo">Resumo da proposta</h4>

      <ContainerCard>
        <BoxMonetario
          title="Valor Financiado"
          value={formatarMoeda(dadosFigital.valorFinanciado)}
          w={"32px"}
          h={"32px"}
        />

        <BoxMonetario
          title="Valor Líquido"
          value={formatarMoeda(dadosFigital.valorLiquido)}
          img="dinheiro"
          w={"32px"}
          h={"32px"}
        />
      </ContainerCard>

      <Espaco margin={20} />

      <ContainerInfoBox>
        <InfoBlock>
          <Title>Operação</Title>
          <Text>{formatarIniciarMaiusculo(dadosFigital.operacao)}</Text>
        </InfoBlock>
      </ContainerInfoBox>

      <Espaco margin={20} />

      <ContainerInfoBox>
        <InfoBlock>
          <Title>Parcela</Title>
          <Text>{formatarMoeda(dadosFigital.valorParcela)}</Text>
        </InfoBlock>
        <InfoBlock>
          <Title>Prazo</Title>
          <Text>{dadosFigital.prazo} vezes</Text>
        </InfoBlock>
      </ContainerInfoBox>

      <Espaco margin={20} />

      <ContainerInfoBox>
        <InfoBlock>
          <Title>Cet mês</Title>
          <Text>{formatarPercentual(dadosFigital.cetMes)}</Text>
        </InfoBlock>
        <InfoBlock>
          <Title>Primeiro vencimento</Title>
          <Text>{formatarStringData(dadosFigital.primeiroVencimento)}</Text>
        </InfoBlock>
      </ContainerInfoBox>

      <p>
        O Cliente concorda que, em razão do limite disponível de margem e o
        momento da averbação, os valores da operação poderão sofrer alterações
      </p>
    </div>
  );
}
