// import Selfie from 'assets/selfie.png';
// import { ReactComponent as Documento } from 'assets/carteira-de-identidade.svg';
// import { ReactComponent as Cell } from 'assets/mensagem-sms.svg';
// import { ReactComponent as ConfirmaDados } from 'assets/documento-verificado.svg';
// import { ReactComponent as Documento } from "assets/v2/documento.svg";
import { DocumentoIcon } from "components/Icons/DocumentoIcon";
// import { ReactComponent as Cell } from "assets/v2/token.svg";
import { CellIcon } from "components/Icons/CellIcon";
// import { ReactComponent as ConfirmaDados } from "assets/v2/confirme.svg";
import { ConfirmeIcon } from "components/Icons/ConfirmeIcon";
import Assinatura from "assets/assinatura.png";
// import { ReactComponent as Selfie } from "assets/v2/selfie.svg";
import { SelfieIcon } from "components/Icons/SelfieIcon";

const listaCards = (config) => {
  let lista = [];

  lista.push({
    image: ConfirmeIcon,
    altText: "confirmar dados",
    text: "Confirme seus dados",
    subText: "Verifique se os dados que foram cadastrados estão corretos.",
  });

  if (config.realizarLiveness) {
    lista.push({
      image: SelfieIcon,
      altText: "selfie",
      text: "Tire uma selfie",
      subText:
        "Para podermos confirmar sua identidade por meio da biometria facial.",
    });
  }

  if (config.realizarFacematch || config.coletaDocumento) {
    lista.push({
      image: DocumentoIcon,
      altText: "documento",
      text: "Tire fotos do seu documento",
      subText:
        "Certifique-se de que todos os detalhes estejam legíveis e que o documento esteja válido.",
    });
  }

  if (config.validarTokenSms) {
    lista.push({
      image: CellIcon,
      altText: "celular",
      text: "Assinatura por token",
      subText:
        "Você receberá um token por SMS para concluir a assinatura do documento.",
    });
  }

  if (config.assinaturaDedo) {
    lista.push({
      image: Assinatura,
      altText: "assinatura",
      text: "Registro da sua assinatura",
    });
  }

  return lista;
};

export default listaCards;
