export const SolicitacaoLocalizacaoIcon = ({ color }) => {
  return (
    <svg
      width="94"
      height="98"
      viewBox="0 0 94 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="57.794"
        cy="61.794"
        r="34.0043"
        fill={color}
        stroke="white"
        strokeWidth="3.5794"
      />
      <path
        d="M61.2642 77.8615C65.5968 74.0485 73.3049 66.2583 73.3049 59.1094C73.3049 55.154 71.7336 51.3605 68.9366 48.5635C66.1397 45.7666 62.3462 44.1953 58.3907 44.1953C54.4352 44.1953 50.6418 45.7666 47.8448 48.5635C45.0479 51.3605 43.4766 55.154 43.4766 59.1094C43.4766 66.2583 51.1822 74.0485 55.5173 77.8615C56.308 78.5673 57.3308 78.9574 58.3907 78.9574C59.4506 78.9574 60.4735 78.5673 61.2642 77.8615ZM53.4193 59.1094C53.4193 57.7909 53.9431 56.5265 54.8754 55.5941C55.8077 54.6618 57.0722 54.1381 58.3907 54.1381C59.7092 54.1381 60.9737 54.6618 61.906 55.5941C62.8383 56.5265 63.3621 57.7909 63.3621 59.1094C63.3621 60.4279 62.8383 61.6924 61.906 62.6247C60.9737 63.5571 59.7092 64.0808 58.3907 64.0808C57.0722 64.0808 55.8077 63.5571 54.8754 62.6247C53.9431 61.6924 53.4193 60.4279 53.4193 59.1094Z"
        fill="white"
      />
      <path
        d="M41.2561 19.2408V3M27.0467 25.7371L13.9303 14.9099M18.3024 39.8125H3"
        stroke={color}
        strokeWidth="5.96566"
        strokeLinecap="round"
      />
    </svg>
  );
};
