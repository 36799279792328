import React, { useState, useEffect } from "react";

import api from "services/api";

import { useApp } from "context/App";

import {
  formatarCPF,
  formatarCep,
  formatarIniciarMaiusculo,
} from "helpers/Formatters";
import { telaVisualizada } from "helpers/telaVisualizada";
import "./styles.css";

export default function ComprovanteResidencia(props) {
  const { tokenSignatario, configuracaoAssinatura } = useApp();
  const [dadosDeclaracao, setDadosDeclaracao] = useState(null);

  useEffect(() => {
    const fecthData = async () => {
      try {
        props.setIsLoading(true);
        const response = await api.get(
          `Template/ObterDadosDeclaracaoResidencia/${tokenSignatario}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                "autorizacao"
              )}`,
            },
          }
        );

        props.setIsLoading(false);
        props.setDadosValidos(true);

        setDadosDeclaracao(response.data.retorno);
        if (configuracaoAssinatura.validaVisualizacaoDocumento) {
          props.setBloqueioScroll(!telaVisualizada());
        }
      } catch (err) {
        props.setDadosValidos(false);
        props.setIsLoading(false);
      }
    };

    fecthData();
  }, []);

  return (
    dadosDeclaracao && (
      <div className="container-comprovante-residencia">
        <h4 className="titulo">Confirme seu endereço</h4>
        <table className="table-group-v2 arredonda-ultimo" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">
                <h4 className="titulo-tabela">Dados de endereço</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <h6>Endereço</h6>
                <span className="conteudo-tabela">
                  {formatarIniciarMaiusculo(dadosDeclaracao.endereco)}, nº{" "}
                  {dadosDeclaracao.numero}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Complemento</h6>
                <span className="conteudo-tabela">
                  {formatarIniciarMaiusculo(dadosDeclaracao.complemento)}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <h6>CEP</h6>
                <span className="conteudo-tabela">
                  {formatarCep(dadosDeclaracao.cep)}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Bairro</h6>
                <span className="conteudo-tabela">
                  {formatarIniciarMaiusculo(dadosDeclaracao.bairro)}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <h6>Cidade</h6>
                <span className="conteudo-tabela">
                  {formatarIniciarMaiusculo(dadosDeclaracao.cidade)}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <h6>UF</h6>
                <span className="conteudo-tabela">{dadosDeclaracao.uf}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Eu, <b>{formatarIniciarMaiusculo(dadosDeclaracao.nome)}</b>, portador
          do CPF <b>{formatarCPF(dadosDeclaracao.cpf)}</b>, declaro, sob as
          penas da lei Civil e Penal, que tenho residência e domicílio situados
          no endereço acima.
        </p>
      </div>
    )
  );
}
