import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import {
  CheckIcon,
  Circle,
  Line,
  MiniCircle,
  ProgressBarContainer,
  StepContainer,
  ProgressBarWrapper,
} from "./styles";
import checkIcon from "assets/v2/tick.svg";
import { useApp } from "context/App";
import { useLocation } from "react-router-dom";

const listaProgresso = [
  "/verificacao",
  "/token",
  "/confirmacao-dados",
  "/documento",
  "/biometria",
  "/anexos",
  "/identificacao",
  "/assinatura",
];

const ProgressBar = () => {
  const { listaSpanProgresso: steps } = useApp();
  const [hide, setHide] = useState(true);
  const location = useLocation();
  const progressRef = useRef(null);

  const lastCheckedIndex = steps.findLastIndex((item) => item.checked);

  const lastCheckedItem =
    lastCheckedIndex !== -1 ? steps[lastCheckedIndex] : null;

  const currentStep = lastCheckedItem ? lastCheckedItem?.id : 0;

  useLayoutEffect(() => {
    const rotaPermitida =
      listaProgresso.filter((x) => x === location.pathname).length > 0;

    setHide(!rotaPermitida);
  }, [location]);

  useEffect(() => {
    if (progressRef.current) {
      const activeStepElement = progressRef.current.children[currentStep];
      if (activeStepElement) {
        activeStepElement.scrollIntoView({ behavior: "smooth", inline: "center" });
      }
    }
  }, [currentStep]);

  return (
    steps.length > 1 &&
    !hide && (
      <ProgressBarWrapper>
        <ProgressBarContainer ref={progressRef}>
          {steps.map((step, index) => (
            <StepContainer key={index}>
              <Circle
                completed={index < currentStep}
                current={currentStep === index}
              >
                {index < currentStep ? (
                  <CheckIcon src={checkIcon} alt="Check" />
                ) : (
                  <MiniCircle current={currentStep === index} />
                )}
              </Circle>
              {index < steps.length - 1 && (
                <Line completed={index < currentStep} />
              )}
            </StepContainer>
          ))}
        </ProgressBarContainer>
      </ProgressBarWrapper>
    )
  );
};

export default ProgressBar;
