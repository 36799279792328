import styled from "styled-components";

export const Container = styled.div`
  background-color: #fff;
  height: 55px;
  border-bottom: 1px solid #dee0e1;
  /* padding-left: 10px; */
  padding: 0px 8px 0px 16px;
  position: fixed;
  z-index: 1;
  width: 100%;
  /* top: 0; */

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const LogoContainer = styled.div`
  height: 18px;
`;
