import styled from "styled-components";

export const Container = styled.div`
  background-color: #e8f1ff;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  .box-centro {
    display: ${({ isLoading }) => (isLoading ? "none" : null)};
    background-color: ${({ isLoading }) => (isLoading ? "#E8F1FF" : "#fff")};
    padding: 40px 100px;
    border-radius: 26px;
  }

  @media screen and (max-width: 800px) {
    background-color: ${({ isLoading }) => (isLoading ? "#E8F1FF" : "#fff")};
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const MainContent = styled.div``;
