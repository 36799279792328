import styled from "styled-components";

export const ContainerInfoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid #dee0e1;
  border-radius: 8px;

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
`;

export const InfoBlock = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Title = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: #616161;
  margin-bottom: 5px;
`;

export const Text = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: #212121;
`;
