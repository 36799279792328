import React from "react";
import { Card, Conteudo, Titulo } from "./styles";
import { BoxIcon } from "components/BoxIcon";
import CarteiraIcon from "assets/v2/carteira.svg";
import DinheiroIcon from "assets/v2/dinheiro.svg";

export const BoxMonetario = ({ title, value, img = "carteira", w, h }) => {
  return (
    <Card>
      <Titulo>
        <BoxIcon
          borderColor="#2B3EA1"
          bgColor="#E8F1FF"
          img={
            img === "dinheiro" ? (
              <img src={DinheiroIcon} alt="Dinheiro" />
            ) : (
              <img src={CarteiraIcon} alt="Carteira" />
            )
          }
          padding={(w || h) ? "0" : "8px"}
          mb={"0"}
          w={w}
          h={h}
        />
        <h6>{title}</h6>
      </Titulo>
      <Conteudo>{value}</Conteudo>
    </Card>
  );
};
