import React, { useEffect } from "react";
import {
  formatarMoeda,
  formatarStringDataMesAno,
  formatarStringData,
} from "helpers/Formatters";
import audioResumoProposta from "assets/audios/resumo-proposta.mp3";

import { useAudio } from "react-use";
import { useApp } from "context/App";
import { VOLUME } from "services/consts";
import "./styles.css";
import { AudioAcessibilidade } from "components/AudioAcessibilidade";
import { cancelarAudio } from "components/AudioAcessibilidade/utils";

import { dateAddMoths } from "helpers/dateUtils";
import { BoxMonetario } from "components/BoxMonetario";
import { ContainerCard } from "components/BoxMonetario/styles";
import {
  ContainerInfoBox,
  InfoBlock,
  Text,
  Title,
} from "components/InfoBox/styles";
import { Espaco } from "components/Espaco";

export default function Resumo(prop) {
  const { dadosCCB } = prop;
  const { ajudaPorVoz, tokenSignatario, habilitaAudio } = useApp();
  const [audio, state, controls, ref] = useAudio({
    src: audioResumoProposta,
    autoPlay: false,
  });

  useEffect(() => {
    if (ajudaPorVoz && !habilitaAudio) {
      controls.volume(VOLUME);
      controls.play();
    }

    if (habilitaAudio) {
      executaAudioResumo();
    }

    return () => {
      if (habilitaAudio) cancelarAudio(tokenSignatario);
    };
  }, []);

  function executaAudioResumo() {
    AudioAcessibilidade(1, dadosCCB, tokenSignatario);
  }

  const vencimentoFolha = dateAddMoths(
    dadosCCB.caracteristicaCreditoCet.primeiroVencimento,
    -1
  );

  return (
    <div className="resumo-ccb-container">
      {audio}

      <ContainerCard>
        <BoxMonetario
          title="Valor Financiado"
          value={formatarMoeda(
            dadosCCB.caracteristicaCreditoCet.valorFinanciado
          )}
          w={"32px"}
          h={"32px"}
        />

        <BoxMonetario
          title="Valor Líquido"
          value={formatarMoeda(dadosCCB.valorLiberado)}
          img="dinheiro"
          w={"32px"}
          h={"32px"}
        />
      </ContainerCard>

      <Espaco margin={20} />

      <ContainerInfoBox>
        <InfoBlock>
          <Title>Tipo de operação</Title>
          <Text>Proposta {dadosCCB.descricaoTipoOperacao} (consignado)</Text>
        </InfoBlock>
        <InfoBlock>
          <Title>Vencimento</Title>
          <Text>
            {formatarStringData(
              dadosCCB.caracteristicaCreditoCet.primeiroVencimento
            )}{" "}
            (Folha de {formatarStringDataMesAno(vencimentoFolha)})
          </Text>
        </InfoBlock>
      </ContainerInfoBox>

      <Espaco margin={20} />

      <ContainerInfoBox>
        <InfoBlock>
          <Title>Parcela</Title>
          <Text>
            {formatarMoeda(dadosCCB.caracteristicaCreditoCet.valorPrestacao)}
          </Text>
        </InfoBlock>
        <InfoBlock>
          <Title>Prazo</Title>
          <Text>
            {dadosCCB.caracteristicaCreditoCet.quantidadePrestacao} vezes
          </Text>
        </InfoBlock>
      </ContainerInfoBox>
    </div>
  );
}
